import review from "@/router/middleware/review";

export default [
  {
    path: 'summary',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Summary.vue')
  },
  {
    path: 'welcome',
    name: 'welcome',
    component: () => import('@/views/dashboard/FirstLogin.vue'),
    meta: {
      // Don't wrap the content area with columns/padding/etc
      fullWidth: true,
      preventWrap: true
    }
  },
  {
    path: 'profile',
    name: 'providerProfile',
    component: () => import('@/views/dashboard/Profile.vue')
  },
  {
    path: 'settings',
    name: 'userSettings',
    component: () => import('@/views/dashboard/Settings.vue')
  },
  {
    path: 'subscription',
    name: 'subscription',
    component: () => import('@/views/dashboard/Subscription.vue')
  },
  // {
  //   path: 'reminders/:slug',
  //   name: 'remindersShow',
  //   component: () => import('@/views/dashboard/Reminders.vue')
  // },
  {
    path: 'reminders/activity',
    name: 'activityShow',
    component: () => import('@/views/dashboard/Activity.vue')
  },
  {
    path: 'reminders/:slug',
    name: 'remindersShow',
    component: () => import('@/views/dashboard/Practice.vue')
  },
  
  {
    path: 'reminders',
    name: 'reminders',
    redirect: { name: 'remindersShow', params: { slug: 'assess' } }
  },
  {
    path: 'summary-reminders/:slug',
    name: 'summary-remindersShow',
    component: () => import('@/views/dashboard/Reminders.vue'),
    meta: {
      useDeprecatedHeader: true
    }
  },
  {
    path: 'summary-reminders',
    name: 'provider',
    redirect: { name: 'summary-remindersShow', params: { slug: 'assess' } }
  },
  {
    path: 'client/edit/:id',
    name: 'clientEdit',
    component: () => import('@/views/dashboard/client/ClientEdit.vue')
  },
  {
    path: 'client/add',
    name: 'clientAdd',
    component: () => import('@/views/dashboard/client/ClientAdd.vue')
    // NOTE does not use deprecated header since it's the new design
  },
  {
    path: 'patient/edit/:id',
    name: 'patientEdit',
    component: () => import('@/views/dashboard/patient/PatientEdit.vue'),
    meta: {
      useDeprecatedHeader: true
    }
  },
  {
    path: 'patient/add',
    name: 'patientAdd',
    component: () => import('@/views/dashboard/patient/PatientAdd.vue'),
    meta: {
      useDeprecatedHeader: true
    }
  },
  {
    path: 'patient/selected',
    name: 'patientSelected',
    component: () => import('@/views/dashboard/patient/PatientSelected.vue'),
    meta: {
      useDeprecatedHeader: true
    }
  },
  {
    path: 'patient/select',
    name: 'patientSelect',
    component: () => import('@/views/dashboard/patient/PatientSelect.vue'),
    meta: {
      useDeprecatedHeader: true
    }
  },
  {
    path: 'review/monitoring',
    name: 'review',
    component: () => import('@/views/dashboard/review/Monitoring.vue'),
    meta: {
      useDeprecatedHeader: true,
      middleware: [ review ],
      reminderType: 'remind'
    }
  },
  {
    path: 'review/assessment',
    name: 'reviewAssess',
    component: () => import('@/views/dashboard/review/AssessmentPage.vue'),
    meta: {
      useDeprecatedHeader: true,
      middleware: [ review ],
      reminderType: 'assess'
    }
  },
  // we need a robust catchall. this doesn't work but should be fixed to
  // { 
  //   path: '/:pathMatch(.*)*', 
  //   name: 'NotFound', 
  //   component: () => import('@/views/NotFound.vue'),
  // },
  {
    path: 'review/sleeplog',
    name: 'reviewSleepLog',
    component: () => import('@/views/dashboard/review/SleepLogPage.vue'),
    meta: {
      useDeprecatedHeader: true,
      middleware: [ review ],
      reminderType: 'sleeplog'
    }
  }
]
