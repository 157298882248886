export const severities = ['Not', 'A Little', 'Pretty', 'Very', 'Extremely']

export const dict = {
  'Self-monitoring': {
    'Self-monitoring': {
      default: {
        type: 'horizontal',
        collapse: false
      },
      emotions: {
        Anxiety: { inAction: 'anxious' },
        Fear: { inAction: 'fearful' },
        Sadness: { inAction: 'sad' },
        Stress: { inAction: 'stressed' },
        Anger: { inAction: 'angry' }
      },
      'distressing situations': {
        default: {
          // emotions: ['anxious', 'afraid', 'sad', 'angry', 'disgusted'],
          emotions: ['anxiety', 'fear', 'sadness', 'anger', 'disgust'],
          sensations: [
            'heart racing',
            'shortness of breath',
            'nausea',
            'faint/dizzy',
            'need to use restroom',
            'muscle tension or body aches'
          ],
          // thoughts: [
          //   // Worries:
          //   `Worried about {what} <nobr>{quantity} times</nobr><br>
          //     for total of {duration} minutes`,
          //   // Intrusive thoughts:
          //   `Intrusive thought about {what} <nobr>{quantity} times</nobr> for a total of {duration} minutes`,
          //   // Pessimism:
          //   `Negative thoughts about {what} <nobr>{quantity} times</nobr> for a total of {duration} minutes`,
          //   // Self-deprecating:
          //   `Self-deprecating thoughts about {what} <nobr>{quantity} times</nobr> for a total of {duration} minutes`,
          //   // Catastrophizing:
          //   `Catastrophizing thoughts about {what} <nobr>{quantity} times</nobr> for a total of {duration} minutes`,
          // ],
          thoughts: [
            // Worries:
            `worry`,
            // Intrusive thoughts:
            `intrusive thoughts`,
            // Pessimism:
            `negative thoughts`,
            // Self-deprecating:
            `self-deprecating thoughts`,
            // Catastrophizing:
            `catastrophizing thoughts`,
          ],
          behaviors: [
            'I avoided or left',
            'I stayed where I was',
            'I approached or interacted'
          ],
          extract: 'extractDistressingSituations',
          key: 'situations',
          // '{what} caused {emotions}<br>{thoughts}<br>{sensations}<br>and {behaviors}'
          tooltip:
          '{what} caused {emotions}, {thoughts}, {sensations}'
        }
      },
      thoughts: {
        Worries: {
          tooltip: `Worried about {what} <nobr>{quantity} times</nobr><br>
          for total of {duration} minutes`
        },
        'Intrusive thoughts': {
          key: 'intrusive',
          tooltip: `Intrusive thought about {what} <nobr>{quantity} times</nobr> for a total of {duration} minutes`
        },
        Pessimism: {
          tooltip: `Negative thoughts about {what} <nobr>{quantity} times</nobr> for a total of {duration} minutes`
        },
        'Self-deprecating': {
          tooltip: `Self-deprecating thoughts about {what} <nobr>{quantity} times</nobr> for a total of {duration} minutes`
        },
        Catastrophizing: {
          tooltip: `Catastrophizing thoughts about {what} <nobr>{quantity} times</nobr> for a total of {duration} minutes`
        }
      },
      behaviors: {
        Avoidance: {
          tooltip: 'Avoided {what} because<br> {why}'
        },
        Checking: {
          key: 'checkingbehavior',
          tooltip:
            'Checked {what} <nobr>{quantity} times</nobr><br>for a total {duration} minutes'
        },
        'Physical activity': {
          tooltip: '{What} for {duration} minutes'
        },
        'Repetitive behavior': {
          tooltip:
            '{What} <nobr>{quantity} times</nobr> for a total of {duration} minutes'
        },
        Tics: {
          tooltip: '{What} {how} <nobr>{quantity} times</nobr>'
        }
      }
    }
  },
  Exercises: {
    order: 1,
    Exposure: {
      'in-vivo exposure': {
        default: {
          order: 1,
          collapse: false,
          type: 'horizontal'
        }
      },
      'stories about exposure': {
        default: {
          title: 'Stories about exposure'
        }
      }
    },
    'Simple reminder': {
      'do something': {
        default: {
          collapse: false,
          title: '{remind}'
        }
      },
      'check out a link': {
        default: {
          collapse: false,
          title: 'Viewed {remind}'
        }
      },
      'take medication': {
        default: {
          collapse: false,
          title: 'Take {remind}'
        }
      }
    },
    Relaxation: {
      'deep breathing': {
        default: {
          title: 'Deep breathing'
        }
      },
      'roll breathing': {
        default: {
          title: 'Roll breathing'
        }
      },
      'muscle relaxation for adults': {
        default: {
          title: 'Muscle relaxation'
        }
      },
      'muscle relaxation for children': {
        default: {
          title: 'Muscle relaxation'
        }
      },
      'mindfulness for adults': {
        default: {
          title: 'Mindfulness'
        }
      },
      'mindfulness for children': {
        default: {
          title: 'Mindfulness'
        }
      },
      'morning breathing': {
        default: {
          title: 'Morning breathing'
        }
      },
      '4-7-8 breathing': {
        default: {
          title: '4-7-8 breathing'
        }
      },
      'belly breathing': {
        default: {
          title: 'Belly breathing'
        }
      }
    },
    'Affect and emotion': {
      'identifying feelings': {
        default: {
          title: 'Identifying feelings'
        }
      },
      'emoji emotion matching': {
        default: {
          title: 'Emoji emotion matching'
        }
      },
      'picture emotion matching': {
        default: {
          title: 'Picture emotion matching'
        }
      }
    },
    'Cognitive': {
      'thoughts, feelings, actions': {
        default: {
          title: 'Identifying thoughts, feelings, and actions'
        }
      },
      'helpful and unhelpful thoughts': {
        default: {
          title: 'Helpful and unhelpful thoughts'
        }
      },
      'changing unhelpful thoughts': {
        default: {
          title: 'Changing unhelpful thoughts'
        }
      },
      'thoughts, feelings, actions stories': {
        default: {
          title: 'Thoughts, feelings, and actions stories'
        }
      },
      'matching cognitive distortions': {
        default: {
          title: 'Matching cognitive distortions'
        }
      },
      'socratic questioning': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'thought log': {
        default: {
          type: 'combined',
          collapse: false
        }
      }
    },
    'Parent-child activities': {
      'do a fun activity': {
        default: {
          type: 'horizontal',
          collapse: false,
          order: 2
        }
      },
      '20 questions': {
        default: {
          title: 'Play 20 Questions with caregiver'
        }
      },
      'teach a skill': {
        default: {
          title: 'Teach {listNameLower} to caregiver'
        }
      }
    },
    'Enhancing safety': {
      'teach your safety plan': {
        default: {
          title: 'Teach safety plan to caregiver'
        }
      },
      'practice your safety plan': {
        default: {
          title: 'Practice safety plan with caregiver'
        }
      }
    },
    'Parenting exercises': {
      praise: {
        default: {
          title: 'Practice praise with {name}'
        }
      },
      'positive attending': {
        default: {
          title: 'Practice positive attending with {name}'
        }
      },
      'contingency management': {
        default: {
          title: 'Practice contingency management with {name}'
        }
      },
      'reflection skills': {
        default: {
          title: 'Practice reflection skills with {name}'
        }
      }
    },
    'Parenting videos': {
      communication: {
        default: {
          title: 'Watch {activityTitle}'
        }
      },
      'structure and rules': {
        default: {
          title: 'Watch {activityTitle}'
        }
      },
      'giving directions': {
        default: {
          title: 'Watch {activityTitle}'
        }
      },
      'discipline and consequences': {
        default: {
          title: 'Watch {activityTitle}'
        }
      },
      'time-out': {
        default: {
          title: 'Watch {activityTitle}'
        }
      }
    },
    'Social skills': {
      'appropriate conversation topics': {
        default: 'Watch {activityTitle}'
      },
      'starting conversations with someone new': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'maintaining conversations with someone new': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'starting conversations with someone familiar': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'maintaining conversations with someone familiar': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'establish and maintaining friendships': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'asking others to change their behavior': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'being positively assertive': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'interacting with authority figures': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'declining unreasonable requests': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
    },
    'Journals': {
      'gratitude journal': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'self-esteem journal': {
        default: {
          type: 'combined',
          collapse: false
        }
      }
    },
    'Activity scheduling': {
      'relationships': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'education/career': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'mind/body/spirituality': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'recreation/interests': {
        default: {
          type: 'combined',
          collapse: false
        }
      },
      'daily responsibilities': {
        default: {
          type: 'combined',
          collapse: false
        }
      }
    }
  }
}
