export default {
  apiKey: 'AIzaSyAfFXJgLm6D-3RAwa224lhgwueJ0aG9nnk',
  authDomain: 'adherely.firebaseapp.com',
  databaseURL: 'https://adherely.firebaseio.com',
  projectId: 'adherely',
  storageBucket: '',
  messagingSenderId: '1098065873855',
  appId: '1:1098065873855:web:0f74103614bdaf11',
  measurementId: 'G-1KDNS4P9GK'
}
