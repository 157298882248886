export default () => {
  return {
    customMessage: null,
    filter: null,
    item: {
      step: 'activity',
      name: null,
      email: null,
      phone: null,
      channel: {},
    },
    editType: null,
    recurrence: {
      isRecurring: false,
      frequency: '',
      endDate: ''
    },
    session: null
  }
}
