export const severities = ['Not', 'A Little', 'Pretty', 'Very', 'Extremely']

export const dict = {
  gad7ad: { // Generalized Anxiety Disorder 7-item (GAD-7) scale
    hasSections: true,
    numSections: 2,
    section_1: {
      selection_headers: [
        'Over the last 2 weeks how often have you been bothered by the following problems?',
        'Not at all sure',
        'Several days',
        'Over half the days',
        'Nearly every day'
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'GAD7_1', text: 'Feeling nervous, anxious, or on edge.' },
        { property: 'GAD7_2', text: 'Not being able to stop or control worrying.' },
        { property: 'GAD7_3', text: 'Worrying too much about different things.' },
        { property: 'GAD7_4', text: 'Trouble relaxing.' },
        { property: 'GAD7_5', text: "Being so restless that it's hard to sit still." },
        { property: 'GAD7_6', text: 'Becoming easily annoyed or irritable.' },
        { property: 'GAD7_7', text: 'Feeling afraid as if something awful might happen.' },
      ],
      scoreRange: [0, 1, 2, 3]
    },
    section_2: {
      selection_headers: [
        '',
        'Not difficult at all',
        'Somewhat difficult',
        'Very difficult',
        'Extremely difficult'
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'GAD7_8', text: 'If you checked off any problems, how difficult have these made it for you to do your work, take care of things at home, or get along with other people?' }
      ],
      scoreRange: [0, 1, 2, 3]
    },
    dataPrefix: 'GAD7_',
  },
  phq8ad: { // Patient Health Questionnaire-8
    selection_headers: [
      'Over the last 2 weeks how often have you been bothered by the following problems?',
      'Not at all sure',
      'Several days',
      'Over half the days',
      'Nearly every day'
    ],
    yes_no_headers: [],
    text_input_headers: [],
    steps: [
      { property: 'PHQ8_1', text: 'Little interest or pleasure in doing things.' },
      { property: 'PHQ8_2', text: 'Feeling down, depressed, or hopeless.' },
      { property: 'PHQ8_3', text: 'Trouble falling or staying asleep, or sleeping too much.' },
      { property: 'PHQ8_4', text: 'Feeling tired or having little energy.' },
      { property: 'PHQ8_5', text: 'Poor appetite or overeating.' },
      { property: 'PHQ8_6', text: 'Feeling bad about yourself, or that you are a failure, or have let yourself or your family down.' },
      { property: 'PHQ8_7', text: 'Trouble concentrating on things, such as reading the newspaper or watching television.' },
      { property: 'PHQ8_8', text: 'Moving or speaking so slowly that other people could have noticed. Or the opposite-being so fidgety or restless that you have been moving around a lot more than usual.' }
    ],
    dataPrefix: 'PHQ8_',
    scoreRange: [0, 1, 2, 3]
  },
  isiad: { // Insomnia Severity Index
    hasSections: true,
    numSections: 3,
    section_1: {
      selection_headers: [
        'Severity of insomnia symptoms within the past 2 weeks.',
        'None',
        'Mild',
        'Moderate',
        'Severe',
        'Very severe'
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'ISI_1', text: 'Difficulty falling asleep.' },
        { property: 'ISI_2', text: 'Difficulty staying asleep.' },
        { property: 'ISI_3', text: 'Problems waking up too early.' },
      ],
      scoreRange: [0, 1, 2, 3, 4]
    },
    section_2: {
      selection_headers: [
        'Satisfaction',
        'Very Satisfied',
        'Satisfied',
        'Moderately Satisfied',
        'Dissatisfied',
        'Very Dissatisfied'
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'ISI_4', text: 'How SATISFIED/DISSATISFIED are you with your CURRENT sleep pattern?' },
      ],
      scoreRange: [0, 1, 2, 3, 4]
    },
    section_3: {
      selection_headers: [
        'Interference',
        'Not at all',
        'A little',
        'Somewhat',
        'Much',
        'Very Much'
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'ISI_5', text: 'How NOTICEABLE to others do you think your sleep problem is in terms of impairing the quality of your life?' },
        { property: 'ISI_6', text: 'How WORRIED/DISTRESSED are you about your current sleep problem?' },
        { property: 'ISI_7', text: 'To what extent do you consider your sleep problem to INTERFERE with your daily functioning (e.g. daytime fatigue, mood, ability to function at work/daily chores, concentration, memory, mood, etc.) CURRENTLY?' }
      ],
      scoreRange: [0, 1, 2, 3, 4]
    },
    dataPrefix: 'ISI_',
  },
  pcl5ad: { // Post-Traumatic Stress Disorder Checklist for DSM-5
    selection_headers: [
      'How much you have been bothered by that problem in the past month.',
      'Not at all',
      'A little bit',
      'Moderately',
      'Quite a bit',
      'Extremely'
    ],
    yes_no_headers: [],
    text_input_headers: [],
    steps: [
      { property: 'PCL5_1', text: 'Repeated, disturbing, and unwanted memories of the stressful experience?' },
      { property: 'PCL5_2', text: 'Repeated, disturbing dreams of the stressful experience?' },
      { property: 'PCL5_3', text: 'Suddenly feeling or acting as if the stressful experience were actually happening again?' },
      { property: 'PCL5_4', text: 'Feeling very upset when something reminded you of the stressful experience?' },
      { property: 'PCL5_5', text: 'Having strong physical reactions when something reminded you of the stressful experience?' },
      { property: 'PCL5_6', text: 'Avoiding memories, thoughts, or feelings related to the stressful experience?' },
      { property: 'PCL5_7', text: 'Avoiding external reminders of the stressful experience?' },
      { property: 'PCL5_8', text: 'Trouble remembering important parts of the stressful experience?' },
      { property: 'PCL5_9', text: 'Having strong negative beliefs about yourself, other people, or the world?' },
      { property: 'PCL5_10', text: 'Blaming yourself or someone else for the stressful experience or what happened after it?' },
      { property: 'PCL5_11', text: 'Having strong negative feelings such as fear, horror, anger, guilt, or shame?' },
      { property: 'PCL5_12', text: 'Loss of interest in activities that you used to enjoy?' },
      { property: 'PCL5_13', text: 'Feeling distant or cut off from other people?' },
      { property: 'PCL5_14', text: 'Trouble experiencing positive feelings?' },
      { property: 'PCL5_15', text: 'Irritable behavior, angry outbursts, or acting aggressively?' },
      { property: 'PCL5_16', text: 'Taking too many risks or doing things that could cause you harm?' },
      { property: 'PCL5_17', text: 'Being “superalert” or watchful or on guard?' },
      { property: 'PCL5_18', text: 'Feeling jumpy or easily startled?' },
      { property: 'PCL5_19', text: 'Having difficulty concentrating?' },
      { property: 'PCL5_20', text: 'Trouble falling or staying asleep?' }
    ],
    dataPrefix: 'PCL5_',
    scoreRange: [0, 1, 2, 3, 4]
  },
  'pswq-cad': { // Penn State Worry Questionnaire for Children
    selection_headers: [
      'How true is the sentence for you?',
      'Never true',
      'Sometimes true',
      'Most times true',
      'Always true'
    ],
    yes_no_headers: [],
    text_input_headers: [],
    steps: [
      { property: 'PSWQC_1', text: 'My worries really bother me.' },
      { property: 'PSWQC_2', text: 'I don\'t really worry about things.', reversed: true },
      { property: 'PSWQC_3', text: 'Many things make me worry.' },
      { property: 'PSWQC_4', text: 'I know I shouldn\'t worry about things, but I just can\'t help it.' },
      { property: 'PSWQC_5', text: 'When I am under pressure, I worry a lot.' },
      { property: 'PSWQC_6', text: 'I am always worrying about something.' },
      { property: 'PSWQC_7', text: 'I find it easy to stop worrying when I want.', reversed: true },
      { property: 'PSWQC_8', text: 'When I finish one thing, I start to worry about everything else.' },
      { property: 'PSWQC_9', text: 'I never worry about anything.', reversed: true },
      { property: 'PSWQC_10', text: 'I\'ve been a worrier all my life.' },
      { property: 'PSWQC_11', text: 'I notice that I have been worrying about things.' },
      { property: 'PSWQC_12', text: 'Once I start worrying, I can\'t stop.' },
      { property: 'PSWQC_13', text: 'I worry all the time.' },
      { property: 'PSWQC_14', text: 'I worry about things until they are all done.' }
    ],
    dataPrefix: 'PSWQC_',
    scoreRange: [0, 1, 2, 3]
  },
  'pswq-aad': { // The Penn State Worry Questionnaire - Adult
    // TODO: This appears to use a slider. Make sure it still works
    selection_headers: [
      'How accurate is each statement for you?',
      'Not at all typical of me',
      '',
      '',
      '',
      'Very typical of me'
    ],
    yes_no_headers: [],
    text_input_headers: [],
    steps: [
      { property: 'PSWQ_1', text: 'If I do not have enough time to do everything, I do not worry about it.', reversed: true },
      { property: 'PSWQ_2', text: 'My worries overwhelm me.' },
      { property: 'PSWQ_3', text: 'I do not tend to worry about things.', reversed: true },
      { property: 'PSWQ_4', text: 'Many situations make me worry.' },
      { property: 'PSWQ_5', text: 'I know I should not worry about things, but I just cannot help it. ' },
      { property: 'PSWQ_6', text: 'When I am under pressure I worry a lot.' },
      { property: 'PSWQ_7', text: 'I am always worrying about something.' },
      { property: 'PSWQ_8', text: 'I find it easy to dismiss worrisome thoughts.', reversed: true },
      { property: 'PSWQ_9', text: 'As soon as I finish one task, I start to worry about everything else I have to do.' },
      { property: 'PSWQ_10', text: 'I never worry about anything.', reversed: true },
      { property: 'PSWQ_11', text: 'When there is nothing more I can do about a concern, I do not worry about it any more.', reversed: true },
      { property: 'PSWQ_12', text: 'I have been a worrier all my life.' },
      { property: 'PSWQ_13', text: 'I notice that I have been worrying about things.' },
      { property: 'PSWQ_14', text: 'Once I start worrying, I cannot stop.' },
      { property: 'PSWQ_15', text: 'I worry all the time.' },
      { property: 'PSWQ_16', text: 'I worry about projects until they are all done.' },
    ],
    dataPrefix: 'PSWQ_',
    scoreRange: [1, 2, 3, 4, 5]
  },
  'csqpad': { // Caregiver Satisfaction Questionnaire - (CSQ-P)
    // TODO: Makes sure the text input responses show up on the table properly
    selection_headers: [
      'Please answer the questions below about your child’s treatment.',
      'Very much false',
      'Mostly false',
      'Both true & false',
      'Mostly true',
      'Very much true',
    ],
    yes_no_headers: [],
    text_input_headers: [
      '',
      'Extra feedback'
    ],
    steps: [
      { property: 'CSQP_1', text: 'The MAIN problems that I wanted my child treated for have improved.' },
      { property: 'CSQP_2', text: 'OTHER problems that my child had have improved.' },
      { property: 'CSQP_3', text: `I am happy with my child’s progress in treatment.` },
      { property: 'CSQP_4', text: `My child’s treatment has improved other parts of my life (e.g. family relationships, my own mood).` },
      { property: 'CSQP_5', text: `I know what to do if my child’s problems get worse or come back again.` },
      { property: 'CSQP_6', text: `My child’s therapist has explained Trauma Focused Cognitive Behavioral Therapy to me.` },
      { property: 'CSQP_7', text: 'I understand how Trauma Focused Cognitive Behavioral Therapy works.' },
      { property: 'CSQP_8', text: `I have felt included in my child’s treatment.` },
      { property: 'CSQP_9', text: 'My therapist has listened to my thoughts and concerns about my child.' },
      { property: 'CSQP_10', text: `My child’s therapist knows how to help my child.` },
      { property: 'CSQP_11', text: 'I would recommend this treatment to a friend who had a child in the same situation.' },
      { property: 'CSQP_12', text: `Overall, I am satisfied with my child’s treatment.` },
      { property: 'extfdbk', text: `What were the MOST HELPFUL parts of your child’s treatment?` },
      { property: 'extfdbk_1', text: `What were the LEAST HELPFUL parts of your child’s treatment?` },
      { property: 'extfdbk_2', text: 'Do you have any suggestions about improving this treatment? If so, please describe:' }
    ],
    scoreRange: [1, 2, 3, 4, 5],
    dataPrefix: 'CSQP_',
  },
  'wai-sr-y-ad': { // Working Alliance Inventory - Short Revised - Client Version
    selection_headers: [
      'Which category best describes your own experience.',
      'Seldom',
      'Sometimes',
      'Fairly Often',
      'Very Often',
      'Always'
    ],
    yes_no_headers: [],
    text_input_headers: [],
    steps: [
      { property: 'WAISRY_1', text: 'As a result of these sessions I am clearer as to how I might be able to change.' },
      { property: 'WAISRY_2', text: 'What I am doing in therapy gives me new ways of looking at my problem.' },
      { property: 'WAISRY_3', text: 'I believe my therapist likes me.' },
      { property: 'WAISRY_4', text: 'My therapist and I collaborate on setting goals for my therapy.' },
      { property: 'WAISRY_5', text: 'My therapist and I respect each other.' },
      { property: 'WAISRY_6', text: 'My therapist and I are working towards mutually agreed upon goals.' },
      { property: 'WAISRY_7', text: 'I feel that my therapist appreciates me.' },
      { property: 'WAISRY_8', text: 'My therapist and I agree on what is important for me to work on.' },
      { property: 'WAISRY_9', text: 'I feel my therapist cares about me even when I do things that he/she does not approve of.' },
      { property: 'WAISRY_10', text: 'I feel that the things I do in therapy will help me to accomplish the changes that I want.' },
      { property: 'WAISRY_11', text: 'My therapist and I have established a good understanding of the kind of changes that would be good for me.' },
      { property: 'WAISRY_12', text: 'I believe the way we are working with my problem is correct.' }
    ],
    dataPrefix: 'WAISRY_',
    scoreRange: [1, 2, 3, 4, 5]
  },
  'casq-yad': { // Child/Adolescent Satisfaction Questionnaire -Y
    // TODO: Makes sure the text input responses show up on the table properly
    selection_headers: [
      'Which category best describes your experience with your treatment',
      'Very much false',
      'Mostly false',
      'Both true & false',
      'Mostly true',
      'Very much true',
    ],
    yes_no_headers: [],
    text_input_headers: [
      '',
      'Extra feedback'
    ],
    steps: [
      { property: 'CASQ_1', text: 'The MAIN problems that I wanted help with have improved.' },
      { property: 'CASQ_2', text: 'OTHER problems that I had before coming here for therapy have improved.' },
      { property: 'CASQ_3', text: 'I am happy with what I have learned and my progress in therapy.' },
      { property: 'CASQ_4', text: 'My treatment has improved other parts of my life (e.g. family relationships, my relationships with my friends, my own mood). ' },
      { property: 'CASQ_5', text: 'I know what to do if my problems get worse or come back again.' },
      { property: 'CASQ_6', text: 'My therapist has explained what we are doing in counseling.' },
      { property: 'CASQ_7', text: 'I understand how Trauma-Focused Cognitive Behavioral Therapy works.' },
      { property: 'CASQ_8', text: 'My parents have been included in counseling. They talk with the therapist and have sessions with me.  ' },
      { property: 'CASQ_9', text: 'My therapist has listened to my thoughts, worries, and concerns.' },
      { property: 'CASQ_10', text: 'My therapist knows how to help me.' },
      { property: 'CASQ_11', text: 'If a friend needed the same kind of help, I would tell them to come here.' },
      { property: 'CASQ_12', text: 'Overall, I am happy with the therapy I got here.' },
      { property: 'CASQ_13', text: 'What were the MOST HELPFUL parts of your therapy?' },
      { property: 'CASQ_14', text: 'What were the parts of counseling you liked the LEAST?' },
      { property: 'CASQ_15', text: 'How could we make the counseling experience better? Please list your suggestions/thoughts:' },
      { property: 'extfdbk', text: 'What were the MOST HELPFUL parts of your therapy?' },
      { property: 'extfdbk_1', text: 'What were the parts of counseling you liked the LEAST?' },
      { property: 'extfdbk_2', text: 'How could we make the counseling experience better? Please list your suggestions/thoughts:' }
    ],
    scoreRange: [1, 2, 3, 4, 5],
    dataPrefix: 'CASQ_',
  },
  'scared-cad': { // Screen for Child Anxiety Related Disorders Child Version
    selection_headers: [
      'In the last three months...',
      'Not true/ Hardly true',
      'Somewhat true/ Sometime true',
      'Very true/ Often true'
    ],
    yes_no_headers: [],
    text_input_headers: [],
    steps: [
      { property: 'SCAREDC_1', text: 'When I feel frightened, it is hard to breathe.' },
      { property: 'SCAREDC_2', text: 'I get headaches when I am at school.' },
      { property: 'SCAREDC_3', text: 'I don’t like to be with people I don’t know well.' },
      { property: 'SCAREDC_4', text: 'I get scared if I sleep away from home.' },
      { property: 'SCAREDC_5', text: 'I worry about other people liking me.' },
      { property: 'SCAREDC_6', text: 'When I get frightened, I feel like passing out.' },
      { property: 'SCAREDC_7', text: 'I am nervous.' },
      { property: 'SCAREDC_8', text: 'I follow my mother or father wherever they go.' },
      { property: 'SCAREDC_9', text: 'People tell me that I look nervous.' },
      { property: 'SCAREDC_10', text: 'I feel nervous with people I don’t know well.' },
      { property: 'SCAREDC_11', text: 'I get stomachaches at school.' },
      { property: 'SCAREDC_12', text: 'When I get frightened, I feel like I am going crazy.' },
      { property: 'SCAREDC_13', text: 'I worry about sleeping alone.' },
      { property: 'SCAREDC_14', text: 'I worry about being as good as other kids.' },
      { property: 'SCAREDC_15', text: 'When I get frightened, I feel like things are not real.' },
      { property: 'SCAREDC_16', text: 'I have nightmares about something bad happening to my parents.' },
      { property: 'SCAREDC_17', text: 'I worry about going to school.' },
      { property: 'SCAREDC_18', text: 'When I get frightened, my heart beats fast.' },
      { property: 'SCAREDC_19', text: 'I get shaky.' },
      { property: 'SCAREDC_20', text: 'I have nightmares about something bad happening to me.' },
      { property: 'SCAREDC_21', text: 'I worry about things working out for me.' },
      { property: 'SCAREDC_22', text: 'When I get frightened, I sweat a lot.' },
      { property: 'SCAREDC_23', text: 'I am a worrier.' },
      { property: 'SCAREDC_24', text: 'I get really frightened for no reason at all.' },
      { property: 'SCAREDC_25', text: 'I am afraid to be alone in the house.' },
      { property: 'SCAREDC_26', text: 'It is hard for me to talk with people I don’t know well.' },
      { property: 'SCAREDC_27', text: 'When I get frightened, I feel like I am choking.' },
      { property: 'SCAREDC_28', text: 'People tell me that I worry too much.' },
      { property: 'SCAREDC_29', text: 'I don’t like to be away from my family.' },
      { property: 'SCAREDC_30', text: 'I am afraid of having anxiety (or panic) attacks.' },
      { property: 'SCAREDC_31', text: 'I worry that something bad might happen to my parents.' },
      { property: 'SCAREDC_32', text: 'I feel shy with people I don’t know well.' },
      { property: 'SCAREDC_33', text: 'I worry about what is going to happen in the future.' },
      { property: 'SCAREDC_34', text: 'When I get frightened, I feel like throwing up.' },
      { property: 'SCAREDC_35', text: 'I worry about how well I do things.' },
      { property: 'SCAREDC_36', text: 'I am scared to go to school.' },
      { property: 'SCAREDC_37', text: 'I worry about things that have already happened.' },
      { property: 'SCAREDC_38', text: 'When I get frightened, I feel dizzy.' },
      { property: 'SCAREDC_39', text: 'I feel nervous when I am with other children or adults and I have to do something while they watch me (for example: read aloud, speak, play a game, play a sport).' },
      { property: 'SCAREDC_40', text: 'I feel nervous when I am going to parties, dances, or any place where there will be people that I don’t know well.' },
      { property: 'SCAREDC_41', text: 'I am shy.' }
    ],
    dataPrefix: 'SCAREDC_',
    scoreRange: [0, 1, 2]
  },
  'scared-pad': { // Screen for Child Anxiety Related Disorders Parent Version
    selection_headers: [
      'In the last three months...',
      'Not True/ Hardly Ever True',
      'Somewhat True/ Sometimes True',
      'Very True/ Often True'
    ],
    yes_no_headers: [],
    text_input_headers: [],
    steps: [
      { property: 'SCAREDP_1', text: 'When my child feels frightened, it is hard for him/her to breathe.' },
      { property: 'SCAREDP_2', text: 'My child gets headaches when he/she is at school.' },
      { property: 'SCAREDP_3', text: `My child doesn't like to be with people he/she doesn't know well.` },
      { property: 'SCAREDP_4', text: 'My child gets scared if he/she sleeps away from home.' },
      { property: 'SCAREDP_5', text: 'My child worries about other people liking him/her.' },
      { property: 'SCAREDP_6', text: 'When my child gets frightened, he/she feels like passing out.' },
      { property: 'SCAREDP_7', text: 'My child is nervous.' },
      { property: 'SCAREDP_8', text: 'My child follows me wherever I go.' },
      { property: 'SCAREDP_9', text: 'People tell me that my child looks nervous.' },
      { property: 'SCAREDP_10', text: `My child feels nervous with people he/she doesn't know well.` },
      { property: 'SCAREDP_11', text: 'My child gets stomachaches at school.' },
      { property: 'SCAREDP_12', text: 'When my child gets frightened, he/she feels like he/she is going crazy.' },
      { property: 'SCAREDP_13', text: 'My child worries about sleeping alone.' },
      { property: 'SCAREDP_14', text: 'My child worries about being as good as other kids.' },
      { property: 'SCAREDP_15', text: 'When he/she gets frightened, he/she feels like things are not real.' },
      { property: 'SCAREDP_16', text: 'My child has nightmares about something bad happening to his/her parents.' },
      { property: 'SCAREDP_17', text: 'My child worries about going to school.' },
      { property: 'SCAREDP_18', text: 'When my child gets frightened, his/her heart beats fast.' },
      { property: 'SCAREDP_19', text: 'He/she gets shaky.' },
      { property: 'SCAREDP_20', text: 'My child has nightmares about something bad happening to him/her.' },
      { property: 'SCAREDP_21', text: 'My child worries about things working out for him/her.' },
      { property: 'SCAREDP_22', text: 'When my child gets frightened, he/she sweats a lot.' },
      { property: 'SCAREDP_23', text: 'My child is a worrier.' },
      { property: 'SCAREDP_24', text: 'My child gets really frightened for no reason at all.' },
      { property: 'SCAREDP_25', text: 'My child is afraid to be alone in the house.' },
      { property: 'SCAREDP_26', text: `It is hard for my child to talk with people he/she doesn't know well.` },
      { property: 'SCAREDP_27', text: 'When my child gets frightened, he/she feel like he/she is choking.' },
      { property: 'SCAREDP_28', text: 'People tell me that my child worries too much.' },
      { property: 'SCAREDP_29', text: `My child doesn't like to be away from his/her family.` },
      { property: 'SCAREDP_30', text: 'My child is afraid of having anxiety (or panic) attacks.' },
      { property: 'SCAREDP_31', text: 'My child worries that something bad might happen to his/her parents.' },
      { property: 'SCAREDP_32', text: `My child feels shy with people he/she doesn't know well.` },
      { property: 'SCAREDP_33', text: 'My child worries about what is going to happen in the future.' },
      { property: 'SCAREDP_34', text: 'When my child gets frightened, he/she feels like throwing up.' },
      { property: 'SCAREDP_35', text: 'My child worries about how well he/she does things.' },
      { property: 'SCAREDP_36', text: 'My child is scared to go to school.' },
      { property: 'SCAREDP_37', text: 'My child worries about things that have already happened.' },
      { property: 'SCAREDP_38', text: 'When my child gets frightened, he/she feels dizzy.' },
      { property: 'SCAREDP_39', text: 'My child feels nervous when he/she is with other children or adults and he/she has to do something while they watch him/ her (for example: read aloud, speak, play a game, play a sport.)' },
      { property: 'SCAREDP_40', text: `My child feels nervous when he/she is going to parties, dances, or any place where there will be people that he/ she doesn't know well.` },
      { property: 'SCAREDP_41', text: 'My child is shy.' }
    ],
    dataPrefix: 'SCAREDP_',
    scoreRange: [0, 1, 2]
  },
  'ces-dcad': { // Center for Epidemiological Studies Depression Scale for Children
    selection_headers: [
      'How much you have felt this way during the past week.',
      'Not at All',
      'A Little',
      'Some',
      'A Lot'
    ],
    yes_no_headers: [],
    text_input_headers: [],
    steps: [
      { property: 'CESDC_1', text: 'I was bothered by things that usually don’t bother me.' },
      { property: 'CESDC_2', text: 'I did not feel like eating, I wasn’t very hungry.' },
      { property: 'CESDC_3', text: 'I wasn’t able to feel happy, even when my family or friends tried to help me feel better.' },
      { property: 'CESDC_4', text: 'I felt like I was just as good as other kids.', reversed: true},
      { property: 'CESDC_5', text: 'I felt like I couldn’t pay attention to what I was doing.' },
      { property: 'CESDC_6', text: 'I felt down and unhappy.' },
      { property: 'CESDC_7', text: 'I felt like I was too tired to do things.' },
      { property: 'CESDC_8', text: 'I felt like something good was going to happen.', reversed: true },
      { property: 'CESDC_9', text: 'I felt like things I did before didn’t work out right.' },
      { property: 'CESDC_10', text: 'I felt scared.' },
      { property: 'CESDC_11', text: 'I didn’t sleep as well as I usually sleep.' },
      { property: 'CESDC_12', text: 'I was happy.', reversed: true},
      { property: 'CESDC_13', text: 'I was more quiet than usual.' },
      { property: 'CESDC_14', text: 'I felt lonely, like I didn’t have any friends.' },
      { property: 'CESDC_15', text: 'I felt like kids I know were not friendly or that they didn’t want to be with me.' },
      { property: 'CESDC_16', text: 'I had a good time.', reversed: true },
      { property: 'CESDC_17', text: 'I felt like crying.' },
      { property: 'CESDC_18', text: 'I felt sad.' },
      { property: 'CESDC_19', text: 'I felt people didn’t like me.' },
      { property: 'CESDC_20', text: 'It was hard to get started doing things.' }
    ],
    dataPrefix: 'CESDC_',
    scoreRange: [0, 1, 2, 3]
  },
  cadbiad: { // Child and Adolescent Disruptive Behavior
    hasSections: true,
    numSections: 6,
    section_1: {
      selection_headers: [
        'Behavior toward adults',
        'Never in the past month',
        '1–2 times in the past month',
        '3–4 times in the past month',
        '2–6 times per week',
        '1 time per day',
        '2–5 times per day',
        '6–9 times per day',
        '10 or more times per day'
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'CADBI_1', text: 'Argues with adults.' },
        { property: 'CADBI_2', text: `Loses temper or gets angry with adults when doesn't get own way (sasses adults, talks back to adults).` },
        { property: 'CADBI_3', text: `Refuses to obey adults' requests or rules.` },
        { property: 'CADBI_4', text: 'Annoys adults on purpose.' },
        { property: 'CADBI_5', text: 'Blames adults for his/her mistakes or misbehavior.' },
        { property: 'CADBI_6', text: 'Becomes annoyed or irritated by the behavior of adults.' },
        { property: 'CADBI_7', text: 'Appears angry or resentful toward adults.' },
        { property: 'CADBI_8', text: 'When angry or upset with adults, attempts to get even with them (vindictive or spiteful toward adults).' },
      ],
      scoreRange: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    section_2: {
      selection_headers: [
        `Cause significant problems for the child's adjustment?`,
        'Definitely no',
        'Maybe',
        'Definitely yes'
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'CADBI_8a', text: `Do the behaviors described in items 1 to 8 CURRENTLY cause significant problems for the child's adjustment?` },
      ],
      scoreRange: [0, 1, 2]
    },
    section_3: {
      selection_headers: [
        'Behavior toward peers',
        'Never in the past month',
        '1–2 times in the past month',
        '3–4 times in the past month',
        '2–6 times per week',
        '1 time per day',
        '2–5 times per day',
        '6–9 times per day',
        '10 or more times per day',
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'CADBI_9', text: 'Argues with peers.' },
        { property: 'CADBI_10', text: `Loses temper or gets angry with peers when doesn't get own way.` },
        { property: 'CADBI_11', text: 'Refuses to cooperate with reasonable requests from peers.' },
        { property: 'CADBI_12', text: 'Annoys peers on purpose.' },
        { property: 'CADBI_13', text: 'Blames peers for his/her mistakes or misbehavior.' },
        { property: 'CADBI_14', text: 'Becomes annoyed or irritated by the behavior of peers.' },
        { property: 'CADBI_15', text: 'Appears angry or resentful toward peers.' },
        { property: 'CADBI_16', text: 'When angry or upset with peers, attempts to get even with them (vindictive or spiteful toward peers).' },
      ],
      scoreRange: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    section_4: {
      selection_headers: [
        `Cause significant problems for the child's adjustment?`,
        'Definitely no',
        'Maybe',
        'Definitely yes',
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'CADBI_16a', text: `Do the behaviors described in items 9 to 16 CURRENTLY cause significant problems for the child's adjustment?` },
      ],
      scoreRange: [0, 1, 2]
    },
    section_5: {
      selection_headers: [
        'Activity level',
        'Never in the past month',
        '1–2 times in the past month',
        '3–4 times in the past month',
        '2–6 times per week',
        '1 time per day',
        '2–5 times per day',
        '6–9 times per day',
        '10 or more times per day',
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'CADBI_17', text: 'Fidgets with hands or feet or squirms in seat.' },
        { property: 'CADBI_18', text: 'Leaves seat in situations where remaining seated is expected, such as mealtimes at home, in restaurants, or at church.' },
        { property: 'CADBI_19', text: 'Runs about or climbs on things where it is inappropriate, such as at restaurants, at church, or at home.' },
        { property: 'CADBI_20', text: 'Has trouble playing or socializing quietly (makes too much noise).' },
        { property: 'CADBI_21', text: 'Talks too much during classroom activities.' },
        { property: 'CADBI_22', text: 'Acts as if "driven by a motor" or seems "on the go".' },
        { property: 'CADBI_23', text: 'Blurts out answers before the questions are completed.' },
        { property: 'CADBI_24', text: 'Does not wait turn in activities (games, waiting in lines, to be served at mealtimes).' },
        { property: 'CADBI_25', text: `Interrupts or intrudes on others (butts into others' games or conversations).` },
      ],
      scoreRange: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    section_6: {
      selection_headers: [
        `Cause significant problems for the child's adjustment?`,
        'Definitely no',
        'Maybe',
        'Definitely yes'
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'CADBI_25a', text: `Do the behaviors described in items 17 to 25 CURRENTLY cause significant problems for the child's adjustment?` }
      ],
      scoreRange: [0, 1, 2]
    },
    dataPrefix: 'CADBI_',
  },
  cpssad: { // The Child PTSD Symptom Scale
    selection_headers: [
      'How often the problem has bothered you in the last month?',
      'Not at all',
      'Once a week',
      '2 to 3 times a week',
      '4 to 5 times a week',
      '6 or more times',
    ],
    yes_no_headers: [
      'Have the problems been getting in the way of these parts of your life IN THE PAST MONTH?',
      'No',
      'Yes'
    ],
    text_input_headers: [
      '',
      'Client response'
    ],
    steps: [
      { property: 'DistressEvent', text: 'Please write down the scary or upsetting thing that bothers you the most when you think about it.' },
      { property: 'TOE', text: 'When did it happen?' },
      { property: 'CPSS_1', text: 'Having upsetting thoughts or pictures about it that came into your head when you didn’t want them to.' },
      { property: 'CPSS_2', text: 'Having bad dreams or nightmares.' },
      { property: 'CPSS_3', text: 'Acting or feeling as if it was happening again (seeing or hearing something and feeling as if you are there again).' },
      { property: 'CPSS_4', text: 'Feeling upset when you remember what happened (for example, feeling scared, angry, sad, guilty, confused).' },
      { property: 'CPSS_5', text: 'Having feelings in your body when you remember what happened (for example, sweating, heart beating fast, stomach or head hurting).' },
      { property: 'CPSS_6', text: 'Trying not to think about it or have feelings about it.' },
      { property: 'CPSS_7', text: 'Trying to stay away from anything that reminds you of what happened (for example, people, places, or conversations about it).' },
      { property: 'CPSS_8', text: 'Not being able to remember an important part of what happened.' },
      { property: 'CPSS_9', text: 'Having bad thoughts about yourself, other people, or the world (for example, “I can’t do anything right”, “All people are bad”, “The world is a scary place.' },
      { property: 'CPSS_10', text: 'Thinking that what happened is your fault (for example, “I should have known better”, “I shouldn’t have done that”, “I deserved it”).' },
      { property: 'CPSS_11', text: 'Having strong bad feelings (like fear, anger, guilt, or shame).' },
      { property: 'CPSS_12', text: 'Having much less interest in doing things you used to do.' },
      { property: 'CPSS_13', text: 'Not feeling close to your friends or family or not wanting to be around them.' },
      { property: 'CPSS_14', text: 'Trouble having good feelings (like happiness or love) or trouble having any feelings at all.' },
      { property: 'CPSS_15', text: 'Getting angry easily (for example, yelling, hitting others, throwing things).' },
      { property: 'CPSS_16', text: 'Doing things that might hurt yourself (for example, taking drugs, drinking alcohol, running away, cutting yourself).' },
      { property: 'CPSS_17', text: 'Being very careful or on the lookout for danger (for example, checking to see who is around you and what is around you.' },
      { property: 'CPSS_18', text: 'Being jumpy or easily scared (for example, when someone walks up behind you, when you hear a loud noise).' },
      { property: 'CPSS_19', text: 'Having trouble paying attention (for example, losing track of a story on TV, forgetting what you read, unable to pay attention in class).' },
      { property: 'CPSS_20', text: 'Having trouble falling or staying asleep.' },
      { property: 'CPSS_23', text: 'Fun things you want to do.' },
      { property: 'CPSS_24', text: 'Doing your chores.' },
      { property: 'CPSS_25', text: 'Relationships with your friends.' },
      { property: 'CPSS_26', text: 'Praying.' },
      { property: 'CPSS_27', text: 'Schoolwork.' },
      { property: 'CPSS_28', text: 'Relationships with your family.' },
      { property: 'CPSS_29', text: 'Being happy with your life.' }
    ],
    dataPrefix: 'CPSS_',
    scoreRange: [0, 1, 2, 3, 4] 
  },
  phqad: { // Patient Health Questionnaire
    // TODO: Empty table sections
    hasSections: true,
    numSections: 8,
    section_1: {
      selection_headers: [
        'During the last 4 weeks, how often have you been bothered by any of the following problems?',
        'Not bothered',
        'Bothered a little',
        'Bothered a lot',
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'phq1a', text: 'Stomach Pain.' },
        { property: 'phq1b', text: 'Back Pain.' },
        { property: 'phq1c', text: 'Pain in your arms, legs, or joins (knees, hips, etc.)' },
        { property: 'phq1d', text: 'Menstrual cramps or other problems with your period.' },
        { property: 'phq1e', text: 'Pain or problems during sexual intercourse.' },
        { property: 'phq1f', text: 'Headaches.' },
        { property: 'phq1g', text: 'Chest pain.' },
        { property: 'phq1h', text: 'Dizziness.' },
        { property: 'phq1i', text: 'Fainting spells.' },
        { property: 'phq1j', text: 'Feeling your heart pound or race.' },
        { property: 'phq1k', text: 'Shortness of breath.' },
        { property: 'phq1l', text: 'Constipation, loose bowels, or diarrhea.' },
        { property: 'phq1m', text: 'Nausea, gas, or indigestion.' },
      ],
      scoreRange: [0, 1, 2]
    },
    section_2: {
      selection_headers: [
        'Over the last 2 weeks, how often have you been bothered by any of the following problems?',
        'Not at all',
        'Several Days',
        'More than half the days',
        'Nearly every day',
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'phq2a', text: 'Little interest or pleasure in doing things.' },
        { property: 'phq2b', text: 'Feeling down, depressed, or hopeless.' },
        { property: 'phq2c', text: 'Trouble falling or staying asleep, or sleeping too much.' },
        { property: 'phq2d', text: 'Feeling tired or having little energy.' },
        { property: 'phq2e', text: 'Poor appetite or overeating.' },
        { property: 'phq2f', text: 'Feeling bad about yourself, or that you are a failure, or have let yourself or your family down' },
        { property: 'phq2g', text: 'Trouble concentrating on things, such as reading the newspaper or watching television.' },
        { property: 'phq2h', text: 'Moving or speaking so slowly that other people could have noticed. Or the opposite–being so fidgety or restless that you have been moving around a lot more than usual.' },
        { property: 'phq2i', text: 'Thoughts that you would be better off dead or of hurting yourself in some way.' },
      ],
      scoreRange: [0, 1, 2, 3]
    },
    section_3: {
      selection_headers: [],
      yes_no_headers: [
        'Over the last 4 weeks, how often have you been bothered by any of the following problems?',
        'No',
        'Yes'
      ],
      text_input_headers: [],
      steps: [
        { property: 'phq3a', text: 'Had an anxiety attack –– suddenly feeling fear or panic?' },
        { property: 'phq3b', text: 'Has this ever happened before?' },
        { property: 'phq3c', text: `Do some of these attacks come suddenly out of the blue –– that is, in situations where you don’t expect to be nervous or uncomfortable?` },
        { property: 'phq3d', text: 'Do these attacks bother you a lot or are you worried about having another attack?' },
        { property: 'phq4a', text: 'Were you short of breath?' },
        { property: 'phq4b', text: 'Did your heart race, pound, or skip?' },
        { property: 'phq4c', text: 'Did you have chest pain or pressure?' },
        { property: 'phq4d', text: 'Did you sweat?' },
        { property: 'phq4e', text: 'Did you feel as if you were choking?' },
        { property: 'phq4f', text: 'Did you have hot flashes or chills?' },
        { property: 'phq4g', text: 'Did you have nausea or an upset stomach, or the feeling that you were going to have diarrhea?' },
        { property: 'phq4h', text: 'Did you feel dizzy, unsteady, or faint?' },
        { property: 'phq4i', text: 'Did you have tingling or numbness in parts of your body?' },
        { property: 'phq4j', text: 'Did you tremble or shake?' },
        { property: 'phq4k', text: 'Were you afraid you were dying?' },
      ],
      scoreRange: [0, 1]
    },
    section_4: {
      selection_headers: [
        'Over the last 4 weeks, how often have you been bothered by any of the following problems?',
        'Not at all',
        'Several days',
        'More than half the days',
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'phq5a', text: 'Feeling nervous, anxious, on edge, or worrying a lot about different things.' },
        { property: 'phq5b', text: 'Feeling restless so that it is hard to sit still.' },
        { property: 'phq5c', text: 'Getting tired very easily.' },
        { property: 'phq5d', text: 'Muscle tension, aches, or soreness.' },
        { property: 'phq5e', text: 'Trouble falling asleep or staying asleep.' },
        { property: 'phq5f', text: 'Trouble concentrating on things, such as reading a book or watching TV.' },
        { property: 'phq5g', text: 'Becoming easily annoyed or irritable.' },
      ],
      scoreRange: [0, 1, 2]
    },
    section_5: {
      selection_headers: [],
      yes_no_headers: [
        'Questions about eating',
        'No',
        'Yes'
      ],
      text_input_headers: [],
      steps: [
        { property: 'phq6a', text: `Do you often feel that you can’t control what or how much you eat?` },
        { property: 'phq6b', text: 'Do you often eat, within any 2-hour period, what most people would regard as an unusually large amount of food?' },
        { property: 'phq6c', text: 'Has this been as often, on average, as twice a week for the last 3 months?' },
      ],
      scoreRange: [0, 1]
    },
    section_6: {
      selection_headers: [],
      yes_no_headers: [
        'In the last 3 months have you often done any of the following in order to avoid gaining weight?',
        'No',
        'Yes'
      ],
      text_input_headers: [],
      steps: [
        { property: 'phq7a', text: 'Made yourself vomit?' },
        { property: 'phq7b', text: 'Took more than twice the recommended dose of laxatives?' },
        { property: 'phq7c', text: 'Fasted –– not eaten anything at all for at least 24 hours?' },
        { property: 'phq7d', text: 'Exercised for more than an hour specifically to avoid gaining weight after binge eating?' },
        { property: 'phq8', text: 'If you checked “YES” to any of these ways of avoiding gaining weight, were any as often, on average, as twice a week?' },
        { property: 'phq9', text: 'Do you ever drink alcohol (including beer or wine)?' },
      ],
      scoreRange: [0, 1]
    },
    section_7: {
      selection_headers: [],
      yes_no_headers: [
        'Have any of the following happened to you more than once in the last 6 months?',
        'No',
        'Yes'
      ],
      text_input_headers: [],
      steps: [
        { property: 'phq10a', text: 'You drank alcohol even though a doctor suggested that you stop drinking because of a problem with your health.' },
        { property: 'phq10b', text: 'You drank alcohol, were high from alcohol, or hung over while you were working, going to school, or taking care of children or other responsibilities.' },
        { property: 'phq10c', text: 'You missed or were late for work, school, or other activities because you were drinking or hung over.' },
        { property: 'phq10d', text: 'You had a problem getting along with other people while you were drinking.' },
        { property: 'phq10e', text: 'You drove a car after having several drinks or after drinking too much.' },
      ],
      scoreRange: [0, 1]
    },
    section_8: {
      selection_headers: [
        '',
        'Not difficult at all',
        'Somewhat difficult',
        'Very difficult',
        'Extremely difficult'
      ],
      yes_no_headers: [],
      text_input_headers: [],
      steps: [
        { property: 'phq11', text: 'If you checked off any problems on this questionnaire, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?' }
      ],
      scoreRange: [0, 1, 2, 3]
    },
    dataPrefix: 'PHQ_'
  },
  'lsas-srad': { // Liebowitz Social Anxiety Scale Self-Report
    selection_headers: [
      'How have these situations have affected you in the last week - How often have you avoided them?',
      'None - Never',
      'Mild - Occasionally',
      'Moderate - Often',
      'Severe - Usually',
    ],
    yes_no_headers: [],
    text_input_headers: [],
    steps: [
      { property: 'LSAS-SR_1a', text: '(Affected by) Telephoning in public.' },
      { property: 'LSAS-SR_1b', text: '(Avoidant of) Telephoning in public.' },
      { property: 'LSAS-SR_2a', text: '(Affected by) Participating in small groups.' },
      { property: 'LSAS-SR_2b', text: '(Avoidant of) Participating in small groups.' },
      { property: 'LSAS-SR_3a', text: '(Affected by) Eating in public places.' },
      { property: 'LSAS-SR_3b', text: '(Avoidant of) Eating in public places.' },
      { property: 'LSAS-SR_4a', text: '(Affected by) Drinking with others in public places.' },
      { property: 'LSAS-SR_4b', text: '(Avoidant of) Drinking with others in public places.' },
      { property: 'LSAS-SR_5a', text: '(Affected by) Talking to people in authority.' },
      { property: 'LSAS-SR_5b', text: '(Avoidant of) Talking to people in authority.' },
      { property: 'LSAS-SR_6a', text: '(Affected by) Acting, performing, or giving a talk in front of an audience.' },
      { property: 'LSAS-SR_6b', text: '(Avoidant of) Acting, performing, or giving a talk in front of an audience.' },
      { property: 'LSAS-SR_7a', text: '(Affected by) Going to a party.' },
      { property: 'LSAS-SR_7b', text: '(Avoidant of) Going to a party.' },
      { property: 'LSAS-SR_8a', text: '(Affected by) Working while being observed.' },
      { property: 'LSAS-SR_8b', text: '(Avoidant of) Working while being observed.' },
      { property: 'LSAS-SR_9a', text: '(Affected by) Writing while being observed.' },
      { property: 'LSAS-SR_9b', text: '(Avoidant of) Writing while being observed.' },
      { property: 'LSAS-SR_10a', text: `(Affected by) Calling someone you don't know very well.` },
      { property: 'LSAS-SR_10b', text: `(Avoidant of) Calling someone you don't know very well.` },
      { property: 'LSAS-SR_11a', text: `(Affected by) Talking with people you don't know very well.` },
      { property: 'LSAS-SR_11b', text: `(Avoidant of) Talking with people you don't know very well.` },
      { property: 'LSAS-SR_12a', text: '(Affected by) Meeting strangers.' },
      { property: 'LSAS-SR_12b', text: '(Avoidant of) Meeting strangers.' },
      { property: 'LSAS-SR_13a', text: '(Affected by) Urinating in a public bathroom.' },
      { property: 'LSAS-SR_13b', text: '(Avoidant of) Urinating in a public bathroom.' },
      { property: 'LSAS-SR_14a', text: '(Affected by) Entering a room when others are already seated.' },
      { property: 'LSAS-SR_14b', text: '(Avoidant of) Entering a room when others are already seated.' },
      { property: 'LSAS-SR_15a', text: '(Affected by) Being the center of attention.' },
      { property: 'LSAS-SR_15b', text: '(Avoidant of) Being the center of attention.' },
      { property: 'LSAS-SR_16a', text: '(Affected by) Speaking up at a meeting.' },
      { property: 'LSAS-SR_16b', text: '(Avoidant of) Speaking up at a meeting.' },
      { property: 'LSAS-SR_17a', text: '(Affected by) Taking a written test.' },
      { property: 'LSAS-SR_17b', text: '(Avoidant of) Taking a written test.' },
      { property: 'LSAS-SR_18a', text: `(Affected by) Expressing appropriate disagreement or disapproval to people you don't know very well.` },
      { property: 'LSAS-SR_18b', text: `(Avoidant of) Expressing appropriate disagreement or disapproval to people you don't know very well.` },
      { property: 'LSAS-SR_19a', text: `(Affected by) Looking at people you don't know very well in the eyes.` },
      { property: 'LSAS-SR_19b', text: `(Avoidant of) Looking at people you don't know very well in the eyes.` },
      { property: 'LSAS-SR_20a', text: '(Affected by) Giving a report to a group.' },
      { property: 'LSAS-SR_20b', text: '(Avoidant of) Giving a report to a group.' },
      { property: 'LSAS-SR_21a', text: '(Affected by) Trying to pick up someone.' },
      { property: 'LSAS-SR_21b', text: '(Avoidant of) Trying to pick up someone.' },
      { property: 'LSAS-SR_22a', text: '(Affected by) Returning goods to a store.' },
      { property: 'LSAS-SR_22b', text: '(Avoidant of) Returning goods to a store.' },
      { property: 'LSAS-SR_23a', text: '(Affected by) Giving a party.' },
      { property: 'LSAS-SR_23b', text: '(Avoidant of) Giving a party.' },
      { property: 'LSAS-SR_24a', text: '(Affected by) Resisting a high pressure salesperson.' },
      { property: 'LSAS-SR_24b', text: '(Avoidant of) Resisting a high pressure salesperson.' }
    ],
    dataPrefix: 'LSAS-SR_',
    scoreRange: [0, 1, 2, 3]
  }
}
