const memoryStorage = {}

export default {
  getStorage: function() {
    return window.localStorage
  },
  isSupported: function() {
    try {
      const key = 'test'

      this.getStorage().setItem(key, key)
      this.getStorage().removeItem(key)

      return true
    } catch (e) {
      return false
    }
  },
  setItem: function(key, value) {
    if (this.isSupported()) {
      this.getStorage().setItem(key, value)
    } else {
      memoryStorage[key] = value
    }
  },
  getItem: function(key) {
    if (this.isSupported()) {
      return this.getStorage().getItem(key)
    }

    return memoryStorage[key] || null
  },
  removeItem: function(key) {
    if (this.isSupported()) {
      this.getStorage().removeItem(key)
    } else {
      if ('key' in memoryStorage) {
        delete memoryStorage[key]
      }
    }
  }
}
