<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters('clients', ['client']),
  methods: {
    clickItemHandler(item) {
      // console.log('item', item)
      const to = { name: item.route }
      if (undefined !== item.slug) {
        to['params'] = { slug: item.slug }
      }
      this.$router.push(to).catch(() => {})
    }
  }
}
</script>
