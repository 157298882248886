import authAction from '@/router/middleware/authAction'
import authenticatedSignin from '@/router/middleware/authenticatedSignin'

export default [
  {
    path: 'sign-up',
    name: 'signUp',
    component: () => import('@/views/auth/SignUp.vue'),
    meta: { middleware: [authenticatedSignin] }
  },
  {
    path: 'sign-up-complete',
    name: 'signUpComplete',
    component: () => import('@/views/auth/SignUpComplete.vue'),
    meta: { middleware: [authenticatedSignin] }
  },
  
  // {
  //   path: 'sign-in',
  //   name: 'signIn',
  //   component: () => import('@/views/auth/SignIn.vue'),
  //   meta: { middleware: [authenticatedSignin] }
  // },
  {
    path: 'reset-password',
    name: 'resetPassword',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: { middleware: [authenticatedSignin] }
  },
  {
    path: 'reset-password-complete',
    name: 'resetPasswordComplete',
    component: () => import('@/views/auth/ResetPasswordComplete.vue'),
    meta: { middleware: [authenticatedSignin] }
  },
  {
    path: 'action/verify-email',
    name: 'authActionVerifyEmail',
    component: () => import('@/views/auth/ActionVerifyEmail.vue'),
    meta: { middleware: [authenticatedSignin] }
  },
  {
    path: 'action/reset-password',
    name: 'authActionResetPassword',
    component: () => import('@/views/auth/ActionResetPassword.vue'),
    meta: { middleware: [authenticatedSignin] }
  },
  {
    path: 'action',
    meta: { middleware: [authAction] }
  }
]
