<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8333 2.10623C22.7691 2.0526 22.6914 2.01759 22.6087 2.00498C22.5259 1.99238 22.4414 2.00267 22.364 2.03473L1.28226 10.7425C1.198 10.7776 1.12615 10.837 1.07593 10.9133C1.02571 10.9896 0.999408 11.0791 1.00039 11.1703C1.00138 11.2616 1.02961 11.3506 1.08146 11.4257C1.13331 11.5008 1.20643 11.5588 1.29143 11.5921L7.09533 13.846C7.16554 13.8734 7.24142 13.8831 7.31624 13.8741C7.39107 13.8651 7.46253 13.8378 7.5243 13.7947L15.2238 8.31892C15.3179 8.25079 15.4345 8.22103 15.55 8.23567C15.6653 8.25031 15.7708 8.30826 15.845 8.39777C15.9193 8.48728 15.9567 8.6016 15.9497 8.71771C15.9428 8.83375 15.892 8.94285 15.8076 9.02287L9.39145 15.2054C9.34704 15.2481 9.31177 15.2993 9.28771 15.3561C9.26358 15.4127 9.25114 15.4737 9.25121 15.5353V21.7068C9.25148 21.8072 9.28475 21.9048 9.34594 21.9845C9.40712 22.0642 9.49278 22.1216 9.58971 22.1479C9.68671 22.174 9.78962 22.1677 9.88263 22.1298C9.97564 22.0918 10.0536 22.0244 10.1045 21.9378L13.0074 16.9652C13.0377 16.9136 13.0869 16.8759 13.1446 16.86C13.2023 16.844 13.2638 16.8512 13.3163 16.88L18.6564 19.8131C18.7189 19.8476 18.7887 19.8669 18.86 19.8696C18.9313 19.8724 19.0023 19.8584 19.0674 19.8289C19.1323 19.7993 19.1895 19.755 19.2343 19.6994C19.2792 19.6438 19.3104 19.5786 19.3255 19.5088L22.9919 2.5517C23.0077 2.47005 23.0013 2.38567 22.9734 2.30734C22.9455 2.229 22.8971 2.15955 22.8333 2.10623Z"
      fill="white"
    />
    <path
      d="M22.8333 2.10623C22.7691 2.0526 22.6914 2.01759 22.6087 2.00498C22.5259 1.99238 22.4414 2.00267 22.364 2.03473L1.28226 10.7425C1.198 10.7776 1.12615 10.837 1.07593 10.9133C1.02571 10.9896 0.999408 11.0791 1.00039 11.1703C1.00138 11.2616 1.02961 11.3506 1.08146 11.4257C1.13331 11.5008 1.20643 11.5588 1.29143 11.5921L7.09533 13.846C7.16554 13.8734 7.24142 13.8831 7.31624 13.8741C7.39107 13.8651 7.46253 13.8378 7.5243 13.7947L15.2238 8.31892C15.3179 8.25079 15.4345 8.22103 15.55 8.23567C15.6653 8.25031 15.7708 8.30826 15.845 8.39777C15.9193 8.48728 15.9567 8.6016 15.9497 8.71771C15.9428 8.83375 15.892 8.94285 15.8076 9.02287L9.39145 15.2054C9.34704 15.2481 9.31177 15.2993 9.28771 15.3561C9.26358 15.4127 9.25114 15.4737 9.25121 15.5353V21.7068C9.25148 21.8072 9.28475 21.9048 9.34594 21.9845C9.40712 22.0642 9.49278 22.1216 9.58971 22.1479C9.68671 22.174 9.78962 22.1677 9.88263 22.1298C9.97564 22.0918 10.0536 22.0244 10.1045 21.9378L13.0074 16.9652C13.0377 16.9136 13.0869 16.8759 13.1446 16.86C13.2023 16.844 13.2638 16.8512 13.3163 16.88L18.6564 19.8131C18.7189 19.8476 18.7887 19.8669 18.86 19.8696C18.9313 19.8724 19.0023 19.8584 19.0674 19.8289C19.1323 19.7993 19.1895 19.755 19.2343 19.6994C19.2792 19.6438 19.3104 19.5786 19.3255 19.5088L22.9919 2.5517C23.0077 2.47005 23.0013 2.38567 22.9734 2.30734C22.9455 2.229 22.8971 2.15955 22.8333 2.10623Z"
      fill="#BFBDBB"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-neutralMedium-base)'
    }
  }
}
</script>