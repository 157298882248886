export default [
  {
    id: 1,
    title: "Relaxation",
    image: "relaxation.svg",
    subtitle: "Practice with {name}...",
    description: "Relaxation exercises to help clients practice deep breathing, muscle relaxation, and mindfulness",
    items: [
      {
        id: 1,
        exercise: 1,
        category: "Relaxation",
        title: "Deep breathing",
        type: 'video',
        text: "Practice deep breathing guided by audio and visual animation",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "relaxation-choose",
            params: {
              nextBtnLabel: "Remind",
              duration: 300
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice deep breathing. This will only take 5 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice deep breathing. This will only take 5 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "MTo6ZGVlcCBicmVhdGhpbmc="
      },
      {
        id: 2,
        exercise: 2,
        category: "Relaxation",
        title: "Muscle relaxation for adults",
        type: 'video',
        text: "Practice progressive muscle relaxation guided by audio instructions",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "audio",
            params: {
              nextBtnLabel: "Remind",
              audios: [
                {
                  id: 1,
                  label: "6 min",
                  audioFile: "muscle-relaxation-adult-brief.mp3",
                  imageFile: "muscle-relaxation.svg",
                  duration: 375,
                  selected: true
                },
                {
                  id: 2,
                  label: "10 min",
                  audioFile: "muscle-relaxation-adult-long.mp3",
                  imageFile: "muscle-relaxation.svg",
                  duration: 570
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice muscle relaxation. This will only take 6-10 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice muscle relaxation. This will only take 6-10 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "MTo6bXVzY2xlIHJlbGF4YXRpb24gZm9yIGFkdWx0cw=="
      },
      {
        id: 6,
        exercise: 26,
        category: "Relaxation",
        title: "Roll breathing",
        type: 'video',
        text: "Practice roll breathing guided by audio and a visual animation",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "video",
            type: "video",
            params: {
              nextBtnLabel: "Remind",
              duration: 480,
              videoFiles: [
                {
                  src: "roll-breathing.mp4",
                  type: "video/mp4"
                },
                {
                  src: "roll-breathing.webm",
                  type: "video/webm"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice roll breathing. This will only take 8 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice roll breathing. This will only take 8 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "MTo6cm9sbCBicmVhdGhpbmc="
      },
      {
        id: 3,
        exercise: 3,
        category: "Relaxation",
        title: "Muscle relaxation for children",
        type: 'video',
        text: "Practice progressive muscle relaxation guided by audio instructions",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              nextBtnLabel: "Remind",
              videos: [
                {
                  id: 1,
                  label: "6 min",
                  videoId: "IC4LBM5qTdM",
                  selected: true
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice muscle relaxation. This will only take 6 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice muscle relaxation. This will only take 6 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "MTo6bXVzY2xlIHJlbGF4YXRpb24gZm9yIGNoaWxkcmVu"
      },
      {
        id: 4,
        exercise: 4,
        category: "Relaxation",
        title: "Mindfulness for adults",
        type: 'video',
        text: "Practice mindfulness guided by audio instructions",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "audio",
            params: {
              nextBtnLabel: "Remind",
              audios: [
                {
                  id: 1,
                  label: "6 min",
                  audioFile: "mindfulness-adult-brief.mp3",
                  imageFile: "muscle-relaxation.svg",
                  duration: 405,
                  selected: true
                },
                {
                  id: 2,
                  label: "15 min",
                  audioFile: "mindfulness-adult-long.mp3",
                  imageFile: "muscle-relaxation.svg",
                  duration: 910
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice mindfulness. This will only take 6-15 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice mindfulness. This will only take 6-15 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "MTo6bWluZGZ1bG5lc3MgZm9yIGFkdWx0cw=="
      },
      {
        id: 5,
        exercise: 5,
        category: "Relaxation",
        title: "Mindfulness for children",
        type: 'video',
        text: "Practice mindfulness guided by audio instructions",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              nextBtnLabel: "Remind",
              videos: [
                {
                  id: 1,
                  label: "6 min",
                  videoId: "jU92aulVeaQ",
                  selected: true
                },
                {
                  id: 2,
                  label: "12 min",
                  videoId: "W8Mjl9jIOg4"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice mindfulness. This will only take 6-12 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice mindfulness. This will only take 6-12 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "MTo6bWluZGZ1bG5lc3MgZm9yIGNoaWxkcmVu"
      },
      {
        id: 7,
        exercise: 59,
        category: "Relaxation",
        title: "Morning breathing",
        type: 'video',
        text: "Practice morning breathing guided by audio and a visual animation",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "video",
            type: "video",
            params: {
              nextBtnLabel: "Remind",
              duration: 211,
              videoFiles: [
                {
                  src: "morning-breathing.mp4",
                  type: "video/mp4"
                },
                {
                  src: "morning-breathing.webm",
                  type: "video/webm"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice morning breathing. This will only take 4 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice morning breathing. This will only take 4 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "08:00"
          ]
        },
        uid: "MTo6bW9ybmluZyBicmVhdGhpbmc="
      },
      {
        id: 8,
        exercise: 68,
        category: "Relaxation",
        title: "4-7-8 breathing",
        type: 'video',
        text: "Practice 4-7-8 breathing guided by audio and visual animation",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "video",
            type: "video",
            params: {
              nextBtnLabel: "Remind",
              duration: 238,
              videoFiles: [
                {
                  src: "478-breathing.mp4",
                  type: "video/mp4"
                },
                {
                  src: "478-breathing.webm",
                  type: "video/webm"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice 4-7-8 breathing. This will only take 4 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice 4-7-8 breathing. This will only take 4 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "MTo6NC03LTggYnJlYXRoaW5n"
      },
      {
        id: 9,
        exercise: 37,
        category: "Relaxation",
        title: "Belly breathing",
        type: 'video',
        text: "Practice belly (i.e., diaphragmatic) breathing guided by audio and visual animation",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "video",
            type: "video",
            params: {
              nextBtnLabel: "Remind",
              duration: 135,
              videoFiles: [
                {
                  src: "belly-breathing.mp4",
                  type: "video/mp4"
                },
                {
                  src: "belly-breathing.webm",
                  type: "video/webm"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice belly breathing. This will only take 2 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice belly breathing. This will only take 2 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "MTo6YmVsbHkgYnJlYXRoaW5n"
      }
    ]
  },
  {
    id: 2,
    title: "Affect and emotion",
    image: "affect-and-emotion.svg",
    subtitle: "Practice with <b>{name}</b>...",
    description: "Exercises to help clients identify emotions",
    items: [
      {
        id: 1,
        exercise: 6,
        category: "Affect and emotion",
        title: "Identifying feelings",
        type: 'game',
        text: "Identify whether a word describes a feeling",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "idfeel",
              preview: "idfeel"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play the identifying feelings game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to play the identifying feelings game. This will only take 3 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Mjo6aWRlbnRpZnlpbmcgZmVlbGluZ3M="
      },
      {
        id: 2,
        exercise: 7,
        category: "Affect and emotion",
        title: "Emoji emotion matching",
        type: 'game',
        text: "Match the feeling that describes the emoji",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "emojimatch",
              preview: "emojimatch"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play the emoji emotion matching game. This will only take 2 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to play the emoji emotion matching game. This will only take 2 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Mjo6ZW1vamkgZW1vdGlvbiBtYXRjaGluZw=="
      },
      {
        id: 3,
        exercise: 8,
        category: "Affect and emotion",
        title: "Picture emotion matching",
        type: 'game',
        text: "Match the feeling that describes the person in the picture",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "picmatch",
              preview: "picmatch"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play the picture emotion matching game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to play the picture emotion matching game. This will only take 3 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Mjo6cGljdHVyZSBlbW90aW9uIG1hdGNoaW5n"
      }
    ]
  },
  {
    id: 3,
    title: "Cognitive",
    image: "cognitive.svg",
    subtitle: "Practice with <b>{name}</b>...",
    description: "Cognitive exercises to help clients identify and change unhelpful thoughts",
    items: [
      {
        id: 1,
        exercise: 9,
        category: "Cognitive",
        title: "Thoughts, feelings, actions",
        type: 'game',
        text: "Identify whether a word is a thought, feeling, or action",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "tfa",
              preview: "tfa"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play a thoughts, feelings, and actions game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to play a thoughts, feelings, and actions game. This will only take 3 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Mzo6dGhvdWdodHMsIGZlZWxpbmdzLCBhY3Rpb25z"
      },
      {
        id: 4,
        exercise: 10,
        category: "Cognitive",
        title: "Thoughts, feelings, actions stories",
        type: 'game',
        text: "Read stories and explore how different thoughts affect how the character feels and acts",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "tfas",
              preview: "tfas"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to read some stories about thoughts, feelings, and actions. This will only take about 7 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to read some stories about thoughts, feelings, and actions. This will only take about 7 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Mzo6dGhvdWdodHMsIGZlZWxpbmdzLCBhY3Rpb25zIHN0b3JpZXM="
      },
      {
        id: 2,
        exercise: 11,
        category: "Cognitive",
        title: "Helpful and unhelpful thoughts",
        type: 'game',
        text: "Identify whether a thought is helpful or unhelpful",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "hut",
              preview: "hut"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play a thought matching game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to play a thought matching game. This will only take 3 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Mzo6aGVscGZ1bCBhbmQgdW5oZWxwZnVsIHRob3VnaHRz"
      },
      {
        id: 3,
        exercise: 12,
        category: "Cognitive",
        title: "Changing unhelpful thoughts",
        type: 'game',
        text: "Choose an alternative, helpful thought to replace the unhelpful thought",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "cut",
              preview: "cut"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play a changing unhelpful thoughts game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to play a changing unhelpful thoughts game. This will only take 3 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Mzo6Y2hhbmdpbmcgdW5oZWxwZnVsIHRob3VnaHRz"
      },
      {
        id: 5,
        exercise: 28,
        category: "Cognitive",
        title: "Matching cognitive distortions",
        type: 'game',
        text: "Match each cognitive distortion with its definition",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "mct",
              preview: "mct"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play a matching distorting thoughts game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to play a matching distorting thoughts game. This will only take 3 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "Mzo6bWF0Y2hpbmcgY29nbml0aXZlIGRpc3RvcnRpb25z"
      },
      {
        id: 6,
        exercise: 79,
        category: "Cognitive",
        title: "Socratic questioning",
        type: 'interview',
        text: "Choose a recent, upsetting thought and ask questions to decide if it’s helpful or accurate",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "sques",
              preview: "sques"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to work on socratic questioning. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to work on socratic questioning. This will only take 3 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "Mzo6c29jcmF0aWMgcXVlc3Rpb25pbmc="
      },
      {
        id: 7,
        exercise: 38,
        category: "Cognitive",
        title: "Thought log",
        type: 'interview',
        text: "Choose a recent thought and feelings and actions that followed, decide if it was helpful or unhelpful",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "tlog",
              preview: "tlog"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to work on a thought log. This will only take 2 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to work on a thought log. This will only take 2 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "Mzo6dGhvdWdodCBsb2c="
      }
    ]
  },
  {
    id: 4,
    title: "Exposure",
    image: "exposure.svg",
    subtitle: "Practice with <b>{name}</b>...",
    description: "Exercises to help clients understand and practice exposure therapy",
    items: [
      {
        id: 1,
        exercise: 13,
        category: "Exposure",
        title: "Stories about exposure",
        type: 'game',
        text: "Read stories and explore how different responses to feared situations affect the character",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "sae",
              preview: "sae"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to read some stories about facing fears. This will only take 5 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to read some stories about facing fears. This will only take 5 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "NDo6c3RvcmllcyBhYm91dCBleHBvc3VyZQ=="
      }
    ]
  },
  {
    id: 5,
    title: "Parent-child activities",
    image: "parent-child-activities.svg",
    subtitle: "Practice with <b>{name}</b> and <b>{caregiverName}</b>:",
    description: "Activities for caregivers and children to practice together to strengthen their relationship",
    items: [
      {
        id: 1,
        exercise: 14,
        category: "Parent-child activities",
        title: "Plan a fun activity",
        type: 'journal',
        text: "Plan an activity to do with a parent or caregiver",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What activity would <b>{name}</b> and <b>{caregiverName}</b> like to do together this week?",
              placeholder: "e.g., Go to the park"
            }
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              previewMode: true,
              activity: "pafa",
              preview: "pafaprev",
              inputs: {
                activity: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name} and {caregiverName}! It's time to {remind} together! Click here when you’re done: {link}",
                email: "Hi {name} and {caregiverName}! <br><br>\n                          It's time to {remind} together!<br>\n                          Click here when you’re done: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "NTo6cGxhbiBhIGZ1biBhY3Rpdml0eQ=="
      },
      {
        id: 2,
        exercise: 15,
        category: "Parent-child activities",
        title: "20 questions",
        type: 'game',
        text: "Get to know someone by asking each other 20 questions",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "20ques",
              preview: "20ques"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play the 20 questions game with your parent or caregiver! This will take about 20 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to play the 20 questions game with your parent or caregiver! This will take about 20 minutes. <br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "NTo6MjAgcXVlc3Rpb25z"
      },
      {
        id: 3,
        subCategory: 1,
        category: "Parent-child activities",
        title: "Teach a skill",
        type: 'journal',
        text: "Teach someone a skill learned in therapy to reinforce learning",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "list",
            params: {
              title: "Which skill should {name} teach {caregiverName}?",
              items: [
                {
                  id: 1,
                  exercise: 16,
                  text: "Exposure",
                  dokbotParams: {
                    nextBtnLabel: "Remind",
                    activity: "tas-exp",
                    preview: "tas-exp"
                  },
                  uid: "NTo6ZXhwb3N1cmU="
                },
                {
                  id: 2,
                  exercise: 17,
                  text: "Changing unhelpful thoughts",
                  dokbotParams: {
                    nextBtnLabel: "Remind",
                    activity: "tas-cut",
                    preview: "tas-cut"
                  },
                  uid: "NTo6Y2hhbmdpbmcgdW5oZWxwZnVsIHRob3VnaHRz"
                },
                {
                  id: 3,
                  exercise: 18,
                  text: "Mindfulness",
                  dokbotParams: {
                    nextBtnLabel: "Remind",
                    activity: "tas-mind",
                    preview: "tas-mind"
                  },
                  uid: "NTo6bWluZGZ1bG5lc3M="
                },
                {
                  id: 4,
                  exercise: 19,
                  text: "Muscle relaxation",
                  dokbotParams: {
                    nextBtnLabel: "Remind",
                    activity: "tas-mrelax",
                    preview: "tas-mrelax"
                  },
                  uid: "NTo6bXVzY2xlIHJlbGF4YXRpb24="
                },
                {
                  id: 5,
                  exercise: 20,
                  text: "Deep breathing",
                  dokbotParams: {
                    nextBtnLabel: "Remind",
                    activity: "tas-deepbreath",
                    preview: "tas-deepbreath"
                  },
                  uid: "NTo6ZGVlcCBicmVhdGhpbmc="
                }
              ],
              fullWidth: true
            }
          },
          {
            name: "dokbot"
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice teaching skills with your parent or caregiver! This will only take 10 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>\n                          It's time to practice teaching skills with your parent or caregiver! This will only take 10 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        }
      }
    ]
  },
  {
    id: 7,
    category: 6,
    title: "Parenting videos",
    image: "parenting-videos.svg",
    subtitle: "Watch with <b>{caregiverName}</b>...",
    description: "Videos to help strengthen caregiver learning",
    caregiver: true,
    items: [
      {
        id: 1,
        exercise: 21,
        category: "Parenting videos",
        title: "Communication",
        type: 'video',
        text: "Videos to help caregivers communicate better with children",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              nextBtnLabel: "Remind",
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "ZG3WIwi5y6k",
                  selected: true
                },
                {
                  id: 2,
                  label: "Video 2",
                  videoId: "mEqaNDwaKfk"
                },
                {
                  id: 3,
                  label: "Video 3",
                  videoId: "n3oKwCk5k3w"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>\n                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Nzo6Y29tbXVuaWNhdGlvbg=="
      },
      {
        id: 2,
        exercise: 22,
        category: "Parenting videos",
        title: "Structure and Rules",
        type: 'video',
        text: "Videos to help caregivers create structure and rules effectively",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              nextBtnLabel: "Remind",
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "98c9MWER080",
                  selected: true
                },
                {
                  id: 2,
                  label: "Video 2",
                  videoId: "JPEm7khwoKk"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>\n                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Nzo6c3RydWN0dXJlIGFuZCBydWxlcw=="
      },
      {
        id: 3,
        exercise: 23,
        category: "Parenting videos",
        title: "Giving directions",
        type: 'video',
        text: "Videos to help caregivers give directions effectively",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              nextBtnLabel: "Remind",
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "jyo2m6cEitg",
                  selected: true
                },
                {
                  id: 2,
                  label: "Video 2",
                  videoId: "zUSosdCjefo"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>\n                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Nzo6Z2l2aW5nIGRpcmVjdGlvbnM="
      },
      {
        id: 4,
        exercise: 24,
        category: "Parenting videos",
        title: "Discipline and Consequences",
        type: 'video',
        text: "Videos to help caregivers use discipline and consequences effectively",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              nextBtnLabel: "Remind",
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "7BW_JamMZho",
                  selected: true
                },
                {
                  id: 2,
                  label: "Video 2",
                  videoId: "bsCMWwyaWTk"
                },
                {
                  id: 3,
                  label: "Video 3",
                  videoId: "2EOo_BuRAL4"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>\n                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Nzo6ZGlzY2lwbGluZSBhbmQgY29uc2VxdWVuY2Vz"
      },
      {
        id: 5,
        exercise: 25,
        category: "Parenting videos",
        title: "Time-Out",
        type: 'video',
        text: "Video to help caregivers use time-out effectively",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              nextBtnLabel: "Remind",
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "teJ1cO4769U",
                  selected: true
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>\n                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Nzo6dGltZS1vdXQ="
      }
    ]
  },
  {
    id: 8,
    title: "Social skills",
    image: "social-skills.svg",
    subtitle: "Practice with <b>{name}</b>...",
    description: "Exercises to help clients practice social skills",
    items: [
      {
        id: 1,
        exercise: 27,
        category: "Social skills",
        title: "Appropriate conversation topics",
        type: 'game',
        text: "Identify whether the topic is appropriate to talk about with someone new",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              nextBtnLabel: "Remind",
              activity: "actwsn",
              preview: "actwsn"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to review appropriate conversation topics. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to review appropriate conversation topics.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6YXBwcm9wcmlhdGUgY29udmVyc2F0aW9uIHRvcGljcw=="
      },
      {
        id: 2,
        exercise: 60,
        category: "Social skills",
        title: "Starting conversations with someone new",
        type: 'journal',
        text: "Practice starting a conversation with a new person",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              preview: "scwsn",
            }
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can start a conversation with someone new this week?",
              placeholder: "e.g., complimenting someone's shirt"
            }
          },
          {
            name: "dokbot2",
            params: {
              nextBtnLabel: "Remind",
              activity: "scwsn-hw",
              preview: "scwsn-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice starting a conversation with someone new. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to practice starting a conversation with someone new.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6c3RhcnRpbmcgY29udmVyc2F0aW9ucyB3aXRoIHNvbWVvbmUgbmV3"
      },
      {
        id: 3,
        exercise: 29,
        category: "Social skills",
        title: "Maintaining conversations with someone new",
        type: 'journal',
        text: "Practice keeping a conversation going with a new person",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              preview: "mcwsn",
            }
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can maintain a conversation with someone new this week?",
              placeholder: "e.g., asking someone with the same phone what they think of it"
            }
          },
          {
            name: "dokbot2",
            params: {
              nextBtnLabel: "Remind",
              activity: "mcwsn-hw",
              preview: "mcwsn-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice maintaining a conversation with someone new. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to practice maintaining a conversation with someone new.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6bWFpbnRhaW5pbmcgY29udmVyc2F0aW9ucyB3aXRoIHNvbWVvbmUgbmV3"
      },
      {
        id: 4,
        exercise: 30,
        category: "Social skills",
        title: "Starting conversations with someone familiar",
        type: 'journal',
        text: "Practice starting a conversation with a familiar person",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              preview: "scwsf"
            }
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can start a conversation with someone familiar this week?",
              placeholder: "e.g., asking a friend how their day was"
            }
          },
          {
            name: "dokbot2",
            params: {
              nextBtnLabel: "Remind",
              activity: "scwsf-hw",
              preview: "scwsf-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice starting a conversation with someone familiar. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to practice starting a conversation with someone familiar.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6c3RhcnRpbmcgY29udmVyc2F0aW9ucyB3aXRoIHNvbWVvbmUgZmFtaWxpYXI="
      },
      {
        id: 5,
        exercise: 31,
        category: "Social skills",
        title: "Maintaining conversations with someone familiar",
        type: 'journal',
        text: "Practice keeping a conversation going with a familiar person",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              preview: "mcwsf"
            }
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can maintain a conversation with someone familiar this week?",
              placeholder: "e.g., talking with a friend about each other's work day"
            }
          },
          {
            name: "dokbot2",
            params: {
              nextBtnLabel: "Remind",
              activity: "mcwsf-hw",
              preview: "mcwsf-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice maintaining a conversation with someone familiar. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to practice maintaining a conversation with someone familiar.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6bWFpbnRhaW5pbmcgY29udmVyc2F0aW9ucyB3aXRoIHNvbWVvbmUgZmFtaWxpYXI="
      },
      {
        id: 6,
        exercise: 32,
        category: "Social skills",
        title: "Inviting others to join an activity",
        type: 'journal',
        text: "Practice inviting someone to do an activity",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              preview: "eamf-iotj"
            }
          },
          {
            name: "remind",
            params: {
              title: "What's an activity where <b>{name}</b> can invite others to join this week?",
              placeholder: "e.g., roller skating"
            }
          },
          {
            name: "dokbot2",
            params: {
              nextBtnLabel: "Remind",
              activity: "eamf-iotj-hw",
              preview: "eamf-iotj-r",
              inputs: {
                activity: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice inviting others to join an activity. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to practice inviting others to join an activity.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6aW52aXRpbmcgb3RoZXJzIHRvIGpvaW4gYW4gYWN0aXZpdHk="
      },
      {
        id: 7,
        exercise: 33,
        category: "Social skills",
        title: "Asking others to change their behavior",
        type: 'journal',
        text: "Practice asking someone to change their behavior",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              preview: "aotctb"
            }
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can ask others to change their behavior this week?",
              placeholder: "e.g., asking someone to stop making inappropriate comments"
            }
          },
          {
            name: "dokbot2",
            params: {
              nextBtnLabel: "Remind",
              activity: "aotchtb-hw",
              preview: "aotctb-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice asking others to change their behavior. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to practice asking others to change their behavior.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6YXNraW5nIG90aGVycyB0byBjaGFuZ2UgdGhlaXIgYmVoYXZpb3I="
      },
      {
        id: 8,
        exercise: 34,
        category: "Social skills",
        title: "Being positively assertive",
        type: 'journal',
        text: "Practice accepting or giving praise and compliments",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              preview: "bepa"
            }
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can be positively assertive this week?",
              placeholder: "e.g., praise someone's cooking"
            }
          },
          {
            name: "dokbot2",
            params: {
              nextBtnLabel: "Remind",
              activity: "bepa-hw",
              preview: "bepa-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice being positively assertive. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to practice being positively assertive.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6YmVpbmcgcG9zaXRpdmVseSBhc3NlcnRpdmU="
      },
      {
        id: 9,
        exercise: 35,
        category: "Social skills",
        title: "Interacting with authority figures",
        type: 'journal',
        text: "Practice interacting with an authority figure",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              preview: "iwaf"
            }
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can interact with authority figures this week?",
              placeholder: "e.g., setting boundaries with your parents"
            }
          },
          {
            name: "dokbot2",
            params: {
              nextBtnLabel: "Remind",
              activity: "iwaf-hw",
              preview: "iwaf-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice interacting with authority figures. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to practice interacting with authority figures.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6aW50ZXJhY3Rpbmcgd2l0aCBhdXRob3JpdHkgZmlndXJlcw=="
      },
      {
        id: 10,
        exercise: 36,
        category: "Social skills",
        title: "Declining unreasonable requests",
        type: 'journal',
        text: "Practice declining an unreasonable request",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              preview: "dureqs"
            }
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can decline an unreasonable requests this week?",
              placeholder: "e.g., saying you can't do all the chores in the house"
            }
          },
          {
            name: "dokbot2",
            params: {
              nextBtnLabel: "Remind",
              activity: "dureqs-hw",
              preview: "dureqs-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice declining unreasonable requests. Click here to start: {link}",
                email: "Hi {name}! <br><br> \n                          It's time for you to practice declining unreasonable requests.<br>\n                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "ODo6ZGVjbGluaW5nIHVucmVhc29uYWJsZSByZXF1ZXN0cw=="
      }
    ]
  }
]