import Vue from 'vue'
import Vuex from 'vuex'
import analytics from './analytics'
import authentication from './authentication'
import app from './app'
import schedules from './schedules'
import clients from './clients'
import review from './review'
import subscription from './subscription'
import ux from './ux'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    analytics,
    authentication,
    app,
    schedules,
    clients,
    review,
    subscription,
    ux
  }
})
