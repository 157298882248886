<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1047 6.50171H22.3131C22.3858 6.50181 22.4573 6.48462 22.5221 6.45157C22.5868 6.41853 22.6427 6.37057 22.6852 6.31165C22.7278 6.25274 22.7557 6.18456 22.7667 6.11273C22.7777 6.04091 22.7715 5.96749 22.7485 5.89855L21.1443 1.31522C21.1141 1.22352 21.0558 1.14368 20.9777 1.08708C20.8995 1.03047 20.8054 1 20.7089 1C20.6124 1 20.5183 1.03047 20.4401 1.08708C20.3619 1.14368 20.3036 1.22352 20.2735 1.31522L18.6693 5.89855C18.6464 5.96749 18.6402 6.04091 18.6512 6.11273C18.6621 6.18456 18.69 6.25274 18.7326 6.31165C18.7751 6.37057 18.831 6.41853 18.8957 6.45157C18.9604 6.48462 19.0321 6.50181 19.1047 6.50171Z"
      fill="url(#paint0_linear_8_97)"
    />
    <path
      d="M16.125 2.83334H4.66668C4.54512 2.83334 4.42854 2.88163 4.34259 2.96759C4.25663 3.05354 4.20834 3.17012 4.20834 3.29168V20.7083C4.20834 20.8299 4.25663 20.9465 4.34259 21.0325C4.42854 21.1184 4.54512 21.1667 4.66668 21.1667H16.125C16.2466 21.1667 16.3632 21.1184 16.4491 21.0325C16.535 20.9465 16.5833 20.8299 16.5833 20.7083V3.29168C16.5833 3.17012 16.535 3.05354 16.4491 2.96759C16.3632 2.88163 16.2466 2.83334 16.125 2.83334ZM7.87501 10.625V7.87501C7.87501 7.75345 7.9233 7.63687 8.00926 7.55092C8.0952 7.46497 8.21178 7.41668 8.33334 7.41668H12.9167C13.0382 7.41668 13.1548 7.46497 13.2408 7.55092C13.3267 7.63687 13.375 7.75345 13.375 7.87501V10.625C13.375 10.7466 13.3267 10.8632 13.2408 10.9491C13.1548 11.035 13.0382 11.0833 12.9167 11.0833H8.33334C8.21178 11.0833 8.0952 11.035 8.00926 10.9491C7.9233 10.8632 7.87501 10.7466 7.87501 10.625Z"
      fill="url(#paint1_linear_8_97)"
    />
    <path
      d="M2.83333 2.83334H2.375C2.01033 2.83334 1.66059 2.97821 1.40273 3.23607C1.14487 3.49393 1 3.84368 1 4.20834V19.7917C1 20.1563 1.14487 20.5061 1.40273 20.764C1.66059 21.0218 2.01033 21.1667 2.375 21.1667H2.83333C2.95489 21.1667 3.07147 21.1184 3.15742 21.0325C3.24338 20.9465 3.29167 20.8299 3.29167 20.7083V3.29168C3.29167 3.17012 3.24338 3.05354 3.15742 2.96759C3.07147 2.88163 2.95489 2.83334 2.83333 2.83334Z"
      fill="url(#paint2_linear_8_97)"
    />
    <path
      d="M22.5417 7.41666H18.875C18.6219 7.41666 18.4167 7.62186 18.4167 7.87499V18.4167C18.4167 18.6697 18.6219 18.875 18.875 18.875H22.5417C22.7947 18.875 23 18.6697 23 18.4167V7.87499C23 7.62186 22.7947 7.41666 22.5417 7.41666Z"
      fill="url(#paint3_linear_8_97)"
    />
    <path
      d="M22.5417 19.7917H18.875C18.7534 19.7917 18.6368 19.84 18.5509 19.9259C18.465 20.0118 18.4167 20.1284 18.4167 20.25V20.7083C18.4167 21.3161 18.6581 21.899 19.0878 22.3288C19.5177 22.7585 20.1006 23 20.7083 23C21.3161 23 21.899 22.7585 22.3288 22.3288C22.7585 21.899 23 21.3161 23 20.7083V20.25C23 20.1284 22.9517 20.0118 22.8658 19.9259C22.7798 19.84 22.6632 19.7917 22.5417 19.7917Z"
      fill="url(#paint4_linear_8_97)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8_97"
        x1="18.7782"
        y1="1.06581"
        x2="23.7774"
        y2="2.46389"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8_97"
        x1="4.60521"
        y1="3.05264"
        x2="19.8085"
        y2="6.87928"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8_97"
        x1="1.07349"
        y1="3.05264"
        x2="4.06078"
        y2="3.19188"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8_97"
        x1="18.5637"
        y1="7.55372"
        x2="24.4209"
        y2="8.42735"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_8_97"
        x1="18.5637"
        y1="19.8301"
        x2="23.2277"
        y2="22.3146"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-neutralMedium-base)'
    }
  }
}
</script>
