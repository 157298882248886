import assess from '@/kit/dictionaries/reminders/assess'
import practice from '@/kit/dictionaries/reminders/practice'
import remind from '@/kit/dictionaries/reminders/remind'
// import IconExercises from '@/components/icons/IconExercises'
import IconPractice from '@/components/icons/IconPractice'
import IconRemind from '@/components/icons/IconRemind'
import IconReview from '@/components/icons/IconReview'
// import IconAssessments from '@/components/icons/IconAssessments'
import IconAssess from '@/components/icons/IconAssess'

export default [
  {
    title: 'What do you want to assess with {name}?',
    icon: IconAssess,
    text: 'Assess',
    route: 'remindersShow',
    slug: 'assess',
    reminders: assess,
  },
  {
    title: 'What do you want to practice with {name}?',
    icon: IconPractice,
    text: 'Practice',
    route: 'remindersShow',
    slug: 'practice',
    reminders: practice
  },
  {
    title: 'What do you want to remind {name} to practice this week?',
    icon: IconRemind,
    text: 'Remind',
    route: 'remindersShow',
    slug: 'remind',
    reminders: remind
  },
  {
    text: 'Review',
    icon: IconReview,
    slug: 'review',
    route: 'review',
    routes: ['review', 'reviewAssess', 'reviewSleepLog'],
  },
]
