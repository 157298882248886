import tidGenerate from '@/misc/tid'
import firebase from '@/firebase'
import { snapshotToArray } from '@/kit/helpers/firebaseHelper'
import { getErrorMessage } from '@/misc/errorMessages'
import moment from '@/plugins/moment'


export default {
  /**
   * @param dispatch
   * @param state
   * @param payload
   * @returns {Promise<user object|null>}
   */
  async signIn({ dispatch, state }, payload) {
    await dispatch('notice')
    await dispatch('app/loading', true, { root: true })
    
    let isFirstTime = null;
    try {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      
      return await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(async userCredential => {
          if (!userCredential.user.emailVerified) {
            // Switch to email verification
            // console.log('Path: signIn -> verify')
            await dispatch('notice', { type: 'verifyError' })
            await dispatch('app/loading', false, { root: true })
            firebase.analytics.logEvent('auth:verify-error')

            return null
          } else {
            // User's email is verified, continue normal path
            // console.log('Path: signIn -> load')
            await dispatch('fetchUser', userCredential.user.uid)
            await dispatch('collectTemporaryData')
            await dispatch('app/loading', false, { root: true })
            dispatch('afterSignIn')
            isFirstTime = userCredential.additionalUserInfo?.isNewUser ?? false
            await dispatch('analytics/segmentTrack', {
              eventName: 'Sign In',
              isFirstTime,
              type: 'Email'
            }, { root: true })

            return state.user
          }
        })
    } catch (e) {
      await dispatch('app/loading', false, { root: true })

      let message = 'The email or password is incorrect'

      if (e.code === 'auth/invalid-email') {
        message = e.message
      }

      await dispatch('notice', {
        type: 'error',
        message
      })

      // Call the logEventFrontend function in firebase
      try {
        await firebase.functions.httpsCallable('logEventFrontend')({
          message: "Invalid email or password on login",
          status: 403, // Not really the return code, but would be for auth failure
          level: "info",
          stack: e?.stack,
          data: {
            isFirstTime,
            signInSource: "email",
          }
        });
      } catch (e) {
        console.error("Error sending to logtail via firebase callable:");
        console.error(e);
      }

      return null
    }
  },
  
  /**
   * @param dispatch
   * @param state
   * @param payload
   * @returns {Promise<boolean>}
   */
  async doxymeSignIn({dispatch, state}, payload) {
    await dispatch('notice')
    await dispatch('app/loading', true, { root: true })
    // const ssoGetUID = firebase.functions.httpsCallable('ssoGetUID')
    const ssoGetCustomToken = firebase.functions.httpsCallable('ssoGetCustomToken')
    const ssoVerifyEmail = firebase.functions.httpsCallable('ssoVerifyEmail')
    // check for existence of authToken because of different sources for this object
    const token = payload?.authToken ? payload.authToken.data : payload.data
    const userEmail = !token.identity 
      ? token.email
      : token.identity.email
    const userName =  !token.identity 
      ? token.name
      : token.identity.name

    /**
     * first thing we need to do is attempt to get the UID associated with
     * an email to check if an account exists. If none, exists, we will create the account.
     */
    // const userCredential = await ssoGetUID({userEmail: userEmail})
    const userCredential = token
    // does account exist?
    if (userCredential && !userCredential?.identity) {
      // generate firebase token from custom JWT credentials
      return await ssoGetCustomToken({uid: userCredential.uid})
      .then(async result => {
        await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)

        const token = result.data
        console.log('isFirstTime', userCredential.additionalUserInfo)
        const isFirstTime = userCredential.additionalUserInfo?.isNewUser ?? false
        await dispatch('analytics/segmentTrack', {
          eventName: 'Sign In',
          isFirstTime,
          type: 'doxy.me'
        }, { root: true })

        // Call the logEventFrontend function in firebase
        if (isFirstTime) {
          try {
            await firebase.functions.httpsCallable('logEventFrontend')({
              message: "User authenticated via Doxy.me SSO for the first time",
              level: "info",
              data: {
                isFirstTime,
                email: userEmail,
                signInSource: "doxy.me",
              }
            });
          } catch (e) {
            console.error("Error sending to logtail via firebase callable:");
            console.error(e);
          }
        }

        // now sign into firebase with firebase generated token
        return firebase.auth().signInWithCustomToken(token)
          .then(async existingUser => {
            await dispatch('fetchUser', existingUser.user.uid)
            await dispatch('collectTemporaryData')
            await dispatch('app/loading', false, { root: true })
            await dispatch('afterSignIn')
            await dispatch('analytics/segmentTrack', {
              eventName: 'Sign In',
              type: 'doxy.me'
            }, { root: true })
            return state.user
          })
      })
      
    } else {
      // attempt to create user based on JWT credentials from doxyme SSO call
      try {
        await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        const randomPassword = () => {
          return Math.floor(Math.random() * Date.now()).toString(36);
        }
        const splitName = userName.split(' ')
        const firstName = Array.isArray(splitName) ? splitName[0] : name
        const lastName = Array.isArray(splitName) ? splitName[1] : ''
        const createdUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(userEmail, randomPassword())
        
        await firebase.db
        .collection('users')
        .doc(createdUser.user.uid)
        .set({
          // Role is set here, but user cannot elevate - ruleset prevents access to data
          role: 'provider',
          email: userEmail,
          first_name: firstName,
          last_name: lastName,
          createdAt: firebase.now()
        })
        await ssoVerifyEmail({uid: createdUser.user.uid})
        .then(async () => {
          await dispatch('fetchUser', createdUser.user.uid)
          await dispatch('collectTemporaryData')
          await dispatch('app/loading', false, { root: true })
          await dispatch('afterSignIn')
        })
        await dispatch('analytics/segmentTrack', {
          eventName: 'Sign Up',
          type: 'doxy.me'
        }, { root: true })
        return createdUser
        
      } catch(e) {
        try {
          await firebase.functions.httpsCallable('logEventFrontend')({
            message: "Error creating user via Doxy.me SSO",
            level: "error",
            stack: e?.stack,
            data: {
              signInSource: "doxy.me",
              email: userEmail
            }
          });
        } catch (e) {
          console.error("Error sending to logtail via firebase callable:");
          console.error(e);
        }
      
        console.log('error creating user', e)
      }
    }
  },

  /**
   * @param dispatch
   * @returns {Promise<user object|null>}
   */
  async googleSignIn({ dispatch, state }) {
    await dispatch('notice')
    await dispatch('app/loading', true, { root: true })

    let isFirstTime = null;
    let email = null;
    let uid = null;
    let isNewUser = null;
    try {
      const provider = new firebase.auth.GoogleAuthProvider()

      provider.setCustomParameters({ prompt: 'select_account' })
      provider.addScope('profile')
      provider.addScope('email')

      // set persistence to SESSION to force for sessions to close
      // after closing tabs
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)

      return await firebase
        .auth()
        // .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .signInWithPopup(provider)
        .then(async userCredential => {
          // Loggable vars if we get this far
          email = userCredential.user.email;
          uid = userCredential.user.uid;
          isNewUser = userCredential.additionalUserInfo?.isNewUser ?? false;

          if (userCredential.additionalUserInfo?.isNewUser) {
            // console.log('New Google-authenticating user')

            firebase.analytics.logEvent('auth:sign-up')
            const isFirstTime = userCredential.additionalUserInfo?.isNewUser ?? false
            await dispatch('analytics/segmentTrack', {
              eventName: 'Sign Up',
              isFirstTime: isFirstTime,
              type: 'Google'
            }, { root: true })

            await firebase.db
              .collection('users')
              .doc(userCredential.user.uid)
              .set({
                // Role is set here, but user cannot elevate - ruleset prevents access to data
                role: 'provider',
                email: userCredential.user.email,
                // photoUrl: userCredential.user.photoURL,
                first_name:
                  userCredential.additionalUserInfo.profile.given_name,
                last_name:
                  userCredential.additionalUserInfo.profile.family_name,
                createdAt: firebase.now()
              })
            
          }
            
          dispatch('userLoading', false)
          await dispatch('fetchUser', userCredential.user.uid)
          await dispatch('collectTemporaryData')
          await dispatch('app/loading', false, { root: true })
          dispatch('afterSignIn')
          isFirstTime = userCredential.additionalUserInfo?.isNewUser ?? false
          await dispatch('analytics/segmentTrack', {
            eventName: 'Sign In',
            isFirstTime,
            type: 'Google'
          }, { root: true })
          
          return state.user
        })
    } catch (e) {
      // console.error(e, e.message, e.stack)

      try {
        await firebase.functions.httpsCallable('logEventFrontend')({
          message: "Error signing in with Google",
          level: "error",
          stack: e?.stack,
          data: {
            signInSource: "google",
            isFirstTime,
            email,
            uid,
            isNewUser
          }
        });
      } catch (e) {
        console.error("Error sending to logtail via firebase callable:");
        console.error(e);
      }
      
      await dispatch('app/loading', false, { root: true })
      await dispatch('notice', {
        type: 'error',
        message: 'Unable to sign in with Google'
      })
      return null
    }
  },

  async intercomBootUp({state, commit}) {
    if (state.user) {
      const intercom = firebase.functions.httpsCallable('intercom')
      const uid =  'al-' + state.user.id
      const intercomHash = await intercom({userId: uid})
      window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'i11e88km',
        user_id: uid,
        user_hash: intercomHash.data,
        name: `${state.user.first_name} ${state.user.last_name}`,
        email: state.user.email,
        created_at: state.user.createdAt.seconds,
        hide_default_launcher: false
      }
      await window.Intercom('reattach_activator')
      await window.Intercom('update', window.intercomSettings)
      // window.Intercom('show')
      commit('setIntercomLaunched', true)
    }
  },

  /**
   * @param dispatch
   * @param payload
   * @returns {Promise<boolean>}
   */
  async signUp({ dispatch }, payload) {
    await dispatch('logout')
    await dispatch('notice')
    await dispatch('app/loading', true, { root: true })

    let isFirstTime = null;
    let email = null;
    let uid = null;
    let isNewUser = null;
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(async userCredential => {
          // Loggable vars if we get this far
          isFirstTime = userCredential.additionalUserInfo?.isNewUser ?? false
          email = userCredential.user.email;
          uid = userCredential.user.uid;
          isNewUser = userCredential.additionalUserInfo?.isNewUser ?? false;

          await firebase.db
            .collection('users')
            .doc(userCredential.user.uid)
            .set({
              // Role is set here, but user cannot elevate - ruleset prevents access to data
              role: 'provider',
              email: payload.email,
              first_name: payload.firstName,
              last_name: payload.lastName,
              createdAt: firebase.now()
            })

          await dispatch('analytics/segmentTrack', {
            eventName: 'Sign Up',
            type: 'Manual'
          }, { root: true })

          firebase.analytics.logEvent('auth:sign-up')
          await dispatch('sendEmailVerification')
          // await dispatch('logout')
          await dispatch('app/loading', false, { root: true })
        })

      return true
    } catch (e) {
      let message = 'The email address is already in use by another account'

      if (e.code === 'auth/invalid-email' || e.code === 'auth/weak-password') {
        message = e.message
      }

      try {
        await firebase.functions.httpsCallable('logEventFrontend')({
          message: "General email auth failure",
          level: "error",
          stack: e?.stack,
          data: {
            signInSource: "email",
            isFirstTime,
            email,
            uid,
            isNewUser
          }
        });
      } catch (e) {
        console.error("Error sending to logtail via firebase callable:");
        console.error(e);
      }

      await dispatch('notice', {
        type: 'error',
        message
      })
      await dispatch('logout')
      await dispatch('app/loading', false, { root: true })

      return false
    }
  },

  /**
   * @param dispatch
   * @returns {Promise<void>}
   */
  async resendEmail({ dispatch }) {
    dispatch('notice')
    dispatch('app/loading', true, { root: true })

    await dispatch('sendEmailVerification')

    dispatch('app/loading', false, { root: true })
  },

  /**
   * @param dispatch
   * @returns {Promise<void>}
   */
  async sendEmailVerification({ dispatch, state }) {
    try {
      await firebase.auth().currentUser.sendEmailVerification({
        url: `${process.env.VUE_APP_BASE_URL}/sign-in`,
        ...firebase.actionCodeSettings
      })
      await dispatch('notice', {
        type: 'info',
        message: 'Email with verification link sent!'
      })
      firebase.analytics.logEvent('auth:verify:sent-email')
    } catch (e) {
      await dispatch('notice', {
        type: 'error',
        message: getErrorMessage(e)
      })
    }
  },

  /**
   * @param dispatch
   * @param payload
   * @return {promise<void>}
   */
  async resetPassword({ dispatch }, payload) {
    await dispatch('app/loading', true, { root: true })
    await dispatch('notice')

    try {
      await firebase.auth().sendPasswordResetEmail(payload.email, {
        url: `${process.env.VUE_APP_BASE_URL}/sign-in`,
        ...firebase.actionCodeSettings
      })
      await dispatch('notice', {
        type: 'info',
        message: 'Password reset email was sent. Please check your inbox.'
      })
    } catch (e) {
      await dispatch('notice', {
        type: 'error',
        message: getErrorMessage(e),
        action: 'focus'
      })
    }
    await dispatch('app/loading', false, { root: true })
  },

  /**
   * Load a user async from firestore when given a uid.
   * This function has side effects: vuex will be populated with the loaded user data.
   * @param {destructured} Vuex params 
   * @param {string} uid 
   * @returns Promise<void>
   */
  async fetchUser({ commit, getters, dispatch }, uid) {
    if (getters.isUserLoading) {
      // ! This can create a race if fetchUser is called externally and not await'ed for during login/register
      // Ex: caller would expect to "await fetchUser(...)" but this condition would return immediately
      // console.log('User already loading, skipping fetch')
      return
    }

    dispatch('userLoading', true)

    // Flags get loaded here with the user too

    const userRef = await firebase.db
      .collection('users')
      .doc(uid)
      .get()

    if (userRef.exists) {
      const userData = userRef.data()
      userData.id = userRef.id

      const flags = await firebase.db
      .collection('flags')
      .get()

      /*
        Merging all flags together into one object:
        1. Add all user flags first
        2. Add global flags to the object UNLESS they are already there
        3. Set the vuex flags to the new object for user to consume locally

        This means:
        - User flags will always take precedent over global flags
        - A global flag can be overridden by a user flag every time
      */

      // This object will hold the final flag config for this user after parsing global and user flags
      const flagObj = {}

      // Get flag names that are set on the user's doc
      const existingUserFlagNames = userData.flags ? Object.keys(userData.flags) : []

      // Apply any and all user flags
      existingUserFlagNames.forEach(flagName => {
        // Set the value from the user's flags first
        // Next step will skip these values if they exist already
        flagObj[flagName] = userData.flags[flagName]
      })

      // Apply all global flags that are not already set
      flags.forEach(flag => {
        const flagName = flag.id

        if (flagObj[flagName] !== undefined) return;  // This value has been set by the user flag already, don't overwrite with global flag

        // Apply global flag
        flagObj[flagName] = flag.data()
      })
      
      // Insert flags seamlessly into vuex
      userData.flags = flagObj

      // Blacklist the "test flags" that are not needed for individual users
      // TODO not sure if used: "'isBooleanTestFlagSet' is assigned a value but never used." [also objectTestFlag]
      const {isBooleanTestFlagSet, objectTestFlag, ...rest} = flagObj

      // If the user is fetched and doesn't have any flags in firestore
      if (!userRef.data().flags) {
        await firebase.db
        .collection('users')
        .doc(uid)
        .update({flags: rest})
      }
      const subscription = userData?.subscription
        ? userData.subscription
        : null
      commit('subscription/setSubscription', subscription, {root: true})

      await commit('setUser', userData)
      dispatch('userLoading', false)
    } else {
      console.error('User does not exist, cannot load (race?)')
    }
  },

  /**
   * When user become not anonymous this function will collect and persist temporary data
   * @param getters
   * @param dispatch
   * @returns {Promise<void>}
   */
  async collectTemporaryData({ getters, dispatch }) {

    if (!getters.user) throw new Error('Cannot collect temp data: user not loaded')

    await firebase.db
      .collection('users')
      .doc(getters.user.id)
      .update({
        tid: getters.tid
      })

    const querySchedules = await firebase.db
      .collection('schedules')
      .where('tid', '==', getters.tid)
      .where('createdBy', '==', null)

    const scheduleSnaps = await querySchedules.get()

    if (!scheduleSnaps.empty) {
      const schedules = snapshotToArray(scheduleSnaps)

      for (const schedule of schedules) {
        const client = await dispatch('clients/saveClient', schedule, {
          root: true
        })

        await schedule.ref.update({
          clientId: client.id,
          createdBy: getters.user.id
        })
      }
    }
  },

  /**
   * Fetch persisted temporary user id (tid)
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  async fetchTid({ commit }) {
    let tid = localStorage.getItem('tid')

    if (!tid) {
      tid = tidGenerate()
      localStorage.setItem('tid', tid)
    }

    commit('setTid', tid)
  },

  async changeFlag({ commit }, payload) {
    commit('changeFlag', payload)
    await firebase.db
      .collection('flags')
      .doc(payload.name)
      .update(payload.set)
  },

  async changeUserFlag({ commit }, payload) {
    commit('changeUserFlag', payload)
    const userRef = await firebase.db
      .collection('users')
      .doc(payload.uid)
      .get()
    // If the user doesn't have flags initialized
    if (!userRef.data().flags) {
      await firebase.db
      .collection('users')
      .doc(payload.uid)
      .update({flags: {[payload.name]: payload.set}})
    }
    let temp = userRef.data()
    temp['flags'][payload.name] = payload.set
    await firebase.db
      .collection('users')
      .doc(payload.uid)
      .update({flags: temp['flags']})
  },

  userLoading({ commit }, payload) {
    commit('userLoading', payload)
  },

  async afterSignIn({ getters, dispatch, commit }) {
    // we need to do this for a while because of adding soft deleting clients
    // most docs don't have the isDeleted field and so it must be added
    // because we can't query firebase for fields that don't exist
    // this will add the field and then get an accurate client count
    dispatch('clients/countClients', {}, {root: true})
    
    firebase.analytics.logEvent('auth:sign-in')

    await firebase.db
      .collection('users')
      .doc(getters.user.id)
      .update({
        signInAt: moment()
          .utc()
          .toDate()
      })
    const userData = await firebase.db
      .collection('users')
      .doc(getters.user.id)
      .get()
    
    const subscription = userData.data()?.subscription
      ? userData.data().subscription
      : null
    commit('subscription/setSubscription', subscription, {root: true})
    await dispatch('analytics/segmentIdentify', {}, { root: true })
  },

  /**
   * @param commit
   * @param dispatch
   * @returns {Promise<void>}
   */
  logout: async ({ getters, dispatch, commit }) => {
    try {
      await firebase.functions.httpsCallable('logEventFrontend')({
        message: "Sign-out [test]",
        level: "info",
        data: {
          email: getters.user?.email,
        }
      });
    } catch (e) {
      console.error("Error sending to logtail via firebase callable:");
      console.error(e);
    }

    await firebase.auth().signOut()
    window.Intercom('shutdown')
    commit('setUser', null)
  },

  notice({ commit }, payload) {
    commit('setNotice', payload)
  },

  getSubscriptions: async() => {
    const subscriptionRef = await firebase.db.collection('users')
    const query  = subscriptionRef
      .where('subscription', '!=', null)
    const snapshot = await query.get()
    return await snapshot.docs.map(doc => doc.data())
  },

  createUser: async ({dispatch}, payload) => {
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(async userCredential => {
          payload.uid = userCredential.user.uid
        })
      return payload
    } catch (e) {
      let message = ''

      if (e.code === 'auth/invalid-email' || e.code === 'auth/weak-password') {
        message = e.message
      } else {
        message = `${payload.email} is already in use by another account`
      }
      alert(message)

      return false
    }
  },
}
