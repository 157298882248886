import moment from '@/plugins/moment'
import { notAssigned } from '@/store/review/static'

export default () => {
  return {
    active: 0,
    assess: false,
    assessChartData: {
      datasets: [],
      tooltips: [],
    },
    assessment: null,
    assessments: [],
    assessmentTableData: null,
    assesses: [],
    chartData: {
      datasets: [],
      tooltips: [],
      times: []
    },
    chartDataEmotions: null,
    chartDataThoughts: null,
    chartDataBehaviors: null,
    chartDataDistressingSituations: null,
    completed: false,
    // Will be faked to a specific date defined in app vuex state during demos
    current: moment().utc(),
    days: [],
    emotion: notAssigned,
    emotionList: [
      {
        name: notAssigned
      }
    ],
    emotions: [],
    from: null,
    haveMonitoring: false,
    hasBehaviorsData: false,
    hasEmotionsData: false,
    hasThoughtsData: false,
    hasDistressingSituationsData: false,
    journalTableData: [],
    loading: true,
    notAssigned,
    period: {
      defaultValue: '1 week',
      value: '1 week',
      items: [
        // 1-week actually spans 8 days, for day-to-day week views
        // The "value" is actually fed into Moment to determine the offset!
        { value: '1 week', text: 'Past Week' },
        { value: '30 day', text: 'Past 30 days' },
        { value: '90 day', text: 'Past 90 days' },
        { value: '180 day', text: 'Past 180 days' },
        { value: '1 year', text: 'Past Year' }
      ]
    },
    reminderType: null,
    scheduled: false,
    schedules: [],
    sections: [],
    selfReminder: {},
    sessions: [],
    sessionData: [],
    table: [],
    thoughtsAndBehaviorsGraphData: [],
    thoughtsAndBehaviorsTableData: [],
    time: null,
    timeList: [],
    to: moment().utc(),
    xAxesFormat: 'MMM D',
    xAxesLabels: []
  }
}
