<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.439 0H1.56098C1.14698 0 0.749939 0.164459 0.457199 0.457199C0.164459 0.749939 0 1.14698 0 1.56098V14.439C0 14.853 0.164459 15.2501 0.457199 15.5428C0.749939 15.8355 1.14698 16 1.56098 16H14.439C14.853 16 15.2501 15.8355 15.5428 15.5428C15.8355 15.2501 16 14.853 16 14.439V1.56098C16 1.14698 15.8355 0.749939 15.5428 0.457199C15.2501 0.164459 14.853 0 14.439 0V0ZM11.2991 8.80624L6.67707 11.4474C6.55384 11.5175 6.41441 11.554 6.27265 11.5535C6.1309 11.5529 5.99176 11.5152 5.86909 11.4442C5.74643 11.3731 5.6445 11.2712 5.57346 11.1485C5.50243 11.0259 5.46475 10.8867 5.4642 10.745V5.46341C5.46475 5.32166 5.50243 5.18252 5.57346 5.05985C5.6445 4.93718 5.74643 4.83525 5.86909 4.7642C5.99176 4.69316 6.1309 4.65548 6.27265 4.65491C6.41441 4.65435 6.55384 4.69091 6.67707 4.76098L11.2999 7.40215C11.4235 7.47314 11.5261 7.57546 11.5975 7.69879C11.6688 7.82212 11.7064 7.96209 11.7064 8.10458C11.7064 8.24708 11.6688 8.38705 11.5975 8.51038C11.5261 8.63371 11.4235 8.73603 11.2999 8.80702L11.2991 8.80624Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
  export default {
    name: "VideoIcon"
  }
</script>