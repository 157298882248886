

import format from 'string-template'
import { dict as dictReview, severities } from '@/kit/dictionaries/review'
import string from '@/helpers/string'

export default {
  default: {
    getDict: (section, activityName, listKey, reminderTitle) => {

      /**
       * At one point, mind/body/spirituality exercises were under
       * "engaging in spirituality". All past exercises with the latter
       * activity name will be reassigned to the former correct name
       * while having their review data preserved
       */
      const activityStringsCorrection = {
        'engaging in spirituality': 'mind/body/spirituality'
      }

      if (Object.prototype.hasOwnProperty.call(activityStringsCorrection, activityName)) {
        activityName = activityStringsCorrection[activityName]
      }

      const activity = dictReview[section]?.[reminderTitle]?.[activityName]
      if (undefined === activity) {
        return undefined
      }

      return {
        ...(dictReview[section]?.[reminderTitle]?.default || {}),
        ...(activity?.default || {}),
        ...(activity?.[listKey] || {})
      }
    },
    getSection: subjectKey =>
      subjectKey === 'Self-monitoring' ? 'Self-monitoring' : 'Exercises',
    getTooltip: (template, data) => {
      return data
        .map(d =>
          format(template, {
            ...Object.entries(d).reduce((a, ds) => {
              a[ds[0]] = `<span class="font-weight-medium">${string.lower(
                ds[1]
              )}</span>`
              a[
                string.capitalizeFirst(ds[0])
              ] = `<span class="font-weight-medium">${string.capitalizeFirst(
                ds[1]
              )}</span>`

              return a
            }, {})
          })
        )
        .join('<hr>')
    }
  },
  'Self-monitoring': {
    'Self-monitoring': {
      emotions: {
        default: {
          severities: severities.map(s => s.toLowerCase()),
          tooltip: ({ severity, cope }, dict) => {
            let action = dict.severities[severity]
            if (dict?.inAction) {
              action += ` ${dict?.inAction}`
            }
            let out = `Felt <span class="font-weight-medium">${action}</span>`

            if (undefined !== cope) {
              out += `<span> and<br /> coped by <span class="font-weight-medium">${cope}</span></span>`
            }

            return out
          }
        }
      },
      'distressing situations': {
        default: {
          extract: (data, dict) => {
            return data.map(d => ({
              ...Object.fromEntries(
                // See kit/dictionaries/review.js for distressing situations formatting
                ['emotions', 'sensations', 'behaviors'].map(f => {
                  return [
                    f,
                    d[f]?.map(e => dict[f]?.[e - 1]).join(', ')
                  ]
                })
              ),
              // Thoughts are numbered keys for words [1,2,3,etc]
              thoughts: d.thoughts
                // Thoughts are not 0-indexed
                .map(thoughtIdx => dict.thoughts[thoughtIdx - 1])
                .join(', '),
              what: d.describe
            }))
          }
        }
      }
    }
  },
  Exercises: {
    'Parent-child activities': {
      'plan a fun activity/do a fun activity': {
        default: {
          tooltip: (data, dict, session) => {
            const what = data.DifferentActivity || session.remind || null
            let feelings = [
              'happy',
              'sad',
              'stressed',
              'nervous',
              'angry',
              'calm'
            ]
            feelings =
              data?.['Feeling'].map(f => feelings[f - 1]).join(', ') || ''

            return `<div>Felt <span class="font-weight-medium">${feelings}</span></div><div>while doing <span class="font-weight-medium">${what}</span> with caregiver</div>`
          },
          label: session => {
            return session.data.DifferentActivity || session.remind || null
          }
        }
      }
    },
    Exposure: {
      'in-vivo exposure': {
        default: {
          tooltip: (data, dict, session) => {
            const collate = severities.map(s => s + ' Nervous')
            const c = {
              baselineAnx: 'Before',
              peakAnx: 'During',
              postAnx: 'After'
            }

            const what = session.remind || ''
            const duration = session.choiceValue || ''
            let out = `<div class="mb-2">Exposure to <span class="font-weight-medium">${what.toLowerCase()}</span> for <span class="font-weight-medium">${duration}</span> minutes:</div>`
            for (const field in c) {
              if (undefined !== data[field]) {
                out += `<div>${c[field]}: 
                        <span class="font-weight-medium">${collate[
                          data[field]
                        ].toLowerCase()}
                        </span>
                    </div>`
              }
            }

            return out
          },
          label: session => session.remind || null
        }
      }
    },
    'Social skills': {
      'starting conversations with someone new': {
        default: {
          tooltip: (data) => {
            const feedback = [
              `It went great! No issues at all!`,
              `It went well with a few issues.`,
              `It didn't go great...`,
            ]
            let newconvosfeedback = feedback[data.newconvosfeedback - 1]
            return `<div>Client replied <span class="font-weight-medium">"${newconvosfeedback}"</span>
            ${newconvosfeedback === `It went great! No issues at all!` ? '' : 
            ' and <span class="font-weight-medium">"' + data.newconvosproblems + '"'}</span></div>`
          }
        }
      },
      'maintaining conversations with someone new': {
        default: {
          tooltip: (data) => {
            const feedback = [
              `It went great! No issues at all!`,
              `It went well with a few issues.`,
              `It didn't go great...`,
            ]
            let newmaintainfeedback = feedback[data.newmaintainfeedback - 1]
            return `<div>Client replied <span class="font-weight-medium">"${newmaintainfeedback}"</span>
            ${newmaintainfeedback === `It went great! No issues at all!` ? '' : 
            ' and <span class="font-weight-medium">"' + data.newmaintainproblems + '"'}</span></div>`
          }
        }
      },
      'starting conversations with someone familiar': {
        default: {
          tooltip: (data) => {
            const feedback = [
              `It went great! No issues at all!`,
              `It went well with a few issues.`,
              `It didn't go great...`,
            ]
            let startconvosfeedback = feedback[data.startconvosfeedback - 1]
            return `<div>Client replied <span class="font-weight-medium">"${startconvosfeedback}"</span>
            ${startconvosfeedback === `It went great! No issues at all!` ? '' : 
            ' and <span class="font-weight-medium">"' + data.startconvosproblems + '"'}</span></div>`
          }
        }
      },
      'maintaining conversations with someone familiar': {
        default: {
          tooltip: (data) => {
            const feedback = [
              `It went great! No issues at all!`,
              `It went well with a few issues.`,
              `It didn't go great...`,
            ]
            let familiarmaintainfeedback = feedback[data.familiarmaintainfeedback - 1]
            return `<div>Client replied <span class="font-weight-medium">"${familiarmaintainfeedback}"</span>
            ${familiarmaintainfeedback === `It went great! No issues at all!` ? '' : 
            ' and <span class="font-weight-medium">"' + data.familiarmaintainproblems + '"'}</span></div>`
          }
        }
      },
      'inviting others to join an activity': {
        default: {
          tooltip: (data) => {
            const feedback = [
              `It went great! No issues at all!`,
              `It went well with a few issues.`,
              `It didn't go great...`,
            ]
            let invitingfeedback = feedback[data.invitingfeedback - 1]
            return `<div>Client replied <span class="font-weight-medium">"${invitingfeedback}"</span>
            ${invitingfeedback === `It went great! No issues at all!` ? '' : 
            ' and <span class="font-weight-medium">"' + data.invitingproblems + '"'}</span></div>`
          }
        }
      },
      'asking others to change their behavior': {
        default: {
          tooltip: (data) => {
            const feedback = [
              `It went great! No issues at all!`,
              `It went well with a few issues.`,
              `It didn't go great...`,
            ]
            let askbehaviorfeedback = feedback[data.askbehaviorfeedback - 1]
            return `<div>Client replied <span class="font-weight-medium">"${askbehaviorfeedback}"</span>
            ${askbehaviorfeedback === `It went great! No issues at all!` ? '' : 
            ' and <span class="font-weight-medium">"' + data.askbehaviorproblems + '"'}</span></div>`
          }
        }
      },
      'being positively assertive': {
        default: {
          tooltip: (data) => {
            const feedback = [
              `It went great! No issues at all!`,
              `It went well with a few issues.`,
              `It didn't go great...`,
            ]
            let positiveassertfeedback = feedback[data.positiveassertfeedback - 1]
            return `<div>Client replied <span class="font-weight-medium">"${positiveassertfeedback}"</span>
            ${positiveassertfeedback === `It went great! No issues at all!` ? '' : 
            ' and <span class="font-weight-medium">"' + data.positiveassertproblems + '"'}</span></div>`
          }
        }
      },
      'interacting with authority figures': {
        default: {
          tooltip: (data) => {
            const feedback = [
              `It went great! No issues at all!`,
              `It went well with a few issues.`,
              `It didn't go great...`,
            ]
            let authorityfeedback = feedback[data.authorityfeedback - 1]
            return `<div>Client replied <span class="font-weight-medium">"${authorityfeedback}"</span>
            ${authorityfeedback === `It went great! No issues at all!`
            ? '' : ' and <span class="font-weight-medium">"' + data.authorityproblems + '"'}</span></div>`
          }
        }
      },
      'declining unreasonable requests': {
        default: {
          tooltip: (data) => {
            const feedback = [
              `It went great! No issues at all!`,
              `It went well with a few issues.`,
              `It didn't go great...`,
            ]
            let requestsfeedback = feedback[data.requestsfeedback - 1]
            return `<div>Client replied <span class="font-weight-medium">"${requestsfeedback}"</span>
            ${requestsfeedback === `It went great! No issues at all!` ? '' : 
            ' and <span class="font-weight-medium">"' + data.requestsproblems + '"'}</span></div>`
          }
        }
      }
    },
    'Cognitive': {
      'socratic questioning': {
        default: {
          tooltip: (data) => {
            return `<div>
            What's an upsetting thought you had recently?
            <span class="font-weight-medium"><br>${data.thoughtrecent}</span><br>
            All or nothing thinking? 
            <span class="font-weight-medium"><br>${data.allornothing === true ? 'Yes' : 'No'}. 
            ${data.howso1 === undefined ? '' : data.howso1}</span><br>
            Exaggerating how important it is? 
            <span class="font-weight-medium"><br>${data.exaggerating === true ? 'Yes' : 'No'}. 
            ${data.howso2 === undefined ? '' : data.howso2}</span><br>
            Jumping to conclusions? 
            <span class="font-weight-medium"><br>${data.jumpingtocon === true ? 'Yes' : 'No'}. 
            ${data.howso3 === undefined ? '' : data.howso3}</span><br>
            Focusing on negatives? 
            <span class="font-weight-medium"><br>${data.focusednegative === true ? 'Yes' : 'No'}. 
            ${data.howso4 === undefined ? '' : data.howso4}</span><br>
            Is there evidence or is this a feeling? 
            <span class="font-weight-medium"><br>${data.anyevidence === 0 ? `It's a feeling` : `There's evidence`}. 
            ${data.howso5 === undefined ? '' : data.howso5}</span><br>
            Assuming the worst? 
            <span class="font-weight-medium"><br>${data.assumingworst === true ? 'Yes' : 'No'}. 
            ${data.howso6 === undefined ? '' : data.howso6}</span><br>
            What evidence supports the thought? 
            <span class="font-weight-medium"><br>${data.whatevidence}</span><br>
            What evidence supports the opposite of this thought? 
            <span class="font-weight-medium"><br>${data.evidenceagainst}</span><br>
            What would I tell someone I cared for if they were having this thought? 
            <span class="font-weight-medium"><br>${data.someoneelse}</span><br>
            What’s a different thought that is more helpful and accurate? 
            <span class="font-weight-medium"><br>${data.differenthelpfulthought}</span>
            </div>`
          }
        }
      },
      'thought log': {
        default: {
          tooltip: (data) => {
            let feelings = [
              'anxious',
              'sad',
              'guilty',
              'embarrassed',
              'angry',
              'happy',
              'excited',
              'calm',
              'surprised'
            ]
            feelings =
              data?.['feelafter'].map(f => feelings[f - 1]).join(', ') || ''
            return `<div>
            What is a thought you've had recently?
            <span class="font-weight-medium"><br>${data.recentthought}</span><br>
            How did you feel after thinking that?
            <span class="font-weight-medium"><br>${feelings}</span><br>
            What did you do?
            <span class="font-weight-medium"><br>${data.didyoudo2}</span><br>
            Was this thought helpful or unhelpful?
            <span class="font-weight-medium"><br>${data.helpful === 0 ? 'Unhelpful' : 'Helpful'}</span><br>
            </div>`
          }
        }
      }
    },
    'Journals': {
      'gratitude journal': {
        default: {
          tooltip: (data) => {
            return `<div>
            Who are you grateful for today?
            <span class="font-weight-medium"><br>${data.gratefulforwho}</span><br>
            What is something good that has happened to you recently?
            <span class="font-weight-medium"><br>${data.recentgood}</span><br>
            What's something nice someone has done for you lately?
            <span class="font-weight-medium"><br>${data.somethingnice}</span><br>
            What was your favorite moment from today?
            <span class="font-weight-medium"><br>${data.favmoment}</span><br>
            </div>`
          }
        }
      },
      'self-esteem journal': {
        default: {
          tooltip: (data) => {
            return `<div>
            What's something you did this week that you're proud of?
            <span class="font-weight-medium"><br>${data.proudof}</span><br>
            What's a new thing you learned recently?
            <span class="font-weight-medium"><br>${data.newthing}</span><br>
            What's something you're good at?
            <span class="font-weight-medium"><br>${data.goodat}</span><br>
            What are you looking forward to in the near future?
            <span class="font-weight-medium"><br>${data.lookforward}</span><br>
            </div>`
          }
        }
      }
    },
    'Activity scheduling': {
      'relationships': {
        default: {
          tooltip: (data) => {
            const enjoyable = [
              'not enjoyable',
              'slightly enjoyable',
              'moderately enjoyable',
              'very enjoyable',
              'extremely enjoyable'
            ]
            const important = [
              'not important',
              'slightly important',
              'moderately important',
              'very important',
              'extremely important'
            ]
            let enjoyFeedback = enjoyable[data.enjoyrelation]
            let importantFeedback = important[data.importantrelation]
            return `<div>
            Client said <span class="font-weight-medium">${data.relationactivity}</span> was 
            <span class="font-weight-medium">${enjoyFeedback}</span> and 
            <span class="font-weight-medium">${importantFeedback}</span></div>`
          }
        }
      },
      'education/career': {
        default: {
          tooltip: (data) => {
            const enjoyable = [
              'not enjoyable',
              'slightly enjoyable',
              'moderately enjoyable',
              'very enjoyable',
              'extremely enjoyable'
            ]
            const important = [
              'not important',
              'slightly important',
              'moderately important',
              'very important',
              'extremely important'
            ]
            let enjoyFeedback = enjoyable[data.enjoyeducar]
            let importantFeedback = important[data.importanteducar]
            return `<div>
            Client said <span class="font-weight-medium">${data.educaractivity}</span> was 
            <span class="font-weight-medium">${enjoyFeedback}</span> and 
            <span class="font-weight-medium">${importantFeedback}</span></div>`
          }
        }
      },
      'mind/body/spirituality': {
        default: {
          tooltip: (data) => {
            const enjoyable = [
              'not enjoyable',
              'slightly enjoyable',
              'moderately enjoyable',
              'very enjoyable',
              'extremely enjoyable'
            ]
            const important = [
              'not important',
              'slightly important',
              'moderately important',
              'very important',
              'extremely important'
            ]
            let enjoyFeedback = enjoyable[data.enjoymind]
            let importantFeedback = important[data.importantmind]
            return `<div>
            Client said <span class="font-weight-medium">${data.mindactivity}</span> was 
            <span class="font-weight-medium">${enjoyFeedback}</span> and 
            <span class="font-weight-medium">${importantFeedback}</span></div>`
          }
        }
      },
      'recreation/interests': {
        default: {
          tooltip: (data) => {
            const enjoyable = [
              'not enjoyable',
              'slightly enjoyable',
              'moderately enjoyable',
              'very enjoyable',
              'extremely enjoyable'
            ]
            const important = [
              'not important',
              'slightly important',
              'moderately important',
              'very important',
              'extremely important'
            ]
            let enjoyFeedback = enjoyable[data.enjoyrelation]
            let importantFeedback = important[data.importantinterest]
            return `<div>
            Client said <span class="font-weight-medium">${data.interestactivity}</span> was 
            <span class="font-weight-medium">${enjoyFeedback}</span> and 
            <span class="font-weight-medium">${importantFeedback}</span></div>`
          }
        }
      },
      'daily responsibilities': {
        default: {
          tooltip: (data) => {
            const enjoyable = [
              'not enjoyable',
              'slightly enjoyable',
              'moderately enjoyable',
              'very enjoyable',
              'extremely enjoyable'
            ]
            const important = [
              'not important',
              'slightly important',
              'moderately important',
              'very important',
              'extremely important'
            ]
            let enjoyFeedback = enjoyable[data.enjoydaily]
            let importantFeedback = important[data.importantdaily]
            return `<div>
            Client said <span class="font-weight-medium">${data.dailyactivity}</span> was 
            <span class="font-weight-medium">${enjoyFeedback}</span> and 
            <span class="font-weight-medium">${importantFeedback}</span></div>`
          }
        }
      }
    }
  }
}
