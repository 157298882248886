export default {
  appTitle: process.env.VUE_APP_TITLE,
  loading: false,
  referer: null,
  acted: false,
  notice: null,
  isLocked: false,
  demo: {
    isDemo: false,
    // Display data as if it's 2022-09-01 (end of week of demo data)
    loopEndDate: {
      year: 2022,
      month: 8, // Sept
      date: 1
    },

    // Dev: Select dates that are relevant to your own local data
    // Make sure this stays commented in demo, local only!
    // loopEndDate: {
    //   year: 2022,
    //   month: 4, // 0-based
    //   date: 26
    // },

    // List of client ids that will be looped until the loopEndDate
    // This only applies in the demo env or localhost
    loopClients: [
      'IYXSUw6KWvm7TpKEnCTL',     // Jasmine
      'mynLTfisSlzXBPN1WiJr',     // Lea
      'FVqLAz98Z1qRlEWzGCyv',     // Janelle
      'aX1dkY2J5xkoEa1fc3Ps',     // Taylor's localhost "Demo Client"
      // 'OAy0W4SuF2ni5hQGSQz4',     // Taylor's localhost "Taylor Primary"
      // 'TFUjDelIGMZtKlVQVtHO',     // Taylor's localhost "Taylor"
    ]
  }
}
