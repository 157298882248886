<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6666 12V13.3333H1.33325V12H14.6666ZM1.33325 2.33331L6.66659 5.66665L1.33325 8.99998V2.33331ZM14.6666 7.33331V8.66665H7.99992V7.33331H14.6666ZM14.6666 2.66665V3.99998H7.99992V2.66665H14.6666Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
  export default {
    name: "JournalIcon"
  }
</script>