import moment from '@/plugins/moment'

const dateFormat = date => {
  const mDate = moment(date, 'YYYY-MM-DD')

  return (
    mDate.format('dddd') +
    ', ' +
    mDate.format('MMMM D') +
    ', ' +
    mDate.format('YYYY')
  )
}

const formatDatetimeLocal = (datetime, format = 'DD-MM-YYYY LT') => {
  return moment(datetime.toDate())
    .local()
    .format(format)
}
const formatTimeUtcToLocal = (time, format = 'LT') => {
  return moment
    .utc(time, 'HH:mm')
    .local()
    .format(format)
}
const formatTimeLocal = (time, format = 'LT') => {
  return moment(time, 'HH:mm')
    .local()
    .format(format)
    .toUpperCase()
}
const formatTimeUTC = (time, format = 'HH:mm') => {
  return moment(time, 'HH:mm')
    .utc()
    .format(format)
}

const datesFormat = (dates, isMultiple) => {
  const total = dates.length
  let dateFormatted = ''
  const joiner = isMultiple ? ', ending ' : ' and '
  dates
    // .sort((a, b) => moment(a) - moment(b))
    .forEach((date, index) => {
      dateFormatted += `${
        index + 1 === total && index > 0 ? joiner : ''
      }<span style="display: inline-block;">${dateFormat(date)}${
        index + 2 < total ? ', ' : ''
      }</span> `
    })

  return dateFormatted.trim()
}

const timesFormat = times => {
  const total = times.length
  let timeFormatted = ''

  times
    .map(item => moment.utc(item, 'HH:mm'))
    // .sort((a, b) => a - b)
    .forEach((date, index) => {
      timeFormatted += `${
        index + 1 === total && index > 0 ? ' and ' : ''
      }<span style="white-space: nowrap;">${formatTimeLocal(date)}${
        index + 2 < total ? ', ' : ''
      }</span>`
    })

  return timeFormatted.trim()
}

export {
  dateFormat,
  datesFormat,
  timesFormat,
  formatDatetimeLocal,
  formatTimeUtcToLocal,
  formatTimeLocal,
  formatTimeUTC
}
