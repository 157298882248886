export default [
  {
    path: 'summary',
    name: 'admin',
    component: () => import('@/views/dashboard/admin/Index.vue')
  },
  {
    path: 'reminders',
    name: 'adminReminders',
    component: () => import('@/views/dashboard/admin/reminders/Index.vue')
  },
  {
    path: 'reminders/:id',
    name: 'reminderShow',
    component: () => import('@/views/dashboard/admin/reminders/Show.vue')
  },
  {
    path: 'notifications',
    name: 'notifications',
    component: () => import('@/views/dashboard/admin/notifications/Index.vue')
  },
  {
    path: 'notifications/:id',
    name: 'notificationShow',
    component: () => import('@/views/dashboard/admin/notifications/Show.vue')
  },
  {
    path: 'contacts',
    name: 'contacts',
    component: () => import('@/views/dashboard/admin/contacts/Index.vue')
  },
  {
    path: 'contacts/:id',
    name: 'contactShow',
    component: () => import('@/views/dashboard/admin/contacts/Show.vue')
  },
  {
    path: 'logs',
    name: 'logs',
    component: () => import('@/views/dashboard/admin/logs/Index.vue')
  },
  {
    path: 'subscriptions',
    name: 'subscriptions',
    component: () => import('@/views/dashboard/admin/subscriptions/Index.vue')
  },
  {
    path: 'logs/:id',
    name: 'logShow',
    component: () => import('@/views/dashboard/admin/logs/Show.vue')
  },
  {
    path: 'users',
    name: 'users',
    component: () => import('@/views/dashboard/admin/users/Index.vue')
  },
  {
    path: 'users/show/:id',
    name: 'userShow',
    component: () => import('@/views/dashboard/admin/users/Show.vue')
  },
  {
    path: 'users/edit/:id',
    name: 'userEdit',
    component: () => import('@/views/dashboard/admin/users/Edit.vue')
  },
  {
    path: 'refresh',
    name: 'refresh',
    component: () => import('@/views/dashboard/admin/Refresh.vue')
  }
]
