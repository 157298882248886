export default [
	{
		path: 'summary-reminders',
		name: 'summary-reminders',
		component: () => import('@/views/dashboard/summary/reminders/Index.vue')
	},
	{
		path: 'summary-reminders/:id',
		name: 'summary-reminderShow',
		component: () => import('@/views/dashboard/summary/reminders/Show.vue')
	},
	{
		path: 'summary-clients',
		name: 'summary-clients',
		component: () => import('@/views/dashboard/summary/clients/Index.vue')
	}
]
