export const PLANNED = 1
export const TO_SEND = 2
export const SENT = 3
export const FAILED = 4

export const getStatusName = status => {
  switch (status) {
    case PLANNED:
      return 'PLANNED'

    case TO_SEND:
      return 'TO_SEND'

    case SENT:
      return 'SENT'

    case FAILED:
      return 'FAILED'

    default:
      return 'UNDEFINED'
  }
}

export const getStatusId = status => {
  switch (status) {
    case 'PLANNED':
      return PLANNED

    case 'TO_SEND':
      return TO_SEND

    case 'SENT':
      return SENT

    case 'FAILED':
      return FAILED

    default:
      return null
  }
}
