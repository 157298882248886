export default [
  {
    id: 1,
    title: 'Anxiety',
    image: 'anxiety.svg',
    subtitle: 'Assess with <b>{name}</b>...',
    description: 'Assess anxiety severity and symptoms',
    reminderType: 'assess',
    items: [
      {
        id: 3,
        title: 'Generalized Anxiety Disorder Screener (GAD-7)',
        type: 'interview',
        shortName: 'GAD-7',
        category: 'anxiety',
        maxScore: 21,
        chartYAxisSteps: 3,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'gad7ad',
              preview: 'gad7ad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                          It's time to do your assessment. This will only take a few minutes.<br>
                          Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
      {
        id: 4,
        title: 'Penn State Worry Questionnaire for Children (PSWQ-C)',
        type: 'interview',
        shortName: 'PSWQ-C',
        category: 'anxiety',
        maxScore: 42,
        chartYAxisSteps: 7,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'pswq-cad',
              preview: 'pswq-cad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                          It's time to do your assessment. This will only take a few minutes.<br>
                          Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
      {
        id: 5,
        title: 'Penn State Worry Questionnaire Adult (PSWQ-A)',
        type: 'interview',
        // text: 'Test text for assessment',
        shortName: 'PSWQ-A',
        category: 'anxiety',
        maxScore: 80,
        chartYAxisSteps: 16,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'pswq-aad',
              preview: 'pswq-aad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                          It's time to do your assessment. This will only take a few minutes.<br>
                          Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
      {
        id: 6,
        title: 'Screen for Child Anxiety Related Disorders Child Version',
        type: 'interview',
        shortName: 'SCARED-C',
        category: 'anxiety',
        maxScore: 82,
        chartYAxisSteps: 16,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'scared-cad',
              preview: 'scared-cad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
      {
        id: 7,
        title: 'Screen for Child Anxiety Related Disorders Parent Version',
        type: 'interview',
        shortName: 'SCARED-P',
        category: 'anxiety',
        maxScore: 82,
        chartYAxisSteps: 16,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'scared-pad',
              preview: 'scared-pad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
      {
        id: 8,
        title: 'Liebowitz Social Anxiety Scale Self-Report ',
        type: 'interview',
        shortName: 'LSAS-SR',
        category: 'anxiety',
        maxScore: 144,
        chartYAxisSteps: 24,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'lsas-srad',
              preview: 'lsas-srad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
    ],
  },
  {
    id: 2,
    title: 'Depression',
    image: 'depression.svg',
    subtitle: 'Assess with <b>{name}</b>...',
    description: 'Assess depression severity and symptoms',
    reminderType: 'assess',
    items: [
      {
        id: 1,
        title: 'Patient Health Questionnaire (PHQ-8)',
        type: 'interview',
        shortName: 'PHQ-8',
        category: 'depression',
        maxScore: 24,
        chartYAxisSteps: 6,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'phq8ad',
              preview: 'phq8ad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                          It's time to do your assessment. This will only take a few minutes.<br>
                          Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
      {
        id: 2 ,
        title: 'Center for Epidemiological Studies Depression Scale for Children',
        type: 'interview',
        shortName: 'CES-DC',
        category: 'depression',
        maxScore: 60,
        chartYAxisSteps: 12,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'ces-dcad',
              preview: 'ces-dcad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
    ],
  },
  {
    id: 3,
    title: 'Sleep',
    image: 'sleep.svg',
    subtitle: 'Assess with <b>{name}</b>...',
    description: 'Assess insomnia severity and symptoms',
    reminderType: 'assess',
    items: [
      {
        id: 1,
        title: 'Insomnia Severity Index (ISI)',
        type: 'interview',
        shortName: 'ISI',
        category: 'sleep',
        maxScore: 28,
        chartYAxisSteps: 7,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'isiad',
              preview: 'isiad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                          It's time to do your assessment. This will only take a few minutes.<br>
                          Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
    ],
  },
  {
    id: 4,
    title: 'PTSD',
    image: 'ptsd.svg',
    subtitle: 'Assess with <b>{name}</b>...',
    description: 'Assess PTSD severity and symptoms',
    reminderType: 'assess',
    items: [
      {
        id: 1,
        title: 'PTSD Checklist for DSM-5 (PCL-5)',
        type: 'interview',
        shortName: 'PCL-5',
        category: 'ptsd',
        maxScore: 80,
        chartYAxisSteps: 16,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'pcl5ad',
              preview: 'pcl5ad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                          It's time to do your assessment. This will only take a few minutes.<br>
                          Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
      {
        id: 2,
        title: 'The Child PTSD Symptom Scale',
        type: 'interview',
        shortName: 'CPSS',
        category: 'ptsd',
        maxScore: 80,
        chartYAxisSteps: 16,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'cpssad',
              preview: 'cpssad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
    ],
  },
  {
    id: 5,
    title: 'Treatment Satisfaction',
    image: 'treatment-satisfaction.svg',
    subtitle: 'Remind <b>{name}</b> to complete...',
    description: 'Assess satisfaction with treatment',
    timesLimit: 1,
    reminderType: 'assess',
    items: [
      {
        id: 1,
        title: 'Caregiver Satisfaction Questionnaire',
        type: 'interview',
        shortName: 'CSQ-P',
        category: 'questionnaire',
        maxScore: 60,
        chartYAxisSteps: 12,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'csqpad',
              preview: 'csqpad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
      {
        id: 2,
        title: 'Child/Adolescent Satisfaction Questionnaire',
        type: 'interview',
        shortName: 'CASQ-Y',
        category: 'questionnaire',
        maxScore: 60,
        chartYAxisSteps: 12,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'casq-yad',
              preview: 'casq-yad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
    ]
  },
  {
    id: 6,
    title: 'Therapeutic Alliance',
    image: 'therapeutic-alliance.svg',
    subtitle: 'Remind <b>{name}</b> to complete...',
    description: 'Assess therapeutic alliance',
    timesLimit: 1,
    reminderType: 'Assess',
    items: [
      {
        id: 1,
        title: 'Working Alliance Inventory - Short Revised - Client Version',
        type: 'interview',
        shortName: 'WAI-SR-Y',
        category: 'therapeutic alliance',
        maxScore: 60,
        chartYAxisSteps: 12,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'wai-sr-y-ad',
              preview: 'wai-sr-y-ad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
    ]
  },
  {
    id: 7,
    title: 'Disruptive Behavior',
    image: 'disruptive-behavior.svg',
    subtitle: 'Remind <b>{name}</b> to complete...',
    description: 'Assess disruptive behavior severity and symptoms',
    timesLimit: 1,
    reminderType: 'assess',
    items: [
      {
        id: 1,
        title: 'Child and Adolescent Disruptive Behavior Inventory - Parent',
        type: 'interview',
        shortName: 'CADBI Parent ',
        category: 'disruptive behavior',
        maxScore: 200,
        chartYAxisSteps: 40,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'cadbiad',
              preview: 'cadbiad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
    ]
  },
  {
    id: 8,
    title: 'Overall Mental Health',
    image: 'overall-mental-health.svg',
    subtitle: 'Remind <b>{name}</b> to complete...',
    description: 'Assess overall mental health and symptoms',
    timesLimit: 1,
    reminderType: 'assess',
    items: [
      {
        id: 1,
        title: 'Patient Health Questionnaire',
        type: 'interview',
        shortName: 'PHQ',
        category: 'overall mental health',
        maxScore: 100,
        chartYAxisSteps: 20,
        workflow: [
          { name: 'activity' },
          {
            name: 'dokbot',
            params: {
              activity: 'phqad',
              preview: 'phqad',
            },
          },
          { name: 'name' },
          {
            name: 'channel',
            params: { title: 'How does <b>{name}</b> want to be reminded?' },
          },
          { name: 'contact' },
          {
            name: 'summary',
            params: {
              messages: {
                sms: `Hi {name}! It's time to do your assessment. This will only take a few minutes. Click here to start: {link}`,
                email: `Hi {name}! <br><br>
                        It's time to do your assessment. This will only take a few minutes.<br>
                        Click here to start: <a href="{link}" target="_blank">{link}</a>`,
              },
            },
          },
          { name: 'scheduled' },
        ],
        notificationConfig: {
          type: 'once',
          times: ['19:00'],
          relativeNow: 72,
        },
      },
    ]
  }
]
