<script>
import * as dateFormat from '@/kit/helpers/dateFormat'

export default {
  methods: {
    dateFormat: dateFormat.dateFormat,
    datesFormat: dateFormat.datesFormat,
    timesFormat: dateFormat.timesFormat,
    formatDatetimeLocal: dateFormat.formatDatetimeLocal,
    formatTimeUtcToLocal: dateFormat.formatTimeUtcToLocal,
    formatTimeLocal: dateFormat.formatTimeLocal,
    formatTimeUTC: dateFormat.formatTimeUTC
  }
}
</script>
