import defaultState from '@/store/clients/state'

export default {
  reset: state => {
    Object.assign(state, defaultState())
  },
  setActivity: (state, payload) => {
    state.client.activity = payload
  },
  setClient: (state, payload) => {
    state.client = payload
  },
  setSMSWelcomeMsgStatus: (state, payload) => {
    state.client.smsWelcomeMsgSent = payload
  },
  updateActivity: (state, payload) => {
    state.client.activity.uid = payload.uid
    state.client.activity.exercise = payload.exercise
  }
}
