export default [ 
 {
    id: 1,
    title: "Custom reminder",
    image: "custom-reminder.svg",
    subtitle: "Remind <b>{name}</b> to...",
    description: "Customizable reminders to send to clients",
    items: [
      {
        id: 3,
        category: "Custom reminder",
        title: "Do something",
        type: 'chat',
        text: "Reminder to do a custom task chosen by the therapist",
        reply: true,
        exercise: 1,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "Remind <b>{name}</b> to...",
              placeholder: "e.g., read the handout I gave you"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              link: "{baseUrl}/go-link/{id}",
              messages: {
                sms: "Hi {name}! Just wanted to remind you to {remind}. Did you {remind}? Reply yes or click this link {link}",
                email: "Hi {name}! <br><br>Just wanted to remind you to {remind}. Did you {remind}?                        <br>Reply yes or click this link <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "MTo6ZG8gc29tZXRoaW5n"
      },
      {
        id: 4,
        category: "Custom reminder",
        title: "Check out a link",
        type: 'chat',
        text: "Reminder to click a link sent from the therapist",
        reply: true,
        exercise: 2,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "Remind {name} to check out...",
              placeholder: "e.g., www.youtube.com"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              link: "{baseUrl}/go-link/{id}",
              messages: {
                sms: "Hi {name}! Just wanted to remind you to check out this link: {remind}. Did you look at it? Reply yes or click this link {link}",
                email: "Hi {name}! <br><br> Just wanted to remind you to check out this link: <a href=\"{remind}\" target=\"_blank\">{remind}</a>. Did you look at it?                        <br>Reply yes or click this link <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "MTo6Y2hlY2sgb3V0IGEgbGluaw=="
      },
      {
        id: 6,
        category: "Custom reminder",
        title: "Attend next appointment",
        type: 'chat',
        text: "Reminder of the date and time of the next appointment",
        exercise: 4,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "date"
          },
          {
            name: "time"
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! Just wanted to remind you about your next appointment on {nextAppointmentDate} at {nextAppointmentTime}.",
                email: "Hi {name}! <br><br> Just wanted to remind you about your next appointment on {nextAppointmentDate} at {nextAppointmentTime}."
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "appointment",
          relativeDate: -24
        },
        uid: "MTo6YXR0ZW5kIG5leHQgYXBwb2ludG1lbnQ="
      },
      {
        id: 5,
        category: "Custom reminder",
        title: "Take medication",
        type: 'chat',
        text: "Reminder to take medication on time, as prescribed",
        reply: true,
        exercise: 3,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "Remind {name} to take...",
              placeholder: "e.g., blood pressure medication"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              link: "{baseUrl}/go-link/{id}",
              messages: {
                sms: "Hi {name}! Just wanted to remind you to take your {remind}. Did you take it? Reply yes or click this link {link}",
                email: "Hi {name}! <br><br> Just wanted to remind you to take your {remind}. Did you take it?                        <br>Reply yes or click this link <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00"
          ]
        },
        uid: "MTo6dGFrZSBtZWRpY2F0aW9u"
      }
    ]
  },
  {
    id: 2,
    title: "Positive affirmations",
    image: "simple-message.svg",
    subtitle: "Send {name} words of...",
    description: "Simple, encouraging messages to send to clients randomly during the week",
    items: [
      {
        id: 1,
        category: "Positive affirmations",
        title: "Encouragement",
        type: 'chat',
        text: "Messages with words of encouragement sent on a random day and time",
        exercise: 5,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "list",
            params: {
              title: "Which words of <span>encouragement</span> would you like to send {name}?",
              items: [
                {
                  id: 1,
                  text: "A little progress everyday adds up to big results 🤩🥳📈"
                },
                {
                  id: 2,
                  text: "you are stronger than you know 💪💪🤗"
                },
                {
                  id: 3,
                  text: "you are capable of amazing things🤩🥳"
                }
              ],
              fullWidth: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! Just wanted to remind you that {listName}.",
                email: "Hi {name}! <br><br> Just wanted to remind you that {listName}."
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "random",
          times: [
            "09:00"
          ],
          from: "07:00",
          to: "19:00"
        },
        uid: "Mjo6ZW5jb3VyYWdlbWVudA=="
      },
      {
        id: 2,
        category: "Positive affirmations",
        title: "Praise",
        type: 'chat',
        text: "Messages with words of praise sent on a random day and time",
        exercise: 6,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "list",
            params: {
              title: "Which words of <span>praise</span> would you like to send {name}?",
              items: [
                {
                  id: 1,
                  text: "I’ve noticed how hard you’ve been working 👏 💪"
                },
                {
                  id: 2,
                  text: "I’m really proud of you 👏 👏 🤩"
                },
                {
                  id: 3,
                  text: "I’m really impressed by the progress you have been making 🤩🥳 📈"
                }
              ],
              fullWidth: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! Just wanted to remind you that {listName}.",
                email: "Hi {name}! <br><br> Just wanted to remind you that {listName}."
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "random",
          times: [
            "09:00"
          ],
          from: "07:00",
          to: "19:00"
        },
        uid: "Mjo6cHJhaXNl"
      },
      {
        id: 3,
        category: "Positive affirmations",
        title: "Confidence",
        type: 'chat',
        text: "Messages with words of confidence sent on a random day and time",
        exercise: 7,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "list",
            params: {
              title: "Which words of <span>confidence</span> would you like to send {name}?",
              items: [
                {
                  id: 1,
                  text: "If you give it everything you’ve got, that is more than enough 💪👏"
                },
                {
                  id: 2,
                  text: "Everything you need to accomplish your goals is already in you ✅🏆"
                },
                {
                  id: 3,
                  text: "you are amazing, brave, and strong 💪 🙌 🤗"
                }
              ],
              fullWidth: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! Just wanted to remind you that {listName}.",
                email: "Hi {name}! <br><br> Just wanted to remind you that {listName}."
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "random",
          times: [
            "09:00"
          ],
          from: "07:00",
          to: "19:00"
        },
        uid: "Mjo6Y29uZmlkZW5jZQ=="
      },
      {
        id: 4,
        category: "Positive affirmations",
        title: "Support",
        type: 'chat',
        text: "Messages with words of support sent on a random day and time",
        exercise: 8,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "list",
            params: {
              title: "Which words of <span>support</span> would you like to send your client?",
              items: [
                {
                  id: 1,
                  text: "Even though times are tough you can make it through this 💪 💯"
                },
                {
                  id: 2,
                  text: "I’m here for you when you need me 👋🤝 🥰"
                },
                {
                  id: 3,
                  text: "Don’t get stuck on the little things because you have big things coming your way 🙌💯"
                }
              ],
              fullWidth: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! Just wanted to remind you that {listName}.",
                email: "Hi {name}! <br><br> Just wanted to remind you that {listName}."
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "random",
          times: [
            "09:00"
          ],
          from: "07:00",
          to: "19:00"
        },
        uid: "Mjo6c3VwcG9ydA=="
      }
    ]
  },
  {
    id: 3,
    title: "Self-monitoring",
    image: "self-monitoring.svg",
    subtitle: "Remind {name} to monitor...",
    description: "Self-monitoring exercises to help clients track their behaviors, thoughts, and emotions",
    items: [
      {
        id: 1,
        exercise: 9,
        category: "Self-monitoring",
        title: "Distressing situations",
        type: 'interview',
        text: "Monitor distressing situations and resulting feelings, thoughts, sensations, and behaviors",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "situations",
              preview: "situationsprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              link: "{baseUrl}/go/situations?timeId={id}",
              messages: {
                sms: "Hi {name}! It’s time to do your self-monitoring. This will only take a few minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It’s time to do your self-monitoring. This will only take a few minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "19:00"
          ]
        },
        uid: "Mzo6ZGlzdHJlc3Npbmcgc2l0dWF0aW9ucw=="
      },
      {
        id: 2,
        category: "Self-monitoring",
        title: "Behaviors",
        type: 'interview',
        text: "Monitor frequency and duration of behaviors",
        subCategory: 1,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "list",
            params: {
              title: "Which <span>behavior</span> would you like {name} to monitor this week?",
              items: [
                {
                  id: 1,
                  text: "Avoidance",
                  type: 'interview',
                  exercise: 10,
                  dokbotParams: {
                    activity: "avoidance",
                    preview: "avoidanceprev"
                  },
                  uid: "Mzo6YXZvaWRhbmNl"
                },
                {
                  id: 2,
                  text: "Checking",
                  type: 'interview',
                  exercise: 11,
                  dokbotParams: {
                    activity: "checking",
                    preview: "checkingprev"
                  },
                  uid: "Mzo6Y2hlY2tpbmc="
                },
                {
                  id: 3,
                  text: "Physical activity",
                  type: 'interview',
                  exercise: 12,
                  dokbotParams: {
                    activity: "physical-activity",
                    preview: "physical-activityprev"
                  },
                  uid: "Mzo6cGh5c2ljYWwgYWN0aXZpdHk="
                },
                {
                  id: 4,
                  text: "Repetitive behavior",
                  type: 'interview',
                  exercise: 13,
                  dokbotParams: {
                    activity: "repetitive",
                    preview: "repetitiveprev"
                  },
                  uid: "Mzo6cmVwZXRpdGl2ZSBiZWhhdmlvcg=="
                },
                {
                  id: 5,
                  text: "Tics",
                  type: 'interview',
                  exercise: 14,
                  dokbotParams: {
                    activity: "tics",
                    preview: "ticsprev"
                  },
                  uid: "Mzo6dGljcw=="
                }
              ],
              fullWidth: false
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It’s time to do your self-monitoring. This will only take a few minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It’s time to do your self-monitoring. This will only take a few minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "19:00"
          ]
        }
      },
      {
        id: 3,
        category: "Self-monitoring",
        title: "Thoughts",
        type: 'interview',
        text: "Monitor frequency and duration of thoughts",
        subCategory: 2,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "list",
            params: {
              title: "Which <span>thoughts</span> would you like {name} to monitor this week?",
              items: [
                {
                  id: 1,
                  text: "Worries",
                  type: 'interview',
                  exercise: 15,
                  dokbotParams: {
                    activity: "worries",
                    preview: "worriesprev"
                  },
                  uid: "Mzo6d29ycmllcw=="
                },
                {
                  id: 2,
                  exercise: 16,
                  text: "Intrusive thoughts",
                  type: 'interview',
                  dokbotParams: {
                    activity: "intrusive",
                    preview: "intrusiveprev"
                  },
                  uid: "Mzo6aW50cnVzaXZlIHRob3VnaHRz"
                },
                {
                  id: 3,
                  exercise: 17,
                  text: "Pessimism",
                  type: 'interview',
                  dokbotParams: {
                    activity: "pessimism",
                    preview: "pessimismprev"
                  },
                  uid: "Mzo6cGVzc2ltaXNt"
                },
                {
                  id: 4,
                  exercise: 18,
                  text: "Self-deprecating",
                  type: 'interview',
                  dokbotParams: {
                    activity: "deprecating",
                    preview: "deprecatingprev"
                  },
                  uid: "Mzo6c2VsZi1kZXByZWNhdGluZw=="
                },
                {
                  id: 5,
                  exercise: 19,
                  text: "Catastrophizing",
                  type: 'interview',
                  dokbotParams: {
                    activity: "catastrophizing",
                    preview: "catastrophizingprev"
                  },
                  uid: "Mzo6Y2F0YXN0cm9waGl6aW5n"
                }
              ],
              fullWidth: false
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It’s time to do your self-monitoring. This will only take a few minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It’s time to do your self-monitoring. This will only take a few minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "19:00"
          ]
        }
      },
      {
        id: 4,
        category: "Self-monitoring",
        title: "Emotions",
        type: 'interview',
        text: "Monitor emotions and what the client did to cope",
        subCategory: 3,
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "list",
            params: {
              title: "Which <span>emotions</span> would you like {name} to monitor this week?",
              items: [
                {
                  id: 1,
                  text: "Anxiety",
                  type: 'interview',
                  exercise: 20,
                  dokbotParams: {
                    activity: "anxiety",
                    preview: "anxietyprev"
                  },
                  uid: "Mzo6YW54aWV0eQ=="
                },
                {
                  id: 2,
                  text: "Fear",
                  type: 'interview',
                  exercise: 21,
                  dokbotParams: {
                    activity: "fear",
                    preview: "fearprev"
                  },
                  uid: "Mzo6ZmVhcg=="
                },
                {
                  id: 3,
                  exercise: 22,
                  text: "Sadness",
                  type: 'interview',
                  dokbotParams: {
                    activity: "sadness",
                    preview: "sadnessprev"
                  },
                  uid: "Mzo6c2FkbmVzcw=="
                },
                {
                  id: 4,
                  exercise: 23,
                  text: "Stress",
                  type: 'interview',
                  dokbotParams: {
                    activity: "stress",
                    preview: "stressprev"
                  },
                  uid: "Mzo6c3RyZXNz"
                },
                {
                  id: 5,
                  text: "Anger",
                  type: 'interview',
                  exercise: 24,
                  dokbotParams: {
                    activity: "anger",
                    preview: "angerprev"
                  },
                  uid: "Mzo6YW5nZXI="
                }
              ],
              fullWidth: false
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It’s time to do your self-monitoring. This will only take a few minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It’s time to do your self-monitoring. This will only take a few minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "19:00"
          ]
        }
      }
    ]
  },
  {
    id: 4,
    title: "Relaxation",
    image: "relaxation.svg",
    subtitle: "Remind {name} to practice...",
    description: "Relaxation exercises to help clients practice deep breathing, muscle relaxation, and mindfulness",
    category: 4,
    items: [
      {
        id: 1,
        exercise: 25,
        category: "Relaxation",
        title: "Deep breathing",
        type: 'video',
        text: "Relaxation exercises to help clients practice deep breathing, muscle relaxation, and mindfulness",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "relaxation-choose",
            params: {
              duration: 300,
              previewMode: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice deep breathing. This will only take 5 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice deep breathing. This will only take 5 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "NDo6ZGVlcCBicmVhdGhpbmc="
      },
      {
        id: 2,
        exercise: 26,
        category: "Relaxation",
        title: "Muscle relaxation for adults",
        type: 'video',
        text: "Practice progressive muscle relaxation guided by audio instructions",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "audio",
            params: {
              previewMode: true,
              audios: [
                {
                  id: 1,
                  label: "6 min",
                  audioFile: "muscle-relaxation-adult-brief.mp3",
                  imageFile: "muscle-relaxation.svg",
                  duration: 375,
                  selected: true
                },
                {
                  id: 2,
                  label: "10 min",
                  audioFile: "muscle-relaxation-adult-long.mp3",
                  imageFile: "muscle-relaxation.svg",
                  duration: 570
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice muscle relaxation. This will only take 6-10 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice muscle relaxation. This will only take 6-10 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "NDo6bXVzY2xlIHJlbGF4YXRpb24gZm9yIGFkdWx0cw=="
      },
      {
        id: 6,
        exercise: 57,
        category: "Relaxation",
        title: "Roll breathing",
        type: 'video',
        text: "Practice roll breathing guided by audio and a visual animation",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "video",
            type: "video",
            params: {
              duration: 480,
              name: "video",
              videoFiles: [
                {
                  src: "roll-breathing.mp4",
                  type: "video/mp4"
                },
                {
                  src: "roll-breathing.webm",
                  type: "video/webm"
                }
              ],
              previewMode: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice roll breathing. This will only take 8 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice roll breathing. This will only take 8 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "NDo6cm9sbCBicmVhdGhpbmc="
      },
      {
        id: 3,
        category: "Relaxation",
        title: "Muscle relaxation for children",
        type: 'video',
        exercise: 27,
        text: "Practice progressive muscle relaxation guided by audio instructions",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              previewMode: true,
              videos: [
                {
                  id: 1,
                  label: "6 min",
                  videoId: "IC4LBM5qTdM",
                  selected: true
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice muscle relaxation. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice muscle relaxation. This will only take 6 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "NDo6bXVzY2xlIHJlbGF4YXRpb24gZm9yIGNoaWxkcmVu"
      },
      {
        id: 4,
        exercise: 28,
        category: "Relaxation",
        title: "Mindfulness for adults",
        type: 'video',
        text: "Practice mindfulness guided by audio instructions",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "audio",
            params: {
              previewMode: true,
              audios: [
                {
                  id: 1,
                  label: "6 min",
                  audioFile: "mindfulness-adult-brief.mp3",
                  imageFile: "muscle-relaxation.svg",
                  duration: 405,
                  selected: true
                },
                {
                  id: 2,
                  label: "15 min",
                  audioFile: "mindfulness-adult-long.mp3",
                  imageFile: "muscle-relaxation.svg",
                  duration: 910
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice mindfulness. This will only take 6-15 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice mindfulness. This will only take 6-15 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "NDo6bWluZGZ1bG5lc3MgZm9yIGFkdWx0cw=="
      },
      {
        id: 5,
        exercise: 29,
        category: "Relaxation",
        title: "Mindfulness for children",
        type: 'video',
        text: "Practice mindfulness guided by audio instructions",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              previewMode: true,
              videos: [
                {
                  id: 1,
                  label: "6 min",
                  videoId: "jU92aulVeaQ",
                  selected: true
                },
                {
                  id: 2,
                  label: "12 min",
                  videoId: "W8Mjl9jIOg4"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice mindfulness. This will only take 6-12 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice mindfulness. This will only take 6-12 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "NDo6bWluZGZ1bG5lc3MgZm9yIGNoaWxkcmVu"
      },
      {
        id: 7,
        exercise: 59,
        category: "Relaxation",
        title: "Morning breathing",
        type: 'video',
        text: "Practice morning breathing guided by audio and a visual animation",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "video",
            type: "video",
            params: {
              duration: 211,
              videoFiles: [
                {
                  src: "morning-breathing.mp4",
                  type: "video/mp4"
                },
                {
                  src: "morning-breathing.webm",
                  type: "video/webm"
                }
              ],
              previewMode: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice morning breathing. This will only take 4 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice morning breathing. This will only take 4 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "08:00"
          ]
        },
        uid: "NDo6bW9ybmluZyBicmVhdGhpbmc="
      },
      {
        id: 8,
        exercise: 68,
        category: "Relaxation",
        title: "4-7-8 breathing",
        type: 'video',
        text: "Practice 4-7-8 breathing guided by audio and visual animation",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "video",
            type: "video",
            params: {
              duration: 238,
              videoFiles: [
                {
                  src: "478-breathing.mp4",
                  type: "video/mp4"
                },
                {
                  src: "478-breathing.webm",
                  type: "video/webm"
                }
              ],
              previewMode: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice 4-7-8 breathing. This will only take 4 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice 4-7-8 breathing. This will only take 4 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "NDo6NC03LTggYnJlYXRoaW5n"
      },
      {
        id: 9,
        exercise: 70,
        category: "Relaxation",
        title: "Belly breathing",
        type: 'video',
        text: "Practice belly (i.e., diaphragmatic) breathing guided by audio and visual animation",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "video",
            type: "video",
            params: {
              duration: 135,
              videoFiles: [
                {
                  src: "belly-breathing.mp4",
                  type: "video/mp4"
                },
                {
                  src: "belly-breathing.webm",
                  type: "video/webm"
                }
              ],
              previewMode: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice belly breathing. This will only take 2 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice belly breathing. This will only take 2 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "07:00",
            "12:00",
            "20:00"
          ]
        },
        uid: "NDo6YmVsbHkgYnJlYXRoaW5n"
      }
    ]
  },
  {
    id: 5,
    title: "Affect and emotion",
    type: 'game',
    image: "affect-and-emotion.svg",
    subtitle: "Remind <b>{name}</b> to practice...",
    description: "Exercises to help clients identify emotions",
    items: [
      {
        id: 1,
        exercise: 30,
        category: "Affect and emotion",
        title: "Identifying feelings",
        type: 'game',
        text: "Identify whether a word describes a feeling",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "idfeel",
              preview: "idfeelprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play the identifying feelings game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to play the identifying feelings game. This will only take 3 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "NTo6aWRlbnRpZnlpbmcgZmVlbGluZ3M="
      },
      {
        id: 2,
        exercise: 31,
        category: "Affect and emotion",
        title: "Emoji emotion matching",
        type: 'game',
        text: "Match the feeling that describes the emoji",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "emojimatch",
              preview: "emojimatchprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play the emoji emotion matching game. This will only take 2 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to play the emoji emotion matching game. This will only take 2 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "NTo6ZW1vamkgZW1vdGlvbiBtYXRjaGluZw=="
      },
      {
        id: 3,
        exercise: 32,
        category: "Affect and emotion",
        title: "Picture emotion matching",
        type: 'game',
        text: "Match the feeling that describes the person in the picture",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "picmatch",
              preview: "picmatchprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play the picture emotion matching game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to play the picture emotion matching game. This will only take 3 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "NTo6cGljdHVyZSBlbW90aW9uIG1hdGNoaW5n"
      }
    ]
  },
  {
    id: 6,
    title: "Cognitive",
    type: 'game',
    image: "cognitive.svg",
    subtitle: "Remind <b>{name}</b> to practice...",
    description: "Cognitive exercises to help clients identify and change unhelpful thoughts",
    items: [
      {
        id: 1,
        exercise: 33,
        category: "Cognitive",
        title: "Thoughts, feelings, actions",
        type: 'game',
        text: "Identify whether a word is a thought, feeling, or action",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "tfa",
              preview: "tfaprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play a thoughts, feelings, and actions game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to play a thoughts, feelings, and actions game. This will only take 3 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Njo6dGhvdWdodHMsIGZlZWxpbmdzLCBhY3Rpb25z"
      },
      {
        id: 4,
        exercise: 34,
        category: "Cognitive",
        title: "Thoughts, feelings, actions stories",
        type: 'game',
        text: "Read stories and explore how different thoughts affect how the character feels and acts",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "tfas",
              preview: "tfasprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to read some stories about thoughts, feelings, and actions. This will only take about 7 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to read some stories about thoughts, feelings, and actions. This will only take about 7 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Njo6dGhvdWdodHMsIGZlZWxpbmdzLCBhY3Rpb25zIHN0b3JpZXM="
      },
      {
        id: 2,
        exercise: 35,
        category: "Cognitive",
        title: "Helpful and unhelpful thoughts",
        type: 'game',
        text: "Identify whether a thought is helpful or unhelpful",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "hut",
              preview: "hutprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play a thought matching game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to play a thought matching game. This will only take 3 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Njo6aGVscGZ1bCBhbmQgdW5oZWxwZnVsIHRob3VnaHRz"
      },
      {
        id: 3,
        exercise: 36,
        category: "Cognitive",
        title: "Changing unhelpful thoughts",
        type: 'game',
        text: "Choose an alternative, helpful thought to replace the unhelpful thought",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "cut",
              preview: "cutprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play a changing unhelpful thoughts game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to play a changing unhelpful thoughts game. This will only take 3 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Njo6Y2hhbmdpbmcgdW5oZWxwZnVsIHRob3VnaHRz"
      },
      {
        id: 5,
        exercise: 80,
        category: "Cognitive",
        title: "Matching cognitive distortions",
        type: 'game',
        text: "Match each cognitive distortion with its definition",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "mct",
              preview: "mct"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play a matching distorting thoughts game. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to play a matching distorting thoughts game. This will only take 3 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "Njo6bWF0Y2hpbmcgY29nbml0aXZlIGRpc3RvcnRpb25z"
      },
      {
        id: 6,
        exercise: 69,
        category: "Cognitive",
        title: "Socratic questioning",
        type: 'interview',
        text: "Choose a recent, upsetting thought and ask questions to decide if it’s helpful or accurate",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "sques",
              preview: "sques"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to work on socratic questioning. This will only take 3 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to work on socratic questioning. This will only take 3 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "Njo6c29jcmF0aWMgcXVlc3Rpb25pbmc="
      },
      {
        id: 7,
        exercise: 71,
        category: "Cognitive",
        title: "Thought log",
        type: 'interview',
        text: "Choose a recent thought and feelings and actions that followed, decide if it was helpful or unhelpful",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "tlog",
              preview: "tlog"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to work on a thought log. This will only take 2 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to work on a thought log. This will only take 2 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "Njo6dGhvdWdodCBsb2c="
      }
    ]
  },
  {
    id: 7,
    title: "Exposure",
    image: "exposure.svg",
    subtitle: "Remind <b>{name}</b> to practice...",
    description: "Exercises to help clients understand and practice exposure therapy",
    items: [
      {
        id: 1,
        exercise: 37,
        category: "Exposure",
        title: "Stories about exposure",
        type: 'game',
        text: "Read stories and explore how different responses to feared situations affect the character",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "sae",
              preview: "saeprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to read some stories about facing fears. This will only take 5 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to read some stories about facing fears. This will only take 5 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Nzo6c3RvcmllcyBhYm91dCBleHBvc3VyZQ=="
      },
      {
        id: 2,
        exercise: 38,
        category: "Exposure",
        title: "In-vivo exposure",
        type: 'journal',
        text: "Spend time around a feared object or situation for a specific duration of time",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What is <b>{name}</b> going to spend time around this week?",
              placeholder: "e.g., big dogs"
            }
          },
          {
            name: "choice2",
            params: {
              title: "How many minutes should <b>{name}</b> spend being around <b>{remind}</b>?",
              items: [
                {
                  id: 1,
                  title: "30 min",
                  value: 30
                },
                {
                  id: 2,
                  title: "45 min",
                  value: 45
                },
                {
                  id: 3,
                  title: "60 min",
                  value: 60
                },
                {
                  id: 4,
                  title: "75 min",
                  value: 75
                },
                {
                  id: 5,
                  title: "90 min",
                  value: 90
                }
              ]
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "ivexp",
              preview: "ivexpprev",
              inputs: {
                thing: "{remind}",
                minutes: "{choiceValue}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice spending time around {remind} for {choiceValue} minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice spending time around {remind} for {choiceValue} minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "Nzo6aW4tdml2byBleHBvc3VyZQ=="
      }
    ]
  },
  {
    id: 8,
    title: "Parent-child activities",
    image: "parent-child-activities.svg",
    subtitle: "Remind <b>{name}</b> to practice with <b>{caregiverName}</b>...",
    description: "Activities for caregivers and children to practice together to strengthen their relationship",
    items: [
      {
        id: 1,
        exercise: 39,
        category: "Parent-child activities",
        title: "Plan a fun activity/do a fun activity",
        type: 'journal',
        text: "Plan an activity caregivers and children can do together in the coming week",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What activity would <b>{name}</b> and <b>{caregiverName}</b> like to do together this week?",
              placeholder: "e.g., Go to the park"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "pafa",
              preview: "pafaprev",
              inputs: {
                activity: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name} and {caregiverName}! It's time to {remind} together! Click here when you’re done: {link}",
                email: "Hi {name} and {caregiverName}! <br><br>                          It's time to {remind} together!<br>                          Click here when you’re done: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "ODo6cGxhbiBhIGZ1biBhY3Rpdml0eS9kbyBhIGZ1biBhY3Rpdml0eQ=="
      },
      {
        id: 2,
        exercise: 40,
        category: "Parent-child activities",
        title: "20 questions",
        type: 'game',
        text: "Ask questions to get to know each other",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "20ques",
              preview: "20quesprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to play the 20 questions game with your parent or caregiver! This will take about 20 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to play the 20 questions game with your parent or caregiver! This will take about 20 minutes. <br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "ODo6MjAgcXVlc3Rpb25z"
      },
      {
        id: 3,
        category: "Parent-child activities",
        title: "Teach a skill",
        type: 'journal',
        text: "Help clients to teach someone a skill learned in therapy to reinforce learning",
        subCategory: 4,
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "list",
            params: {
              title: "Which skill should {name} teach {caregiverName}?",
              items: [
                {
                  id: 1,
                  exercise: 41,
                  text: "Exposure",
                  dokbotParams: {
                    activity: "tas-exp",
                    preview: "tas-expprev"
                  },
                  uid: "ODo6ZXhwb3N1cmU="
                },
                {
                  id: 2,
                  exercise: 42,
                  text: "Changing unhelpful thoughts",
                  dokbotParams: {
                    activity: "tas-cut",
                    preview: "tas-cutprev"
                  },
                  uid: "ODo6Y2hhbmdpbmcgdW5oZWxwZnVsIHRob3VnaHRz"
                },
                {
                  id: 3,
                  exercise: 43,
                  text: "Mindfulness",
                  dokbotParams: {
                    activity: "tas-mind",
                    preview: "tas-mindprev"
                  },
                  uid: "ODo6bWluZGZ1bG5lc3M="
                },
                {
                  id: 4,
                  exercise: 44,
                  text: "Muscle relaxation",
                  dokbotParams: {
                    activity: "tas-mrelax",
                    preview: "tas-mrelaxprev"
                  },
                  uid: "ODo6bXVzY2xlIHJlbGF4YXRpb24="
                },
                {
                  id: 5,
                  exercise: 45,
                  text: "Deep breathing",
                  dokbotParams: {
                    activity: "tas-deepbreath",
                    preview: "tas-deepbreathprev"
                  },
                  uid: "ODo6ZGVlcCBicmVhdGhpbmc="
                }
              ],
              fullWidth: true
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice teaching skills to your parent or caregiver! This will only take 10 minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice teaching skills to your parent or caregiver! This will only take 10 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        }
      }
    ]
  },
  {
    id: 9,
    title: "Enhancing safety",
    image: "enhancing-safety.svg",
    subtitle: "Remind <b>{name}</b> to practice...",
    description: "Exercises to help clients know and practice their safety plan",
    items: [
      {
        id: 1,
        exercise: 46,
        category: "Enhancing safety",
        title: "Teach your safety plan",
        type: 'journal',
        text: "Clients teach someone else what's included in their safety plan",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "tysp",
              preview: "tyspprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice teaching your safety plan to your parent or caregiver! Click here when you’re done: {link}",
                email: "Hi {name}!<br><br>                          It's time to practice teaching your safety plan to your parent or caregiver!<br>                          Click here when you’re done: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "OTo6dGVhY2ggeW91ciBzYWZldHkgcGxhbg=="
      },
      {
        id: 2,
        exercise: 47,
        category: "Enhancing safety",
        title: "Practice your safety plan",
        type: 'journal',
        text: "Clients practice their safety plan",
        audience: [
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "pysp",
              preview: "pyspprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to practice your safety plan with your parent or caregiver! Click here when you are done: {link}",
                email: "Hi {name}! <br><br>                          It's time to practice your safety plan with your parent or caregiver!. <br>                          Click here when you are done: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "OTo6cHJhY3RpY2UgeW91ciBzYWZldHkgcGxhbg=="
      }
    ]
  },
  {
    id: 10,
    title: "Parenting exercises",
    image: "parenting-exercises.svg",
    subtitle: "Remind <b>{caregiverName}</b> to practice...",
    description: "Exercises to help caregivers practice parenting skills",
    caregiver: true,
    items: [
      {
        id: 1,
        exercise: 53,
        category: "Parenting exercises",
        title: "Praise",
        type: 'journal',
        text: "Catch a child behaving well and praise them for it",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "pe-praise",
              preview: "praiseprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It’s time to practice praising {name}’s good behavior. Click here when you’re ready: {link}",
                email: "Hi {caregiverName}! <br><br>                    It’s time to practice praising {name}’s good behavior.<br>                    Click here when you’re ready: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "19:00"
          ]
        },
        uid: "MTA6OnByYWlzZQ=="
      },
      {
        id: 2,
        exercise: 54,
        category: "Parenting exercises",
        title: "Positive attending",
        type: 'journal',
        text: "Do an activity with a child and practice giving praise for their good behavior",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "pe-pa",
              preview: "pe-paprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It’s time to practice positive attending with {name}. Click here when you’re ready: {link}",
                email: "Hi {caregiverName}! <br><br>                    It’s time to practice positive attending with {name}.<br>                    Click here when you’re ready: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "19:00"
          ]
        },
        uid: "MTA6OnBvc2l0aXZlIGF0dGVuZGluZw=="
      },
      {
        id: 3,
        exercise: 55,
        category: "Parenting exercises",
        title: "Contingency management",
        type: 'journal',
        text: "Practice setting up a contingency management plan with child",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "pe-cm",
              preview: "pe-cmprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It’s time to practice using contingency management with {name}. Click here when you’re ready: {link}",
                email: "Hi {caregiverName}! <br><br>                    It’s time to practice using contingency management with {name}.<br>                    Click here when you’re ready: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "MTA6OmNvbnRpbmdlbmN5IG1hbmFnZW1lbnQ="
      },
      {
        id: 4,
        exercise: 56,
        category: "Parenting exercises",
        title: "Reflection skills",
        type: 'journal',
        text: "Practice reflecting what a child says back to them to show understanding",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "pe-rs",
              preview: "pe-rsprev"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It’s time to practice reflection skills with {name}. Click here when you’re ready: {link}",
                email: "Hi {caregiverName}! <br><br>                    It’s time to practice reflection skills with {name}.<br>                    Click here when you’re ready: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "19:00"
          ]
        },
        uid: "MTA6OnJlZmxlY3Rpb24gc2tpbGxz"
      }
    ]
  },
  {
    id: 11,
    title: "Parenting videos",
    image: "parenting-videos.svg",
    subtitle: "Remind <b>{caregiverName}</b> to watch...",
    description: "Videos to help strengthen caregiver learning",
    caregiver: true,
    items: [
      {
        id: 1,
        exercise: 48,
        category: "Parenting videos",
        title: "Communication",
        type: 'video',
        text: "Videos to help caregivers communicate better with children",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              previewMode: true,
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "ZG3WIwi5y6k",
                  selected: true
                },
                {
                  id: 2,
                  label: "Video 2",
                  videoId: "mEqaNDwaKfk"
                },
                {
                  id: 3,
                  label: "Video 3",
                  videoId: "n3oKwCk5k3w"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "MTE6OmNvbW11bmljYXRpb24="
      },
      {
        id: 2,
        exercise: 49,
        category: "Parenting videos",
        title: "Structure and rules",
        type: 'video',
        text: "Videos to help caregivers create structure and rules effectively",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              previewMode: true,
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "98c9MWER080",
                  selected: true
                },
                {
                  id: 2,
                  label: "Video 2",
                  videoId: "JPEm7khwoKk"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "MTE6OnN0cnVjdHVyZSBhbmQgcnVsZXM="
      },
      {
        id: 3,
        exercise: 50,
        category: "Parenting videos",
        title: "Giving directions",
        type: 'video',
        text: "Videos to help caregivers give directions effectively",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              previewMode: true,
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "jyo2m6cEitg",
                  selected: true
                },
                {
                  id: 2,
                  label: "Video 2",
                  videoId: "zUSosdCjefo"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "MTE6OmdpdmluZyBkaXJlY3Rpb25z"
      },
      {
        id: 4,
        exercise: 51,
        category: "Parenting videos",
        title: "Discipline and consequences",
        type: 'video',
        text: "Videos to help caregivers use discipline and consequences effectively",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              previewMode: true,
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "7BW_JamMZho",
                  selected: true
                },
                {
                  id: 2,
                  label: "Video 2",
                  videoId: "bsCMWwyaWTk"
                },
                {
                  id: 3,
                  label: "Video 3",
                  videoId: "2EOo_BuRAL4"
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "MTE6OmRpc2NpcGxpbmUgYW5kIGNvbnNlcXVlbmNlcw=="
      },
      {
        id: 5,
        exercise: 52,
        category: "Parenting videos",
        title: "Time-Out",
        type: 'video',
        text: "Video to help caregivers use time-out effectively",
        audience: [
          "caregiver"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "youtube-video",
            params: {
              previewMode: true,
              videos: [
                {
                  id: 1,
                  label: "Video 1",
                  videoId: "teJ1cO4769U",
                  selected: true
                }
              ]
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {caregiverName}! It's time to watch some helpful parenting videos! This will take less than 15 minutes. Click here to start: {link}",
                email: "Hi {caregiverName}! <br><br>                          It's time to watch some helpful parenting videos! This will take less than 15 minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "19:00"
          ],
          relativeNow: 72
        },
        uid: "MTE6OnRpbWUtb3V0"
      }
    ]
  },
  {
    id: 12,
    title: "Social skills",
    image: "social-skills.svg",
    subtitle: "Remind <b>{name}<b> to practice...",
    description: "Exercises to help clients practice social skills by starting conversations with familiar people",
    items: [
      {
        id: 1,
        exercise: 58,
        category: "Social skills",
        title: "Appropriate conversation topics",
        type: 'game',
        text: "Identify whether the topic is appropriate to talk about with someone new",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "actwsn",
              preview: "actwsn"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to review appropriate conversation topics. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to review appropriate conversation topics.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6OmFwcHJvcHJpYXRlIGNvbnZlcnNhdGlvbiB0b3BpY3M="
      },
      {
        id: 2,
        exercise: 60,
        category: "Social skills",
        title: "Starting conversations with someone new",
        type: 'journal',
        text: "Practice starting a conversation with a new person",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can start a conversation with someone new this week?",
              placeholder: "e.g., complimenting someone's shirt"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "scwsn-hw",
              preview: "scwsn-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice starting a conversation with someone new. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to practice starting a conversation with someone new.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6OnN0YXJ0aW5nIGNvbnZlcnNhdGlvbnMgd2l0aCBzb21lb25lIG5ldw=="
      },
      {
        id: 3,
        exercise: 61,
        category: "Social skills",
        title: "Maintaining conversations with someone new",
        type: 'journal',
        text: "Practice keeping a conversation going with a new person",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can maintain a conversation with someone new this week?",
              placeholder: "e.g., asking someone with the same phone what they think of it"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "mcwsn-hw",
              preview: "mcwsn-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice maintaining a conversation with someone new. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to practice maintaining a conversation with someone new.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6Om1haW50YWluaW5nIGNvbnZlcnNhdGlvbnMgd2l0aCBzb21lb25lIG5ldw=="
      },
      {
        id: 4,
        exercise: 62,
        category: "Social skills",
        title: "Starting conversations with someone familiar",
        type: 'journal',
        text: "Practice starting a conversation with a familiar person",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can start a conversation with someone familiar this week?",
              placeholder: "e.g., asking a friend how their day was"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "scwsf-hw",
              preview: "scwsf-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice starting a conversation with someone familiar. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to practice starting a conversation with someone familiar.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6OnN0YXJ0aW5nIGNvbnZlcnNhdGlvbnMgd2l0aCBzb21lb25lIGZhbWlsaWFy"
      },
      {
        id: 5,
        exercise: 63,
        category: "Social skills",
        title: "Maintaining conversations with someone familiar",
        type: 'journal',
        text: "Practice keeping a conversation going with a familiar person",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can maintain a conversation with someone familiar this week?",
              placeholder: "e.g., talking with a friend about each other's work day"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "mcwsf-hw",
              preview: "mcwsf-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice maintaining a conversation with someone familiar. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to practice maintaining a conversation with someone familiar.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6Om1haW50YWluaW5nIGNvbnZlcnNhdGlvbnMgd2l0aCBzb21lb25lIGZhbWlsaWFy"
      },
      {
        id: 6,
        exercise: 64,
        category: "Social skills",
        title: "Inviting others to join an activity",
        type: 'journal',
        text: "Practice inviting someone to do an activity",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What's an activity where <b>{name}</b> can invite others to join this week?",
              placeholder: "e.g., roller skating"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "eamf-iotj-hw",
              preview: "eamf-iotj-r",
              inputs: {
                activity: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice inviting others to join an activity. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to practice inviting others to join an activity.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6Omludml0aW5nIG90aGVycyB0byBqb2luIGFuIGFjdGl2aXR5"
      },
      {
        id: 7,
        exercise: 65,
        category: "Social skills",
        title: "Asking others to change their behavior",
        type: 'journal',
        text: "Practice asking someone to change their behavior",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can ask others to change their behavior this week?",
              placeholder: "e.g., asking someone to stop making inappropriate comments"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "aotchtb-hw",
              preview: "aotctb-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice asking others to change their behavior. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to practice asking others to change their behavior.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6OmFza2luZyBvdGhlcnMgdG8gY2hhbmdlIHRoZWlyIGJlaGF2aW9y"
      },
      {
        id: 8,
        exercise: 66,
        category: "Social skills",
        title: "Being positively assertive",
        type: 'journal',
        text: "Practice accepting or giving praise and compliments",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can be positively assertive this week?",
              placeholder: "e.g., praise someone's cooking"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "bepa-hw",
              preview: "bepa-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice being positively assertive. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to practice being positively assertive.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6OmJlaW5nIHBvc2l0aXZlbHkgYXNzZXJ0aXZl"
      },
      {
        id: 9,
        exercise: 67,
        category: "Social skills",
        title: "Interacting with authority figures",
        type: 'journal',
        text: "Practice interacting with an authority figure",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can interact with authority figures this week?",
              placeholder: "e.g., setting boundaries with your parents"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "iwaf-hw",
              preview: "iwaf-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice interacting with authority figures. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to practice interacting with authority figures.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6OmludGVyYWN0aW5nIHdpdGggYXV0aG9yaXR5IGZpZ3VyZXM="
      },
      {
        id: 10,
        exercise: 79,
        category: "Social skills",
        title: "Declining unreasonable requests",
        type: 'journal',
        text: "Practice declining an unreasonable request",
        audience: [
          "adult",
          "child"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "remind",
            params: {
              title: "What's a situation where <b>{name}</b> can decline an unreasonable requests this week?",
              placeholder: "e.g., saying you can't do all the chores in the house"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "dureqs-hw",
              preview: "dureqs-r",
              inputs: {
                situation: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to practice declining unreasonable requests. Click here to start: {link}",
                email: "Hi {name}! <br><br>                           It's time for you to practice declining unreasonable requests.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTI6OmRlY2xpbmluZyB1bnJlYXNvbmFibGUgcmVxdWVzdHM="
      }
    ]
  },
  {
    id: 13,
    title: "Journals",
    image: "journal.svg",
    subtitle: "Remind <b>{name}</b> to complete a...",
    description: "Journaling exercises to help clients understand thoughts and experiences more clearly",
    items: [
      {
        id: 1,
        exercise: 72,
        category: "Journals",
        title: "Gratitude Journal",
        type: 'interview',
        text: "Document things clients are grateful for",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "gratjour",
              preview: "gratjour"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to fill out a gratitude journal. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time for you to fill out a gratitude journal.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "19:00"
          ]
        },
        uid: "MTM6OmdyYXRpdHVkZSBqb3VybmFs"
      },
      {
        id: 2,
        exercise: 73,
        category: "Journals",
        title: "Self-Esteem Journal",
        type: 'interview',
        text: "Document positive attributes and thoughts",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "sejour",
              preview: "sejour"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time for you to fill out a self-esteem journal. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time for you to fill out a self-esteem journal.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "19:00"
          ]
        },
        uid: "MTM6OnNlbGYtZXN0ZWVtIGpvdXJuYWw="
      }
    ]
  },
  {
    id: 14,
    title: "Activity scheduling",
    image: "activity-scheduling.svg",
    subtitle: "Plan an activity for <b>{name}</b> in the life area of...",
    description: "Exercises to help clients increase engagement in enjoyable/important activities",
    items: [
      {
        id: 1,
        exercise: 76,
        category: "Activity scheduling",
        title: "Relationships",
        type: 'journal',
        text: "Life area that involves family, friends, and/or significant others",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "choice2",
            params: {
              title: "What's something <b>{name}</b> values in their relationships?",
              items: [
                {
                  id: 1,
                  title: "Being a good partner/spouse",
                  value: "being a good partner/spouse",
                  example: "e.g., take your partner on a date"
                },
                {
                  id: 2,
                  title: "Being a good friend",
                  value: "being a good friend",
                  example: "e.g., ask a friend to hang out"
                },
                {
                  id: 3,
                  title: "Being a good parent",
                  value: "being a good parent",
                  example: "e.g., play a game with your child"
                },
                {
                  id: 4,
                  title: "Something else",
                  value: "something else",
                  example: "e.g., taking your dog to the park",
                  example2: "e.g., being a good pet owner"
                }
              ]
            }
          },
          {
            name: "remind",
            params: {
              title: "What's an activity <b>{name}</b> can do this week that aligns with <span>{choiceValue}</span>?",
              placeholder: "{choiceExample}"
            }
          },
          {
            name: "remind2",
            params: {
              title: "What does <b>{name}</b> value that relates to <span>{remind}</span>?",
              placeholder: "{choiceExample2}"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "barel",
              preview: "barel-r",
              inputs: {
                valuerelation: "{choiceValue}",
                relationactivity: "{remind}"
              }
            }
          },
          {
            name: "dokbot2",
            params: {
              previewMode: true,
              activity: "barel",
              preview: "barel-r",
              inputs: {
                valuerelation: "{remind2}",
                relationactivity: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to talk about {remind}. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to talk about {remind}.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          // { // TODO: In case we need to display custom category for custom selection in message
          //   name: "summary2",
          //   params: {
          //     messages: {
          //       sms: "Hi {name}! It's time to talk about {choiceValue}s. Click here to start: {link}",
          //       email: "Hi {name}! <br><br>                          It's time to talk about {choiceValue}s.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
          //     }
          //   }
          // },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTQ6OnJlbGF0aW9uc2hpcHM="
      },
      {
        id: 2,
        exercise: 75,
        category: "Activity scheduling",
        title: "Education/Career",
        type: 'journal',
        text: "Life area that involves spending time improving one’s education and/or career",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "choice2",
            params: {
              title: "What's something <b>{name}</b> values related to their education or career?",
              items: [
                {
                  id: 1,
                  title: "Getting a formal education",
                  value: "getting a formal education",
                  example: "e.g., talk to someone about how to enroll in a course"
                },
                {
                  id: 2,
                  title: "Being knowledgeable about the world",
                  value: "being knowledgeable about the world",
                  example: "e.g., watch an educational YouTube video"
                },
                {
                  id: 3,
                  title: "Job performance and satisfaction",
                  value: "job performance and satisfaction",
                  example: "e.g., set a work-related goal"
                },
                {
                  id: 4,
                  title: "Learning new skills",
                  value: "learning new skills",
                  example: "e.g., participate in a training program"
                },
                {
                  id: 5,
                  title: "Something else",
                  value: "something else",
                  example: "e.g., go to a career-related event",
                  example2: "e.g., opportunities and networking"
                }
              ]
            }
          },
          {
            name: "remind",
            params: {
              title: "What's an activity <b>{name}</b> can do this week that aligns with <span>{choiceValue}</span>",
              placeholder: "{choiceExample}"
            }
          },
          {
            name: "remind2",
            params: {
              title: "What does <b>{name}</b> value that relates to <span>{remind}</span>?",
              placeholder: "{choiceExample2}"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "baeducar",
              preview: "baedu-r",
              inputs: {
                valueeducar: "{choiceValue}",
                educaractivity: "{remind}"
              }
            }
          },
          {
            name: "dokbot2",
            params: {
              previewMode: true,
              activity: "baeducar",
              preview: "baedu-r",
              inputs: {
                valueeducar: "{remind2}",
                educaractivity: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to talk about {remind}. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to talk about {remind}.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTQ6OmVkdWNhdGlvbi9jYXJlZXI="
      },
      {
        id: 3,
        exercise: 77,
        category: "Activity scheduling",
        title: "Mind/Body/Spirituality",
        type: 'journal',
        text: "Life area that involves physical and mental health and/or religion/spirituality",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "choice2",
            params: {
              title: "What's something <b>{name}</b> values related to their mind, body, and/or spirituality?",
              items: [
                {
                  id: 1,
                  title: "Mental health",
                  value: "mental health",
                  example: "e.g., meditate"
                },
                {
                  id: 2,
                  title: "Being physically healthy",
                  value: "being physically healthy",
                  example: "e.g., go on a walk"
                },
                {
                  id: 3,
                  title: "Engaging in their spirituality",
                  value: "engaging in their spirituality",
                  example: "e.g., pray"
                },
                {
                  id: 4,
                  title: "Something else",
                  value: "something else",
                  example: "e.g., set limits on social media use",
                  example2: "e.g., overcoming an addiction"
                }
              ]
            }
          },
          {
            name: "remind",
            params: {
              title: "What's an activity <b>{name}</b> can do this week that aligns with <span>{choiceValue}</span>?",
              placeholder: "{choiceExample}"
            }
          },
          {
            name: "remind2",
            params: {
              title: "What does <b>{name}</b> value that relates to <span>{remind}</span>?",
              placeholder: "{choiceExample2}"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "bambs",
              preview: "bamind-r",
              inputs: {
                valuemind: "{choiceValue}",
                mindactivity: "{remind}"
              }
            }
          },
          {
            name: "dokbot2",
            params: {
              previewMode: true,
              activity: "bambs",
              preview: "bamind-r",
              inputs: {
                valuemind: "{remind2}",
                mindactivity: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to talk about {remind}. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to talk about {remind}.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTQ6Om1pbmQvYm9keS9zcGlyaXR1YWxpdHk="
      },
      {
        id: 4,
        exercise: 78,
        category: "Activity scheduling",
        title: "Recreation/Interests",
        type: 'journal',
        text: "Life area that involves leisure time, having fun, and/or relaxing",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "choice2",
            params: {
              title: "What's something <b>{name}</b> values in their recreation or interests?",
              items: [
                {
                  id: 1,
                  title: "Engaging in a hobby",
                  value: "engaging in a hobby",
                  example: "e.g., draw something"
                },
                {
                  id: 2,
                  title: "Going outside",
                  value: "going outside",
                  example: "e.g., go hiking"
                },
                {
                  id: 3,
                  title: "Helping others",
                  value: "helping others",
                  example: "e.g., volunteering at a food pantry"
                },
                {
                  id: 4,
                  title: "Something else",
                  value: "something else",
                  example: "e.g., listen to relaxing music",
                  example2: "e.g., enjoy a relaxing activity"
                }
              ]
            }
          },
          {
            name: "remind",
            params: {
              title: "What's an activity <b>{name}</b> can do this week that aligns with <span>{choiceValue}</span>?",
              placeholder: "{choiceExample}"
            }
          },
          {
            name: "remind2",
            params: {
              title: "What does <b>{name}</b> value that relates to <span>{remind}</span>?",
              placeholder: "{choiceExample2}"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "bari",
              preview: "barec-r",
              inputs: {
                valueinterest: "{choiceValue}",
                interestactivity: "{remind}"
              }
            }
          },
          {
            name: "dokbot2",
            params: {
              previewMode: true,
              activity: "bari",
              preview: "barec-r",
              inputs: {
                valueinterest: "{remind2}",
                interestactivity: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to talk about {remind}. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to talk about {remind}.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTQ6OnJlY3JlYXRpb24vaW50ZXJlc3Rz"
      },
      {
        id: 5,
        exercise: 74,
        category: "Activity scheduling",
        title: "Daily Responsibilities",
        type: 'journal',
        text: "Life area that involves taking care of belongings and obligations and responsibilities to others",
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "choice2",
            params: {
              title: "What's something <b>{name}</b> values related to daily responsibilities?",
              items: [
                {
                  id: 1,
                  title: "Being dependable",
                  value: "being dependable",
                  example: "e.g., be on time to work"
                },
                {
                  id: 2,
                  title: "Taking care of belongings",
                  value: "taking care of belongings",
                  example: "e.g., fix something in the house"
                },
                {
                  id: 3,
                  title: "Being organized",
                  value: "being organized",
                  example: "e.g., make a task list for the next day"
                },
                {
                  id: 4,
                  title: "Having good hygiene",
                  value: "having good hygiene",
                  example: "e.g., go to sleep early"
                },
                {
                  id: 5,
                  title: "Something else",
                  value: "something else",
                  example: "e.g., work on a personal project",
                  example2: "e.g., achieving a daily goal"
                }
              ]
            }
          },
          {
            name: "remind",
            params: {
              title: "What's an activity <b>{name}</b> can do this week that aligns with <span>{choiceValue}</span>?",
              placeholder: "{choiceExample}"
            }
          },
          {
            name: "remind2",
            params: {
              title: "What does <b>{name}</b> value that relates to <span>{remind}</span>?",
              placeholder: "{choiceExample2}"
            }
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "badr",
              preview: "badaily-r",
              inputs: {
                valuedaily: "{choiceValue}",
                dailyactivity: "{remind}"
              }
            }
          },
          {
            name: "dokbot2",
            params: {
              previewMode: true,
              activity: "badr",
              preview: "badaily-r",
              inputs: {
                valuedaily: "{remind2}",
                dailyactivity: "{remind}"
              }
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to talk about {remind}. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to talk about {remind}.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "once",
          times: [
            "12:00"
          ],
          relativeNow: 24
        },
        uid: "MTQ6OmRhaWx5IHJlc3BvbnNpYmlsaXRpZXM="
      }
    ]
  },
  {
    id: 15,
    title: "Sleep",
    image: "sleep.svg",
    subtitle: "Remind <b>{name}</b> to complete...",
    description: "Monitor sleep from the previous night",
    timesLimit: 1,
    reminderType: "sleeplog",
    items: [
      {
        id: 1,
        exercise: 81,
        category: "Sleep",
        title: "Sleep log",
        text: "Monitor sleep from the previous night",
        type: "interview",
        shortName: "Sleep log",
        maxScore: 100,
        chartYAxisSteps: 20,
        audience: [
          "adult"
        ],
        workflow: [
          {
            name: "activity"
          },
          {
            name: "dokbot",
            params: {
              previewMode: true,
              activity: "sleeplog",
              preview: "sleeplog"
            }
          },
          {
            name: "name"
          },
          {
            name: "channel",
            params: {
              title: "How does <b>{name}</b> want to be reminded?"
            }
          },
          {
            name: "contact"
          },
          {
            name: "summary",
            params: {
              messages: {
                sms: "Hi {name}! It's time to do your sleep log. This will only take a few minutes. Click here to start: {link}",
                email: "Hi {name}! <br><br>                          It's time to do your sleep log. This will only take a few minutes.<br>                          Click here to start: <a href=\"{link}\" target=\"_blank\">{link}</a>"
              }
            }
          },
          {
            name: "scheduled"
          }
        ],
        notificationConfig: {
          type: "daily",
          times: [
            "09:00"
          ]
        },
        uid: "MTU6OnNsZWVwIGxvZw==",
        isPractice: false
      }
    ]
  }
]