export default function authAction({ next, to }) {
  switch (to.query.mode) {
    case 'resetPassword':
      next({
        name: 'authActionResetPassword',
        query: {
          oobCode: to.query.oobCode
        }
      })
      break

    case 'verifyEmail':
      next({
        name: 'authActionVerifyEmail',
        query: {
          oobCode: to.query.oobCode
        }
      })
      break

    default:
      next({ name: 'signIn' })
  }
}
