export default {}

export const mapCollateTo = (a, b, aId, bId, to, def = []) => {
  const indexed = index(b, bId)

  return a.map(ai => {
    ai[to] = indexed[ai[aId]] || def
    return ai
  })
}

// Array.prototype.unique = function() {
//   var a = this.concat();
//   for(var i=0; i<a.length; ++i) {
//       for(var j=i+1; j<a.length; ++j) {
//           if(a[i] === a[j])
//               a.splice(j--, 1);
//       }
//   }

//   return a;
// }

export const mapObjectValue = (names, value) =>
  Object.fromEntries(names.map(k => [k, value]))

export const indexs = (subject, fields) => {
  return subject.reduce((a, c) => {
    return pushForce(a, fields.map(f => c[f]).join(), c)
  }, {})
}

export const index = (subject, field) => {
  return subject.reduce((a, c) => {
    return pushForce(a, c[field], c)
  }, {})
}

export const orderBy = (by = 'order', k = 1, a, b) => {
  return (a[by] < b[by] ? 1 : -1) * k
}

export const groupBy = (arr, fields) => {
  const field = fields[0]
  if (!field) return arr
  const retArr = Object.values(
    arr.reduce((obj, current) => {
      if (!obj[current[field]])
        obj[current[field]] = {
          name: current[field],
          items: []
        }
      obj[current[field]].items.push(current)
      return obj
    }, {})
  )
  if (fields.length) {
    retArr.forEach(obj => {
      obj.items = groupBy(obj.items, fields.slice(1))
    })
  }
  return retArr
}

export const preferredIndex = list => {
  const { index } = list.reduce(
    (a, c, i) => {
      if (c.items.length >= a.max) {
        a.max = c.items.length
        a.index = i
      }
      return a
    },
    { index: 0, max: 0 }
  )

  return index
}

export const pushForce = (subject, key, value) => {
  if (undefined === subject[key]) {
    subject[key] = []
  }
  subject[key].push(value)

  return subject
}

export const maxIndex = (i, max) => (i > max ? max : i)
export const colorIndex = [0, 1, 2, 3, 1, 2, 3, 2, 3, 3]

export function searchClosest(arr, target, lo = 0, hi = arr.length - 1) {
  if (target < arr[lo]) {
    return arr[0]
  }
  if (target > arr[hi]) {
    return arr[hi]
  }

  const mid = Math.floor((hi + lo) / 2)

  return hi - lo < 2
    ? target - arr[lo] < arr[hi] - target
      ? arr[lo]
      : arr[hi]
    : target < arr[mid]
    ? searchClosest(arr, target, lo, mid)
    : target > arr[mid]
    ? searchClosest(arr, target, mid, hi)
    : arr[mid]
}

export function sortArrays(arrays, comparator = (a, b) => (a < b) ? -1 : (a > b) ? 1 : 0) {
  let arrayKeys = Object.keys(arrays);
  let sortableArray = Object.values(arrays)[0];
  let indexes = Object.keys(sortableArray);
  let sortedIndexes = indexes.sort((a, b) => comparator(sortableArray[a], sortableArray[b]));

  let sortByIndexes = (array, sortedIndexes) => sortedIndexes.map(sortedIndex => array[sortedIndex]);

  if (Array.isArray(arrays)) {
    return arrayKeys.map(arrayIndex => sortByIndexes(arrays[arrayIndex], sortedIndexes));
  } else {
    let sortedArrays = {};
    arrayKeys.forEach((arrayKey) => {
      sortedArrays[arrayKey] = sortByIndexes(arrays[arrayKey], sortedIndexes);
    });
    return sortedArrays;
  }
}
