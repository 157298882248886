export default function isAdmin({ next, store, to }) {
  if (
    store.getters['authentication/isUserLoggedIn'] &&
    to.meta.role === 'admin' &&
    !store.getters['authentication/isAdmin']
  ) {
    return next({ name: 'dashboard' })
  }

  next()
}
