<script>
import { mapActions } from 'vuex'
import Style from '@/mixins/Style'

export default {
  mixins: [Style],
  watch: {
    $route() {
      this.setBodyClass()
    }
  },
  beforeMount() {
    this.setBodyClass()
  },
  mounted() {
    this.setStyle()
  },
  async created() {
    await this.fetchTid()
    await this.fetchClient()
  },
  methods: {
    ...mapActions('clients', ['fetchClient']),
    ...mapActions('authentication', ['fetchTid']),
    setBodyClass() {
      document.body.className = this.$route.meta.bodyClass || ''
    }
  }
}
</script>
