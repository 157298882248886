import authenticatedSignin from '@/router/middleware/authenticatedSignin'

export default [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('@/views/Home.vue'),
  //   meta: {
  //     bodyClass: 'home-page watermark',
  //     middleware: [authenticatedSignin],
  //   }
  // },
  {
    path: '/',
    name: 'signIn',
    component: () => import('@/views/auth/SignIn.vue'),
    meta: { middleware: [authenticatedSignin] }
  },
  {
    path: 'relaxation/:type/:id',
    name: 'relaxation',
    component: () => import('@/views/Relaxation.vue')
  },
  {
    path: 'contact-us',
    name: 'contactUs',
    component: () => import('@/views/ContactUs.vue')
  },
  {
    path: 'privacy-policy',
    name: 'privacyPolicy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    meta: {
      bodyClass: 'background-white'
    }
  },
  {
    path: 'terms-of-use',
    name: 'termsOfService',
    component: () => import('@/views/TermsOfService.vue'),
    meta: {
      bodyClass: 'background-white'
    }
  },
  
]
