<template>
  <v-list-item
    :key="key"
    :class="{ 'v-list-item--active': isActive }"
    @click="handleClick(item)"
  >
    <v-list-item-icon
      :is="item.icon"
      v-if="item.icon"
    />
    <v-list-item-content>
      <v-list-item-title v-text="item.text" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Menu from '@/mixins/Menu'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardMenuItem',
  mixins: [Menu],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      key: 1
    }
  },
  computed: {
    ...mapGetters({
      activity: 'clients/activity'
    }),
    isActive() {
      const routes = this.item.routes || [this.item.route]

      if (this.$route.name === 'activityShow') {
        return this.item.slug ? this.item.slug === this.activity?.reminderType : false
      }
      
      if (!routes.includes(this.$route.name)) {
        return false
      } else if (this.item.route === 'dashboard') {
        return this.$route.name === 'dashboard'
      } else if (this.item.route === 'review') {
        return this.$route.name === 'review'
      } else {
        return this.item.slug === this.$route.params.slug
      }
    }
  },
  methods: {
    handleClick(item) {
      this.clickItemHandler(item)
      this.key++
    }
  },
}
</script>
