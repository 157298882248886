import defaultState from './state'
import cloneDeep from 'lodash.clonedeep'

export default {
  setCustomMessage: (state, item) => {
    state.customMessage = item
  },
  setFilter: (state, item) => {
    state.filter = item
  },
  setItem: (state, item) => {
    Object.assign(state, { item })
  },
  updateItem: (state, item) => {
    const clone = cloneDeep(state.item)
    state.item = Object.assign({}, clone, item)
  },
  updateEditType: (state, item) => {
    state.editType = item
  },
  updateNotificationType: (state, type) => {
    state.item.activity.notificationConfig.type = type
  },
  resetItem: state => {
    Object.assign(state.item, defaultState().item)
  },
  setSession: (state, session) => {
    state.session = session
  },
  reset: state => {
    Object.assign(state, defaultState())
  },
  updateRecurrence: (state, recurrence) => {
    state.recurrence = Object.assign({}, recurrence)
  },
  // TODO: Invoke this mutation when a practice exercise is being created
  updateReminder: (state, activity) => {
    state.item.activity = activity
  }
}
