import moment from '@/plugins/moment'

export const days = (isDaily) => {
  const items = []
  const day = isDaily ? moment().add(1, 'days') : moment()

  for (let i = 0; i < 7; i++) {
    items.push(day.add(i, 'day').format('YYYY-MM-DD'))
  }

  return items
}

export const times = () => {
  return [
    '07:00',
    '08:00',
    '09:00',
    '11:00',
    '12:00',
    '13:00',
    '18:00',
    '19:00',
    '20:00'
  ]
}

export const hasDay = (date, isDaily) => !!days(isDaily).find(item => item === date)
export const hasTime = time => !!times().find(item => item === time)

export const fromDokbotTime = (time, format24Hour = false) => {
  let hour = parseInt(time.h, 10);
  if (format24Hour) {
    // Convert to 24-hour format for calculation purposes
    if (time.A === 'PM' && hour < 12) {
      hour += 12;
    } else if (time.A === 'AM' && hour === 12) {
      hour = 0;
    }
    return `${hour.toString().padStart(2, '0')}:${time.mm.padStart(2, '0')}`;
  } else {
    // Return in 12-hour format with AM/PM
    return `${time.h.padStart(2, '0')}:${time.mm.padStart(2, '0')} ${time.A}`;
  }
}

export const minsToHrs = (mins) => `${mins/60|0} hrs ${mins % 60} mins`

export const fromDokbotTimeDiffMins = (a, b, addDay = false) => {
  a = moment(fromDokbotTime(a, true), 'HH:mm');
  b = moment(fromDokbotTime(b, true), 'HH:mm');

  // If a is before b, then a is on the next day
  if (addDay && a.isBefore(b)) {
    a = a.add(1, 'day');
  }

  return a.diff(b, 'minutes');
}

export const periodGenerator = (
  from,
  fromFormat,
  duration,
  durationUnit,
  interval,
  intervalUnit,
  resultFormat
) => {
  let start = moment(from, fromFormat)

  const end = moment(start).add(duration, durationUnit)
  const result = []

  do {
    result.push(moment(start).format(resultFormat))
    start = moment(start).add(interval, intervalUnit)
  } while (moment(start).isBefore(end))

  return result
}
