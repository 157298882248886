import firebase from '@/firebase'
import moment from '@/plugins/moment'
import { PLANNED, SENT } from '@/kit/dictionaries/timesSendStatus'

export default {
  reset({ dispatch, commit }) {
    commit('resetItem')
    dispatch('clients/resetItem', null, { root: true })
    dispatch('clients/mapClientToItem', null, { root: true })
  },
  /**
   * 
   * @param {Object} ctx, 
   *   the contents of a component (this) 
   * @param {Object} data, {
   *   dokbotData: data,
   *   type: 'dokbot',
   *   progress: 100,
   *   duration: this.durarion() (???)
   * } 
   */
  async sessionProgress({ commit, rootGetters, getters, dispatch }, [ctx, data]) {
    data.progress = data.progress || ctx.progress || 0
    const completeOn = ctx.completeOn || 100

    const passed = data.timePassed || data.stepsPassed
    const count = data.duration || data.stepsCount
    if (passed && count) {
      data.progress = Math.floor((passed * 100) / count)
    }
    data.progress = data.progress < 100 ? data.progress : 100
    data['started'] = data.progress > 0

    if (data.progress > 0) {
      window.addEventListener('beforeunload', function() {
        firebase.analytics.logEvent('adhere.ly:inProgress')
      })
    }

    data['type'] = ctx.type || 'default'

    const isPreview =
      ctx?.isPreviewMode && rootGetters['clients/haveChosenClient']
    if (isPreview) {
      commit('updateItem', {
        previewProgress: data.progress
      })
    }

    let session
    if (getters.session) {
      session = firebase
        .firestore()
        .collection('sessions')
        .doc(getters.session)
    }

    if (undefined === ctx.isCompleted && data.progress >= completeOn) {
      firebase.analytics.logEvent('adhere.ly:completed')
      ctx.isCompleted = true
      data['completed'] = true
      data['completedAt'] = moment()
        .utc()
        .toDate()

      if (isPreview) {
        commit('updateItem', {
          previewCompleted: true
        })
        const eventData = {
          eventName: 'Activity Completed',
          type: 'Preview',
          duration: data.duration,
          completed: data.completed,
          started: data.started,
          isPreviewMode: ctx.isPreviewMode ?? false
        }
        await dispatch('analytics/segmentTrack', eventData, { root: true })
      } else {
        const eventData = {
          eventName: 'Activity Completed',
          duration: data.duration,
          completed: data.completed,
          started: data.started,
          isPreviewMode: ctx.isPreviewMode ?? false
        }
        await dispatch('analytics/segmentTrack', eventData, { root: true })
      }

      if (data?.dokbotData) {
        if (data.dokbotData?.consultation?.data) {
          data['data'] = data.dokbotData.consultation.data
        } else {
          if (data.dokbotData?.data) {
            data['data'] = data.dokbotData.data
          }
        }

        if (undefined !== data?.data?.Score) {
          // Fix for insomnia index dokbot
          data.data['score'] = data.data.Score
        }

        data.data['options'] = data.dokbotData?.steps?.map(s =>
          ({
            ...s.options,
            title: s.handlers[0].value
          })
        )

        // console.log(data['data'], ctx.session.id)
        delete data.dokbotData
      }
    }

    await session?.update(data)
  },
  async sessionStart({ dispatch, commit, rootGetters }, ctx) {

    const postInSessionResultsToReview = {
      'assess': true,
      'practice': false
    }
    
    if (
      rootGetters['clients/isPractice'] &&
      postInSessionResultsToReview[ctx.scope.reminderType] &&
      !ctx.notificationId
    ) {
      // This will ensure that a notificationId is always created even for practice exercises
      const { timeId, scheduleId } = await dispatch('schedule', ctx)
      ctx.notificationId = timeId
      commit('updateItem', {
        practiceId: scheduleId
      })
    }

    if (ctx?.isPreviewMode && rootGetters['clients/haveChosenClient']) {
      commit('updateItem', {
        previewInitiated: true
      })
    }

    if (ctx.notificationId) {
      const progress = ctx.progress || 0
      const duration = ctx.duration || 0
      const session = await firebase
        .firestore()
        .collection('sessions')
        .add({
          time: ctx.notificationId,
          type: ctx.type || null,
          workflowId: ctx.workflowId || null,
          isPreviewMode: ctx.isPreviewMode || false,
          timeRef: firebase
            .firestore()
            .collection('times')
            .doc(ctx.notificationId),
          progress,
          duration,
          createdAt: moment()
            .utc()
            .toDate(),
          started: false
        })

      await commit('setSession', session.id)
      
      // console.log(
      //   'SESSION STARTED, THE LINK:',
      //   `https://console.firebase.google.com/u/0/project/adherely-dev/firestore/data~2Fsessions~2F${ctx.session.id}`
      // )
    } else {
      const eventData = {
        eventName: 'Activity Started',
        isPreviewMode: ctx.isPreviewMode ?? false,
        type: 'In-session'
      }
      await dispatch('analytics/segmentTrack', eventData, { root: true })
    }
    
  },
  async schedule({ rootGetters, dispatch }, context) {
    const practice = rootGetters['clients/isPractice']
    const isPreviewMode = context.isPreviewMode || false
    const scope = context.scope || rootGetters['schedules/item']
    // console.log('schedule isPreviewMode and practice', isPreviewMode, practice)

    const reminderData = {
      ...scope,
      createdAt: firebase.firestore.Timestamp.now(),
      isPreviewMode,
      utcOffset: moment().utcOffset(),
      practice
    }

    let sendStatus = reminderData.phone !== '1111111111111' ? PLANNED : SENT

    if (practice) {
      reminderData.date = [moment().format('YYYY-MM-DD')]
      reminderData.time = [moment().format('HH:mm')]
      sendStatus = SENT
    }

    if (null === reminderData.clientId) {
      reminderData.clientId = rootGetters['clients/client'].id
      reminderData.clientName = rootGetters['clients/client'].name
    }

    Object.keys(reminderData).forEach(
      key => reminderData[key] === undefined && delete reminderData[key]
    )

    if (rootGetters['authentication/isUserLoggedIn']) {
      reminderData.createdBy = rootGetters['authentication/user'].id
    } else {
      reminderData.tid = rootGetters['authentication/tid']
      reminderData.createdBy = null
    }

    // Remove subtitle from reminderData
    delete reminderData.activity.subtitle

    // Remove other undefined activity attributes if the activity is an assessment
    if (reminderData.reminderType === 'assess') {
      if (!reminderData.activity?.uid) delete reminderData.activity.uid
      if (!reminderData.activity?.exercise) delete reminderData.activity.exercise
    }

    // TODO: Remove what undefined attribute that Firebase throws a tantrum about

    const reminderRef = firebase
      .firestore()
      .collection('schedules')
      .doc()
    const batch = firebase.firestore().batch()
    batch.set(reminderRef, reminderData)

    let timeId = null
    reminderData.date.forEach(itemDate => {
      reminderData.time.forEach(itemTime => {
        firebase.analytics.logEvent('adhere.ly:notification:created')
        const notificationRef = firebase
          .firestore()
          .collection('times')
          .doc()
        const dateCreated = moment(
          itemDate + ' ' + context.formatTimeUtcToLocal(itemTime, 'HH:mm:ss'),
          'YYYY-MM-DD HH:mm:ss'
        ).add(
          reminderData.activity.notificationConfig.relativeDate || 0,
          'hour'
        )

        batch.set(notificationRef, {
          schedule: reminderRef,
          sendTime: firebase.firestore.Timestamp.fromDate(
            dateCreated.utc().toDate()
          ),
          nextAppointmentDate: itemDate,
          nextAppointmentTime: context.formatTimeUtcToLocal(itemTime),
          sendStatus
        })
        timeId = notificationRef?.id
      })
    })

    if (undefined !== reminderData.practiceId) {
      batch.update(
        firebase
          .firestore()
          .collection('schedules')
          .doc(reminderData.practiceId),
        {
          practiceReminded: true,
          practiceProgress: reminderData.practiceProgress || 0,
          practiceCompleted: reminderData.practiceCompleted || false
        }
      )
    }

    await batch
      .commit()
      .then(async() => {
        context.success = true
        const analytic = {
          eventName: 'Activity Scheduled',
          reminderCategory: reminderData.reminderCategory,
          reminderTitle: reminderData.reminderTitle,
          activityTitle: reminderData.activity.title,
          reminderType: reminderData.reminderType
        }
        firebase.analytics.logEvent('adhere.ly:scheduled', analytic)

        await dispatch('analytics/segmentTrack', analytic, { root: true })

        if (reminderData.isUserLoggedIn && 0 === reminderData.id) {
          context.saveClient(reminderData).then(client => {
            reminderRef.update({
              clientId: client.id,
              clientName: client.name
            })
            context.updateItem({
              ...client,
              channel: { key: client.channel }
            })
          })
        }
      })
      .catch(() => {
        context.success = false
      })
      .finally(() => {
        context.loaded = true
      })

    return {
      timeId: timeId,
      scheduleId: reminderRef.id
    }
  },
  // used when soft deleting a client
  // remove all scheduled activities so no reminders get sent
  async deleteScheduledActivities({ getters }, context) {
    const uid = context.uid
    await firebase
      .firestore()
      .collection('schedules')
      .where('clientId', '==', uid)
      .get()
      .then((docs) => {
        docs.forEach(doc => {
          doc.ref.delete()
        })
      })
  }
}
