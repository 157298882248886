function middlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) {
    return context.next
  }

  return async () => {
    const nextPassValue = await new Promise((resolve) => {
      // Not all middleware will be async, but this should handle the ones that are
      nextMiddleware({ ...context, next: resolve });
    })
    
    const nextPipeline = middlewarePipeline(context, middleware, index + 1)
    await nextPipeline(nextPassValue);
  }
}

export default middlewarePipeline