/**
 * Events to track:
 * Activity Completed
 * Activity Scheduled
 * Activity Started
 * Client Added
 * Sign In
 * Sign In (with doxy.me)
 * Sign Up
 * Sign Up Complete
 * Site View
 * Review Screen
 * Sign up (date)
 * Viewing Practice Page
 */
export default {
    // segment analytics
    segmentIdentify({rootState}) {
      const user = rootState.authentication.user
      const fullName = user.first_name + ' ' + user.last_name
      const analytic = {
        providerName: fullName,
        providerEmail: user.email,
        created: new Date().toString(),
      }
      window.analytics.identify(user.id, analytic)
    },
  
    segmentTrack({ rootState }, payload) {
      if (window.location.hostname !== 'app.adhere.ly') {
        return
      }
      try {
        const { eventName } = payload
        const authUser = rootState.authentication.user
        let user = null
        if (authUser) {
          const fullName = authUser.first_name + ' ' + authUser.last_name
          user = {
            providerName: fullName,
            providerEmail: authUser.email,
            id: authUser.id,
          }
        }
        const mergedData = {...payload, ...user, datetime: new Date().toString()}
        window?.analytics.track(eventName, mergedData)
      } catch(e) {
        console.error('Error posting analytics to Segment: ', e)
      }
    },
}