export default () => {
  return {
    client: {
      activity: null,
      dob: null,
      channel: null,
      caregiverAsPatient: false,
      caregiverDob: null,
      caregiverEmail: null,
      caregiverEthnicity: null,
      caregiverGender: null,
      caregiverName: null,
      caregiverPhone: null,
      caregiverRace: null,
      email: null,
      ethnicity: null,
      gender: null,
      id: null,
      name: null,
      phone: null,
      race: null,
      smsWelcomeMsgSent: false
    }
  }
}
