<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    floating
    class="navigation-drawer"
    width="var(--dashboard-width)"
    color="neutralLight"
    absolute
    temporary
  >
    <aside>
      <div
        :class="[
          'navigation-drawer-choose-patient',
          { selected: haveChosenClient }
        ]"
        @click="choosePatientClickHandler"
      >
        <div class="navigation-drawer-choose-patient-helper pa-6">
          <div class="choose-patient d-flex align-left px-3 py-2">
            <h3 v-if="haveChosenClient">
              Selected client
            </h3>
            <div class="choose-patient-name my-1">
              <template v-if="haveChosenClient">
                {{ client.name }}
              </template>
              <template v-else>
                Select {{ clientPhrase }}
              </template>
              <v-btn
                v-if="haveChosenClient && !isLocked"
                class="choose-patient-edit"
                x-small
                icon
              >
                <v-icon
                  color="neutralGrayText"
                  small
                >
                  {{ mdiPencil }}
                </v-icon>
              </v-btn>
              <v-btn
                v-if="haveChosenClient && !isLocked"
                class="choose-patient-remove"
                color="neutralGrayText"
                small
                icon
                @click.stop="resetClient"
              >
                <v-icon
                  color="black"
                  small
                >
                  {{ mdiClose }}
                </v-icon>
              </v-btn>
            </div>
            <!-- <div class="choose-patient-avatar" /> -->
          </div>
        </div>
      </div>
      <dashboard-menu
        v-if="menu.length"
        :menu="menu"
        menu-class="px-6 py-0 menu-navigation-drawer"
      />
    </aside>

    <div class="navigation-drawer-bottom">
      <div
        v-if="!hasActiveSubscription"
        class="ma-6 navigation-drawer-add-patient text-left"
      >
        <router-link
          :to="{ name: 'userSettings', hash: '#subscription'}"
          class="text-decoration-none"
        >
          <v-btn
            x-large
            block
            color="primary"
            class="navigation-drawer-add-patient-button"
            elevation="0"
          >
            <v-icon
              left
              light
            >
              {{ mdiStar }}
            </v-icon>
            Upgrade to PRO
          </v-btn>
        </router-link>
      </div>
      <div
        v-else
        class="ma-6 navigation-drawer-add-patient text-left"
      >
        <router-link
          :to="{ name: 'userSettings', hash: '#subscription'}"
          class="text-decoration-none"
        >
          <v-btn
            x-large
            block
            color="primary"
            class="navigation-drawer-add-patient-button"
            elevation="0"
          >
            <v-icon
              left
              light
            >
              {{ mdiStar }}
            </v-icon>
            PRO subscription
          </v-btn>
        </router-link>
      </div>
      <div
        v-if="!isLocked"
        class="ma-6 navigation-drawer-add-patient text-left"
      >
        <router-link
          :to="{ name: 'clientAdd' }"
          class="text-decoration-none"
        >
          <v-btn
            x-large
            block
            color="black"
            class="navigation-drawer-add-patient-button"
            elevation="0"
          >
            <v-icon
              left
              light
            >
              {{ mdiPlus }}
            </v-icon>

            Add new client
          </v-btn>
        </router-link>
      </div>
      <router-link
        class="px-8 py-6 navigation-drawer-user-settings"
        :to="{ name: 'userSettings' }"
      >
        <div class="initials">
          {{ initialText }}
        </div>
        <div class="settings-text ml-3">
          <h3>{{ providerName }}</h3>
          <span>Personal Settings</span>
        </div>
      </router-link>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiPencil, mdiPlus, mdiStar } from '@mdi/js'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import reminders from '@/kit/dictionaries/reminders'
import additionalMenu from '@/helpers/additionalMenu'
import DashboardMenu from '@/components/dashboard/DashboardMenu'
// import IconReview from '@/components/icons/IconReview'
import IconSummary from '@/components/icons/IconSummary'
// import IconViewDashboard from '../icons/IconViewDashboard'

export default {
  name: 'NavigationDrawer',
  components: { DashboardMenu },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      drawer: true,
      mdiClose,
      mdiPencil,
      mdiStar,
      reminders,
      // IconReview,
      // IconSummary,
      mdiPlus,
      menuItems: [{
        // icon: IconViewDashboard,
        icon: IconSummary,
        text: 'Dashboard',
        route: 'dashboard'
      }]
    }
  },
 
  computed: {
    ...mapGetters('authentication', ['clientPhrase', 'isAdmin', 'providerName']),
    ...mapGetters('app', ['isLocked']),
    ...mapGetters('clients', ['client', 'haveChosenClient']),
    ...mapGetters('subscription', ['subscription', 'hasActiveSubscription']),
    isShowNavigationDrawer() {
      if (this.isLocked && !this.haveChosenClient) {
        // User is always directed to the select client page
        return false
      }
      return this.$vuetify.breakpoint.mdAndUp
    },
    menu() {
      // Build the sidebar navigation, conditionally add admin routes
      let menu = [
        ...this.menuItems,
        ...this.reminders
      ]
      if (this.isAdmin) {
        menu = [
          ...menu,
          'divider',
          ...additionalMenu
        ]
      }

      return menu
    },
    initialText() {
      return (this.providerName || '')
        .split(' ')
        .slice(0, 2)
        .map(item => item[0])
        .filter(letter => letter)
        .join('')
        .trim()
    }
  },
  watch: {
    drawer(now) {
      this.$root.$emit('DrawerClosed', now)
    },
    isDrawerOpen(now) {
      if (now) {
        window.scrollTo(0, 0)
      }
      this.drawer = now
    }
  },
  methods: {
    ...mapActions('clients', ['resetClient']),
    // ...mapActions('subscription', ['hasActiveSubscription']),
    ...mapMutations('app', ['referer']),
    choosePatientClickHandler() {
      const routerData = this.haveChosenClient
        ? { name: 'clientEdit', params: { id: this.client.id } }
        : { name: 'patientSelect' }

      this.referer({
        name: this.$route.name,
        params: this.$route.params
      })

      this.$router.push(routerData).catch(() => {})
    }
  }
}
</script>

<style lang="scss">

.navigation-drawer {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    // align-content: space-between;
  }

  &-bottom {
    margin-top: auto;
  }
}
</style>

<style scoped lang="scss">

// Montserrat doesn't seem to provide a 500 weight (design requires 500 in some spots)

$color__base: #FF9175;
$color__base-text: #27201F;
$color__nav-background: #F5F5F5;
$font-size__body: 15px;
$font-size__title: 18px;
$font-color__base: $color__base-text;
$font-color__body: #27201F;

$avatar-size: 36px;

.navigation-drawer {
  background: $color__nav-background;
  border-width: 0px !important;
  font-family: var(--font-family-primary);
  position: fixed;
  .logo-link {
    display: flex;

    img {
      display: inline-block;
      margin: auto;
      height: 32px;
    }
  }

  // .v-avatar {
  //   position: relative;
  //   font-size: 24px;
  //   color: $color__base-text;
  //   text-transform: uppercase;

  //   @media (max-width: 959px) {
  //     font-size: 18px;
  //   }
  // }

  &-add-patient {
    .add-patient-img {
      width: 56px;
      height: 56px;
      border: 1.5px solid $color__base;
      border-radius: 50%;

      img {
        width: 34px;
      }
    }

    &-button {
      border-radius: 8px;
      text-transform: none;
      font-weight: 700;
      font-size: 15px;
    }
  }


  &-choose-patient {
    cursor: pointer;
    position: relative;

    .v-btn {
      transition: transform 0.3s ease-in-out;
    }

    &.selected {
      .navigation-drawer-choose-patient-helper {
        background-color: var(--v-neutralLight-base);
      }

      .choose-patient-name {
        color: $font-color__body;
      }
    }
    
    &-name {
      font-weight: 500;
    }


    .choose-patient {
      background: white;
      border: 1px solid #E0E0E0;
      border-radius: 12px;
      flex-direction: column;
      
      h3 {
        font-size: 13px;
        color: #615c5c;
        display: block;
      }
      &-name {
        // Requested by new design:
        // font-size: 14px;
        font-size: $font-size__title;
        line-height: 17px;
        font-weight: 500;
        color: $font-color__base;
      }

      // .avatar-img-default {
      //   width: 26px;
      //   margin-left: 4px;
      // }

      // &-avatar {
      //   position: relative;
      // }

      &-edit {
        position: absolute;
        right: 50px;
        top: 38%;
        transform: translateY(-50%);
        z-index: 2;
      }

      &-remove {
        position: absolute;
        top: 26%;
        right: 26px;
        // transform: translateY(-50%);
        z-index: 2;

        // &:hover {
        //   transform: translateY(-50%) scale(1.4);
        // }
      }
    }
  }

  &-user-settings {
    display: flex;
    overflow: hidden;
    border-top: 1px solid #E0E0E0;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: #E0E0E0;  // Top border color
    }

    .initials {
      width: $avatar-size;
      height: $avatar-size;
      flex-shrink: 0;
      line-height: $avatar-size;
      border-radius: 50%;
      text-align: center;
      color: #27201F;
      font-size: 14px;
      font-weight: 600;   // 500
      background-color: #fff;
    }

    .settings-text {
      height: $avatar-size;

      // Prevent this container from overflowing parent
      flex: 1 1 0;
      min-width: 0;

      h3 {
        font-weight: 600;   // 500
        font-size: 14px;
        line-height: 18px;
        color: #27201F;
        
        // Long names
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      
      span {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #615C5C;
      }
    }
  }
}
</style>
