import firebase from '@/firebase'

export default async (
  url,
  data,
  options = {
    method: 'POST'
  }
) => {
  const token = await firebase.auth().currentUser.getIdToken()
  const baseUrl = process.env.VUE_APP_API_URL || process.env.VUE_APP_BASE_URL

  if (baseUrl.includes('localhost')) {
    return false
  }

  if (options.method === 'POST') {
    options['body'] = JSON.stringify(data)
  }

  return fetch(`${baseUrl}/api${url}`, {
    ...options,
    headers: {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(r => r.json())
}
