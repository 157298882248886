<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8314 11.9702C20.5649 11.9702 21.9702 10.5669 21.9702 8.83584C21.9702 7.10479 20.5649 5.70151 18.8314 5.70151C17.0979 5.70151 15.6926 7.10479 15.6926 8.83584C15.6926 10.5669 17.0979 11.9702 18.8314 11.9702ZM18.7225 7.45074L17.6864 7.87462L17.5235 7.39104L19.0352 6.74627H19.3545V10.9254H18.7225V7.45074Z"
      fill="#C4C3C2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.9797 19.1791C9.62507 19.4738 9.41493 19.84 9.41493 20.2369C9.41493 21.2107 10.6797 22 12.2399 22C13.8 22 15.0648 21.2107 15.0648 20.2369C15.0648 19.84 14.8547 19.4738 14.5 19.1791H9.9797Z"
      fill="#C4C3C2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0338 1C12.8094 1 13.6019 1.6167 13.6019 2.37744C15.6922 2.37744 17.1263 3.20883 18.0624 4.19672C15.8279 4.5633 14.1231 6.50076 14.1231 8.83581C14.1231 11.4324 16.2311 13.5373 18.8314 13.5373C19.0778 13.5373 19.3198 13.5184 19.556 13.482V14.5138C20.749 18.6675 23.2815 19.6424 23.8741 19.8705C24.0432 19.9356 24.0543 19.9399 23.8315 19.9399H21.9064H18.9575H13.6019C13.6019 19.9399 13.6346 19.8167 13.695 19.5955H10.1524C10.2128 19.8167 10.2455 19.9399 10.2455 19.9399H4.88991H1.94105H0.172674C-0.055066 19.9399 -0.0439835 19.9354 0.126764 19.8661C0.818196 19.5856 4.1278 18.243 4.79938 11.8357L4.88991 7.1985C5.62022 3.51889 8.20636 2.62268 10.6303 2.42006C10.6298 2.40591 10.6296 2.3917 10.6296 2.37744C10.6296 1.6167 11.2583 1 12.0338 1Z"
      fill="#C4C3C2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-neutralMedium-base)'
    }
  }
}
</script>
