import router from '@/router'

export default {
  activity: (state) => {
    return state.client.activity
  },
  client: state => state.client,
  // Determines if the current exercise is practice or for real:
  isPractice: (state, _getters, _rootState, rootGetters) => {
    return (
      !!state.client.id &&
      rootGetters['schedules/item'].step !== 'scheduled' &&
      // Practice URLs have the signature "/dashboard/reminders/practice" <- take note
      (router.currentRoute.params?.slug === 'practice' || router.currentRoute.params?.slug === 'assess')
    )
  },
  haveChosenClient: state => !!state.client.id,
  collectionPath: (state, getters, rootState) => {
    return rootState.authentication.user
      ? `users/${rootState.authentication.user.id}/clients`
      : null
  },
}
