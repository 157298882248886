export default {
  hasActiveSubscription: (state, getters, rootState) => {
    // return true
    // manually created subscriptions through the stripe console have the cancel_at param set to the timestamp
    // for when the subscription ends. Programmatically created subscriptions
    // come in as null but change to timestamp after we cancel through the stripe portal via adherely
    let active = true
    if (state?.subscription?.cancel_at) {
      const now = parseInt(Date.now()/1000)
      active = state.subscription.cancel_at > now
    }
    
    return (state.subscription && active && (state.subscription?.status === 'active' 
      || state.subscription?.status === 'trialing')) 
      || rootState.authentication.user?.flags.premiumFeatures1.value
  },
  subscription: state => state.subscription,
}
