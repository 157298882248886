<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.439 0H1.56098C1.14698 0 0.749939 0.164459 0.457199 0.457199C0.164459 0.749939 0 1.14698 0 1.56098V14.439C0 14.853 0.164459 15.2501 0.457199 15.5428C0.749939 15.8355 1.14698 16 1.56098 16H14.439C14.853 16 15.2501 15.8355 15.5428 15.5428C15.8355 15.2501 16 14.853 16 14.439V1.56098C16 1.14698 15.8355 0.749939 15.5428 0.457199C15.2501 0.164459 14.853 0 14.439 0V0ZM8.58537 11.7073C8.58537 11.8108 8.54425 11.9101 8.47107 11.9833C8.39788 12.0564 8.29862 12.0976 8.19512 12.0976H4.29268C4.18918 12.0976 4.08992 12.0564 4.01674 11.9833C3.94355 11.9101 3.90244 11.8108 3.90244 11.7073V10.9268C3.90244 10.8233 3.94355 10.7241 4.01674 10.6509C4.08992 10.5777 4.18918 10.5366 4.29268 10.5366H8.19512C8.29862 10.5366 8.39788 10.5777 8.47107 10.6509C8.54425 10.7241 8.58537 10.8233 8.58537 10.9268V11.7073ZM12.4878 8.19512C12.4878 8.29862 12.4467 8.39788 12.3735 8.47107C12.3003 8.54425 12.2011 8.58537 12.0976 8.58537H4.29268C4.18918 8.58537 4.08992 8.54425 4.01674 8.47107C3.94355 8.39788 3.90244 8.29862 3.90244 8.19512V7.41463C3.90244 7.31113 3.94355 7.21187 4.01674 7.13869C4.08992 7.0655 4.18918 7.02439 4.29268 7.02439H12.0976C12.2011 7.02439 12.3003 7.0655 12.3735 7.13869C12.4467 7.21187 12.4878 7.31113 12.4878 7.41463V8.19512ZM12.4878 4.68293C12.4878 4.78643 12.4467 4.88569 12.3735 4.95887C12.3003 5.03206 12.2011 5.07317 12.0976 5.07317H4.29268C4.18918 5.07317 4.08992 5.03206 4.01674 4.95887C3.94355 4.88569 3.90244 4.78643 3.90244 4.68293V3.90244C3.90244 3.79894 3.94355 3.69968 4.01674 3.62649C4.08992 3.55331 4.18918 3.51219 4.29268 3.51219H12.0976C12.2011 3.51219 12.3003 3.55331 12.3735 3.62649C12.4467 3.69968 12.4878 3.79894 12.4878 3.90244V4.68293Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
  export default {
    name: "InterviewIcon"
  }
</script>