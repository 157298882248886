export default () => {
  return {
    clientPhrase: "client",
    firebaseUser: null,
    intercomLaunched: false,
    notice: {
      type: null,
      message: null
    },
    subscription: null,
    tid: null,
    user: null,
    userLoading: false,
  }
}
