import Vue from 'vue'

import { firestorePlugin } from 'vuefire'
import Notifications from 'vue-notification'
import VueYoutube from 'vue-youtube'
import FormatDatetime from '@/mixins/FormatDatetime'
import firebase from '@/firebase'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueGtag from "vue-gtag"
require('@/theme/main.scss')

Vue.use(Notifications)
Vue.use(firestorePlugin)
Vue.use(VueYoutube)
Vue.use(VueGtag, {
  config: {
    id: 'AW-10995571789'
  }
})
Vue.prototype.$firebase = firebase
Vue.config.productionTip = false

Vue.mixin(FormatDatetime)

// don't know where to put this to get it to work
// firebase.functions().useEmulator("localhost", 5001);

firebase.auth().onAuthStateChanged(async authUser => {
  if (authUser) {
    firebase.analytics.setUserId(authUser.uid)
    firebase.analytics.setUserProperties({
      verified: authUser.emailVerified
    })
  }

  if (authUser && authUser.emailVerified) {
    // Load the user when the page is first loaded (otherwise user is "signed out" (not really) on reload)
    
    // This block runs on login/register too - it then creates a race between this block and login
    // Let login/register handle the fetchUser
    // See: store/authentication/actions -> googleSignIn/signIn for where that load occurs
    if (store.getters['authentication/user']) return

    await store.dispatch('authentication/fetchUser', authUser.uid)

    // listen for lock/unlockClient message from doxyme iframe to start locked session
    // or unlock session to return to normal state

    // whitelist is for when we want to restrict by domain
    // const whitelist = ['https://doxy.me', 'localhost:8080']
    window.onmessage = function(e) {
      // if (!whitelist.includes(e.origin)) {
      //   return
      // }
      if (e.data == 'lockClient') {
        // go to lock mode.
        store.commit('app/setLock', true)
        // Force user to select a client if the app is locked (prevent navigation away)
        if (store.getters['authentication/isUserLoggedIn'] && store.getters['app/isLocked']) {

          if (!store.getters['clients/haveChosenClient']) {
            if (router.currentRoute.name !== 'patientSelect') {
              router.push({name: 'patientSelect'})
            }
          } else {
            // Prevent provider from accessing changing client
            if (router.currentRoute.name !== 'dashboard') {
              router.push({name: 'dashboard'})
            }
          }
        }
        //send when adherely does not share other patients info
        if (window.parent) {
          window.parent.postMessage('startLockMode', '*')
        }
      }
      if (e.data == 'unlockClient') {
        // to unlocked mode
        store.commit('app/setLock', false)
        //send when adherely might contain others patients info
        if (window.parent) {
          window.parent.postMessage('stopLockMode', '*')
        }
      }
    }

  } else {
    await store.dispatch('clients/resetClient')
  }

  const mountPointSelector = "#app"
  const mountPoint = document.querySelector(mountPointSelector)
  if (!mountPoint?.__vue__) {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})


