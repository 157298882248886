
import defaultState from '@/store/authentication/state'

export default {
  changeFlag: (state, payload) => (state.user.flags[payload.name] = payload.set),
  changeUserFlag: (state, payload) => (state.user.flags[payload.name] = payload.set),
  changeFlagValue: (state, payload) => (state.user.flags[payload.name].value = payload.set),
  resetNotice: state => (state.notice = defaultState().notice),
  setIntercomLaunched: (state, payload) => (state.intercomLaunched = payload),
  setNotice: (state, payload) => (state.notice = payload),
  setTid: (state, payload) => (state.tid = payload),
  setUser: (state, payload) => (state.user = payload),
  userLoading: (state, payload) => (state.userLoading = payload),
  updateProperty(state, { property, value }) {
    state.user = {
      ...state.user,
      [property]: value
    }
  },
}
