import merge from 'lodash.merge'
import { dict as dictReview } from '@/kit/dictionaries/review'
import string from '@/helpers/string'
import functions from '@/helpers/review/functions'
import moment from '@/plugins/moment'
import { index } from '@/helpers/array'
import { progressStates } from '@/store/review/static'

export const preparedDict = merge(dictReview, functions)

export const prepareSchedule = schedule => {
  const titleOrCategory = schedule.reminderCategory ? schedule.reminderCategory : schedule.reminderTitle
  const section = preparedDict.default.getSection(titleOrCategory)

  return {
    ...schedule,
    dict: preparedDict.default.getDict(
      section,
      schedule.activityName,
      schedule.listKey,
      titleOrCategory
    ),
    title: schedule.listName || schedule.activity.title,
    time: schedule.time.map(t => ({
      name: t
    })),
    listKey: schedule.listName || 'default',
    verticalTimes: true,
    dates: schedule.date
      .map(t => ({
        day: moment(t).format('YYYY-MM-DD'),
        date: moment(t).format('YYYY-MM-DD'),
        name: t
      }))
      .sort((a, b) => a.name.localeCompare(b.date)),
    section: preparedDict.default.getSection(titleOrCategory)
  }
}

export const prepareSession = (session, xAxesFormat) => {
  const titleOrCategory = session.reminderCategory ? session.reminderCategory : session.reminderTitle
  const section = preparedDict.default.getSection(titleOrCategory)
  const listKey = session.listName || 'default'
  const dict = preparedDict.default.getDict(
    section,
    session.activityName,
    listKey,
    titleOrCategory
  )
  const label = dict?.label?.(session) || null
  const priority = null === label ? 0 : false === label ? 1 : 2
  const order =
    (preparedDict[section]['order'] || 0) +
    (dict?.order || 5) +
    titleOrCategory +
    session.activityTitle +
    session.listName +
    session.scheduleId

  let timeUTC = null
  if (session.schedules.length > 0) {
    timeUTC = moment(session.nextAppointmentTime, 'hh:mm A')
      .add(-session.schedules[0].utcOffset, 'minutes')
      .format('HH:mm')
  }
  let day = moment(session.date || session.sentTime.toDate())
  const dayFormat = day.format(xAxesFormat)
  day = day.format('YYYY-MM-DD')

  return {
    ...session,
    section,
    listKey,
    dict,
    label,
    priority,
    order,
    sTime: [session.nextAppointmentTime, session.scheduleId].join(),
    completed: session.completed || false,
    title: session.listName || session.activityTitle,
    // day: moment(session.date || session.sentTime.toDate()).format('MMM D'),
    day,
    dayFormat,
    timeUTC,
    state: Object.entries(session.data || {}).find(
      en => typeof en[1] === 'boolean'
    )?.[1]
  }
}

// Called when loading sessions in the review page
// Specifically, filters out sessions and prepares the values for graphing
// See call in review vuex actions for usage
export const prepareSessions = (sessions, schedules, to, from, xAxesFormat) => {
  const indexed = index(schedules, 'id')
  return sessions
    /* Filtering out sessions that don't have a scheduleId, sentTime, indexed[s.scheduleId], and are not
    between the from and to dates. */
    
    .filter(
      s =>
        undefined !== s.scheduleId &&
        undefined !== s.sentTime &&
        undefined !== indexed[s.scheduleId] &&
        moment(moment(s.date).format('MM/DD/YYYY')) >= from &&
        moment(moment(s.date).format('MM/DD/YYYY')) <= to
    )
    /* Mapping over the sessions and adding the schedule to the session. */
    .map(s => {
      s.schedules = indexed[s.scheduleId]
      s = prepareSession(s, xAxesFormat)
      let tooltip = undefined !== s.dict?.tooltip ? s.dict?.tooltip : null

      /* Checking if the tooltip is a string or a function. If it is a string, it is checking if the
      reminderTitle and data are defined.*/
      if (typeof tooltip === 'string') {
        if (undefined !== s.reminderTitle && undefined !== s.data) {
          let key = s.dict?.key
          if (undefined === key) {
            key = s.listKey.replace(/\s*/g, '').toLowerCase()
          }

          if (undefined !== s.data.patient) {
            let data = s.data.patient[key]

            if (undefined !== data) {
              if (undefined !== s.dict.extract) {
                data = s.dict.extract(data, s.dict, s)
              }
              tooltip = preparedDict.default.getTooltip(tooltip, data)

              s.label =
                undefined === s.state
                  ? null
                  : s.state === true
                  ? string.firstUpperOtherLower(data[0].what)
                  : false
            } else {
              s.label = null
              if (s.state === false) {
                s.label = "None"
              }
              tooltip = null
            }
          }
        }
      } else if (typeof tooltip === 'function') {
        /* Checking if the tooltip is a function. If it is, it is calling the function and passing in
        the data, dict, and session. */
        tooltip = s.dict?.tooltip?.(s.data, s.dict, s) || null
      }

      return {
        ...s,
        tooltip
      }
    })
    .sort((a, b) => {
      return b.order.localeCompare(a.order)
    })
    // Place the session into two categories, emotions being in the [0] index
    .reduce((accum, session) => {
      accum[session.activityName === 'emotions' ? 0 : 1].push(session)
      return accum
    }, [[], []])
    // a[0] -> activityName is "emotions", a[1] -> activityName is something else
    // Called like this in review/actions: const [emotions, sessions] = prepareSessions
}

export const getScheduledDiff = (s, t, day, now) => {
  let datetime = moment(`${day.date} ${t['name']}`, 'YYYY-MM-DD HH:mm').add(
    s.utcOffset,
    'minutes'
  )
  datetime = moment(
    `${day.date} ${datetime.format('HH:mm')}`,
    'YYYY-MM-DD HH:mm'
  )

  return now.diff(datetime, 'hours')
}

export const getThisDay = (s, d, format) =>
  s.dates.find(dti => moment(dti.day).format(format) === d)

export const getTimeAmPm = (t, s) => {
  return moment(t['name'], 'HH:mm')
    .add(s.utcOffset, 'minutes')
    .format('h:mm A')
}
export const getSessionForTheDay = (indexed, t, d) => {
  let exists = indexed[[t, d].join()]
  if (undefined !== exists) {
    exists = exists.sort((a, b) => {
      return a.priority > b.priority ? -1 : 1
    })
    return exists[0]
  }

  return undefined
}

export const getThisState = (day, t, s, d) => {
  let label = d
  if (undefined !== day) {
    label = progressStates.scheduled
    const now = moment()
    const datetime = moment(`${day.date} ${t['name']}`, 'YYYY-MM-DD HH:mm').add(
      s.utcOffset,
      'minutes'
    )
    const diffInHours = now.diff(datetime, 'hours')
    if (diffInHours > 1) {
      label = progressStates.missed
    }
    // console.log('label', label)
  }

  return label
}