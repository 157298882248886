import format from 'string-template'
import cloneDeep from 'lodash.clonedeep'
import firebase from '@/firebase'
import {
  getThisDay,
  getScheduledDiff,
  getTimeAmPm,
  preparedDict as dictReview,
  prepareSchedule,
  prepareSessions,
} from '@/helpers/review'
import { fetchDocs } from '@/kit/helpers/firebaseHelper'
import {
  preferredIndex,
  groupBy,
  mapCollateTo,
  indexs,
  orderBy
} from '@/helpers/array'
import string from '@/helpers/string'
import moment from '@/plugins/moment'
import api from '@/helpers/api'
import {notAssigned, progressStates, SLEEP_LOG, severityLabels} from '@/store/review/static'
import {dict} from "@/kit/dictionaries/assess";
import {
  fromDokbotTime,
  fromDokbotTimeDiffMins,
  minsToHrs
} from '@/kit/helpers/datetime'
// import { max } from 'lodash'
// import x from 'uniqid'

export default {
  async init({ dispatch, commit, getters, rootState }, reminderType) {
    if (undefined !== reminderType) {
      await commit('set', { reminderType })
    }
    
    // Demo env setup
    if (
      rootState.app.demo.isDemo &&
      // Only some users are going to have a time loop
      rootState.app.demo.loopClients.includes(rootState.clients.client.id)
    ) {
      // The demo environment will have a repeating day cycle, act as if it's the end of that date
      
      // The key/values for each set() call is an obj hardcoded in the app state
      let current = moment().local()
      Object.entries(rootState.app.demo.loopEndDate).forEach(([unit, value]) => {
        current = current.set(unit, value)
      })
      current = current.startOf('day');
      
      console.log(`Demo detected, behaving as if it's ${ current.toString() }`)

      commit('set', {
        current,
        to: current,  // Redundant
      })
    }

    // Dynamically init
    if (getters.reminderType === SLEEP_LOG) {
      return await dispatch('initAssess')
    }
    await dispatch('init' + string.firstUpper(getters.reminderType));
  },
  /**
   * Getting data from firestore for reviews table
   */
  // TODO: Figure out where the newer assessments went.
  async getReviews({ state, rootState, getters, commit, dispatch }) {
    let query = firebase
      .firestore()
      .collection('reviews')
      .where('schedule.reminderType', '==', getters.reminderType)
      .where('createdBy', '==', rootState.authentication.user.id)
      .where('clientId', '==', rootState.clients.client.id)

    const scheduled = !(await query.limit(1).get()).empty
    await commit('set', { scheduled })

    if (state.period.value !== 'all') {
      await dispatch('pickDays')
    }

    return fetchDocs(
      // Extend query
      query
        .orderBy('dateStart', 'asc')
    )
  },
  /**
   * Because reminderType doesn't work for sleep log,
   * schedule.dokbot == 'sleeplog' will be used instead
   */
  async getSleeplog({ state, rootState, commit, dispatch }) {
    let query = firebase
      .firestore()
      .collection('reviews')
      .where('schedule.dokbot', '==', SLEEP_LOG)
      .where('createdBy', '==', rootState.authentication.user.id)
      .where('clientId', '==', rootState.clients.client.id)
    const scheduled = !(await query.limit(1).get()).empty
    await commit('set', { scheduled })
    if (state.period.value !== 'all') {
      await dispatch('pickDays')
    }
    return fetchDocs(
      // Extend query
      query
        .orderBy('dateStart', 'asc')
    )
  },
  async initAssess({ state, commit, dispatch, rootState, getters }) { 
    
    // console.log(`Selected type: ${ getters.reminderType }`)

    // const reviews = await dispatch('getReviews')

    let reviews = []
    if (getters.reminderType !== 'sleeplog') {
      reviews = await dispatch('getReviews')
    } else {
      reviews = await dispatch('getSleeplog')
    }
    
    // Filter to the correct time range
    const min = moment(moment(getters.from.toDate()).format('YYYY-MM-DD'))
    const max = moment(getters.current).endOf('day')
    const reviewsInRange = reviews.filter(review => {
      if (!review.sessions?.length) return false

      // Determine if any day in the SL is in the review scope
      return review.sessions.some(session => {

        // By default use the session's date
        let targetDate = moment(moment(session.date).format('YYYY-MM-DD'));

        // Sleep log dates are different than regular assessment dates, since the user can enter their own date
        if (getters.reminderType === SLEEP_LOG) {
          // Extract sleep date and convert date to moment instance
          const sleepDate = session.data?.sleep?.date
          if (!sleepDate) return false; // Probably not done yet
          targetDate = moment(moment(sleepDate).format('YYYY-MM-DD'))
        }
        if (
          rootState.app.demo.isDemo &&
          rootState.app.demo.loopClients.includes(rootState.clients.client.id)
        ) {        
          // Skip past assessments from the "future" during demos
          return !(targetDate < max && min <= targetDate)
        } else {
          return min < targetDate
        }
      })
    })

    // console.log('reviews in range', reviewsInRange)

    if (state.period.value === 'all') {
      let diffDays = moment().utc().diff(moment(reviewsInRange?.[0]?.dateStart), 'days') + 2
      if (diffDays < 8) {
        diffDays = 8
        commit('set', { xAxesFormat: 'MMM D' })
      }
      await dispatch('pickDays', diffDays)
    }

    // console.log('Pre-processed reviewsInRange')
    // console.log(cloneDeep(reviewsInRange))
    
    // Look towards the end of the current day
    // Extract just the sessions component from each review
    const reviewSessions = reviewsInRange.map(review => {
      return {
        ...review,
        sessions: review.sessions.filter(session =>
          // Only track completed sessions
          session.completed
        ).map(session => {
          // Determine if the session is visible in the scope AS WELL AS properly select + format the date

          session['dict'] = dict[session.workflowId]

          // Move score value to new key?
          if (session.data?.['Score']) {
            session.data['score'] = session.data['Score']
          }

          // Prepare to filter out assessments with sessions that are not in the view's scope
          // Do this by comparing a date field in the session with the scope, NOT setting sessionDate when out of scope
          // Choose which date to use, sleep logs allow the user to enter their own date
          session.date = getters.reminderType === SLEEP_LOG ? moment(session.data.sleep.date) : moment(session.date)
          const isVisible = (session.date <= max && session.date >= min)
          session.date = session.date.format('YYYY-MM-DD')

          session.isVisible = isVisible;

          return session
        }).filter(session =>
          // Only track sessions that are in the view's scope
          session.isVisible
        )
      }
    }).filter(reviewSession => {
      // Filter out reviews with no current or completed sessions
      return reviewSession.sessions?.length > 0
    }).sort(
      // Sort reviews by their dates
      orderBy.bind(null, 'date', -1)
    )
    
    // Pull sessions and group them
    // This will map the reviews and then flatten the array
    // Each review is an array, and each review.sessions is an array
    // If we simply map, then we get [[...sessions], [...sessions], ...]
    let completedSessions = reviewSessions.reduce((accum, review) => {
      accum.push(...review.sessions)
      return accum
    }, []);
    completedSessions = groupBy(completedSessions, ['activityTitle'])

    // console.log('Post-process')
    // console.log(cloneDeep(completedSessions))

    await commit('set', {
      assessments: completedSessions,
      completed: completedSessions.length > 0
    })

    const assessment = state.assessment?.name || completedSessions?.[0]?.name

    dispatch('setAssess', assessment)
    commit('setLoading', false)
  },
  async initRemind({ dispatch, commit, getters, rootGetters }) {
    // console.log('initRemind')

    if (rootGetters['clients/client'].id !== null) {
      commit('setLoading', true)

      await dispatch('pickDays')
      // Load the review data for reminders/self monitoring
      await dispatch('fetchExercises')
      await dispatch('useSessions')
      await dispatch('useSchedules')
      await dispatch('useEmotions')
      commit('setLoading', false)

      try {
        await api('/review', {
          clientId: rootGetters['clients/client'].id,
          createdBy: rootGetters['authentication/user'].id,
          from: getters.from.toString()
        })
      } catch (_) {
        return false
      }
    }
  },
  pickDays({ commit, getters }, diffDays) {
    if (undefined === diffDays) {
      diffDays = getters.diffDays
    }
    const current = moment(getters.current)
    let days = []
    for (let i = 0; i < diffDays; i++) {
      days.push(current.add(-i, 'day').format(getters.xAxesFormat))
    }
    days = days.reverse()
    commit('set', {
      // Calculate from date in the past
      // Used for 7/30/90/180 day calculations etc.
      from: current.startOf('day').add(-diffDays + 1, 'day')
    })
    commit('set', { days })
  },
  // Called by initRemind for self-monitoring/exercises
  async fetchExercises({ commit, state, rootState }) {
    const reviews = await fetchDocs(
      firebase
        .firestore()
        .collection('reviews')
        .where('isPreviewMode', '==', false)
        .where('createdBy', '==', rootState.authentication.user.id)
        .where('clientId', '==', rootState.clients.client.id)
        .where('practice', '==', false)
        .where('dateStart', '!=', '0000-00-00')
        .orderBy('dateStart', 'asc')
    )
    
    // Extract sessions and schedules from reviews
    const schedules = []
    let sessions = []
    // console.log("Reviews pre-process", cloneDeep(reviews))
    reviews.forEach(review => {
      review.schedule.id = review.id
      schedules.push(review.schedule)
      if (review.sessions) {
        sessions = [...sessions, ...review.sessions.filter(session => session.completed)]
        // sessions = [...sessions, ...review.sessions]
      }
    })

    // console.log("s", sessions)

    // console.log("Sessions post-process", cloneDeep(sessions))

    await commit('set', {
      schedules,
      haveMonitoring: reviews.length > 0,
      sessions
    });
  },
  useSessions({
    commit,
    state: { to, from, sessions: initial, schedules },
    getters
  }) {
    const min = moment(moment(getters.from.toDate()).format('YYYY-MM-DD'))
    const max = moment(getters.current).endOf('day')

    const [emotions, sessions] = prepareSessions(
      initial,
      schedules,
      max,
      min,
      getters.xAxesFormat
    )

    // console.log("emotions", cloneDeep(emotions).map(e => e.date))

    commit('set', {
      sessions,
      emotions
    })
  },
  useSchedules({
    commit,
    getters,
    state: { schedules, sessions, days, from, to }
  }) {
    const _sessions = cloneDeep(sessions)
    let _schedules = cloneDeep(schedules)
    _schedules = _schedules.map(s => {
      s.activityTitle = s.activity.title

      s = prepareSchedule(s)
      s.dates = s.dates.filter(
        d =>
          d.date >= from.format('YYYY-MM-DD') &&
          d.date <= to.format('YYYY-MM-DD')
      )

      return s
    })
    _schedules = _schedules.filter(s => {
      return (
        // Custom reminders don't have dicts
        s.reminderTitle === 'Custom reminder'
      ) || (
        s.dates.length > 0 &&
        s.activityName !== 'emotions' &&
        undefined !== s.dict
      )
    })
    _schedules = _schedules.sort((a, b) => a.title.localeCompare(b.title))
    _schedules = mapCollateTo(
      _schedules,
      _sessions,
      'id',
      'scheduleId',
      'sessions'
    )

    const titles = {}
    const now = moment()
    const defaultValues = {
      false: { label: progressStates.none },
      undefined: { label: progressStates.blank },
      null: { label: progressStates.missed },
      true: { label: progressStates.scheduled }
    }

    _schedules = _schedules.map(s => {
      const indexed = indexs(s.sessions, ['timeUTC', 'dayFormat'])
      const listName = s.listName || ''
      s.listNameLower = listName.toLowerCase()
      s.titleKey = listName + s.activityName + s.reminderTitle
      s.titleKeyCreatedAt = s.titleKey + s.createdAt.toString()
      if (undefined !== s.dict?.title) {
        s.title = format(s.dict.title, s)
      }
      s['horizontal'] = s.dict?.type === 'horizontal'
      s['combined'] = s.dict?.type === 'combined' 
      s.collapse = undefined !== s.dict?.collapse ? s.dict?.collapse : true
      if (s.horizontal) {
        s.time = s.time.map(t => {
          t['timeAmPm'] = getTimeAmPm(t, s)

          t['items'] = days.map(d => {
            const day = getThisDay(s, d, getters.xAxesFormat)

            let thisDayTimeSession = indexed[[t.name, d].join()]
            const values = { ...defaultValues }
            let state
            if (day) {
              state = getScheduledDiff(s, t, day, now) > 0 ? null : true
            }

            if (undefined !== thisDayTimeSession) {
              thisDayTimeSession = thisDayTimeSession.sort((a, b) => {
                return a.priority > b.priority ? -1 : 1
              })
              thisDayTimeSession = thisDayTimeSession[0]
              values[thisDayTimeSession.label] = {
                tooltip: thisDayTimeSession.tooltip,
                label: string.firstUpperOtherLower(thisDayTimeSession.label)
              }
              state = thisDayTimeSession.label
            }

            return values[state]
          })

          return t
        })
      } else if (s.combined) {
        const time = s.time.slice(0, 1)
        // s = _schedule.map
        // t = s.time.map
        // d = days.map

        if (undefined === time[0]['times']) {
          time[0]['items'] = days.map(d => {
            const day = getThisDay(s, d, getters.xAxesFormat)

            const timeMap = s.time.map(t => {
              const thisDayTimeSession = indexed[[t.name, d].join()]
              let state = progressStates.scheduled

              // If the schedule difference is greater than 0, state = missed
              if (day && getScheduledDiff(s, t, day, now) > 0) {
                state = progressStates.missed
              }
              
              // If the session is complete, state = complete
              if (true === thisDayTimeSession?.[0]?.completed) {
                state = progressStates.completed
              }

              state = state.toLowerCase()
              
              if (state === 'completed') {
                return {
                  state,
                  tooltip: thisDayTimeSession?.[0]?.tooltip
                }
              } else {
                return {
                  state,
                  tooltip: `${s.title} at 
                    <nobr>${getTimeAmPm(t, s)}:</nobr> 
                    <span class="font-weight-medium">${state}</span>`
                }
              }
            })
            return {
              label: false,
              times: undefined === day ? [] : timeMap
            }
          })
        }
        s.time = time
      } else {
        const time = s.time.slice(0, 1)
        // s = _schedule.map
        // t = s.time.map
        // d = days.map

        if (undefined === time[0]['times']) {
          time[0]['items'] = days.map(d => {
            const day = getThisDay(s, d, getters.xAxesFormat)

            const timeMap = s.time.map(t => {
              const thisDayTimeSession = indexed[[t.name, d].join()]
              let state = progressStates.scheduled

              // If the schedule difference is greater than 0, state = missed
              if (day && getScheduledDiff(s, t, day, now) > 0) {
                state = progressStates.missed
              }
              
              // If the session is complete, state = complete
              if (true === thisDayTimeSession?.[0]?.completed) {
                state = progressStates.completed
              }

              state = state.toLowerCase()

              return {
                state,
                tooltip: `${s.title} at 
                  <nobr>${getTimeAmPm(t, s)}:</nobr> 
                  <span class="font-weight-medium">${state}</span>`
              }
            })
            return {
              label: false,
              times: undefined === day ? [] : timeMap
            }
          })
        }
        s.time = time
      }

      return s
    })

    _schedules = _schedules.sort(orderBy.bind(null, 'titleKeyCreatedAt', -1))
    _schedules = _schedules.map(s => {
      let ca = moment(s.createdAt.toDate()).unix()
      s.order = '' + (dictReview[s.section].order || 0) + (s.dict?.order || 5)
      let force = 1
      if (s.collapse) {
        if (undefined !== titles[s.titleKey]) {
          force = 2
          ca = titles[s.titleKey]
        } else {
          titles[s.titleKey] = ca
        }
      }
      s.order += ca + force + s.title

      return s
    })
    _schedules = _schedules.sort(orderBy.bind(null, 'order', -1))

    const sections = groupBy(_schedules, ['section'])
    commit('set', { sections })
  },
  xAxesLabels({ state, commit, getters }) {
    const density = Math.floor(getters.diffDays / 4.25) // Magic number
    const offset = Math.floor(density / 2)
    const xAxesLabels = state.days.map((_, index) => (index + offset) % density === 0 ? true : undefined)
    commit('set', { xAxesLabels })
  },
  useEmotions({ state, commit, dispatch }) {
    let emotionList = groupBy(state.emotions, ['listName'])
    const defaultSortList = ['Anxiety', 'Fear', 'Sadness', 'Stress', 'Anger']
    emotionList = emotionList.sort((a, b) => {
      [a, b] = [a, b].map(i => {
        return defaultSortList.findIndex(d => d === i.name)
      })

      return a > b ? 1 : -1
    })

    dispatch('xAxesLabels')
    if (emotionList.length > 0) {
      commit('set', { emotionList })
      
      let emotion = state.emotion !== notAssigned ? state.emotion : emotionList[0].name

      dispatch('setEmotion', emotion)
    } else {
      // This will set a default value
      dispatch('setEmotion')
    }
  },
  toggleTime({ commit, state }, payload) {
    const timeList = cloneDeep(state.timeList)
    timeList.map(t => {
      if (t.id === payload) {
        t.active = !t.active
      }

      return t
    })

    commit('set', { timeList })
  },
  setTime({ commit, state }, payload) {
    const active = state.timeList.findIndex(t => t.id === payload)
    commit('set', { active })
  },
  // this seems to be only used by sleep log
  useTable({ commit, getters }) {

    let assesses = [...getters.assesses]

    // TODO find out why shift was needed at some point
    // assesses.sort(orderBy.bind(null, 'date', -1)).shift() // remove out-of-bounds first assess from table
    assesses.sort(orderBy.bind(null, 'date', -1))

    const table = assesses.reduce((aa, s) => {

      // Raw dokbot data lives in s.data
      // Steps results lives in last
      const { options, score = 0, ...last } = s.data;

      const n = {
        ...s,
        title: 'Assessment date',
        subTitle: moment(this.value).format('MM/DD/YY'),
        rightTitle: 'Score',
        rightSubTitle: score,
        line: [],
      }

      if (getters.reminderType === SLEEP_LOG && last.sleep) {

        /**
         * The table constructor for sleep log hasn't appeared
         * to connect to any dictionary, just connects
         * directly to dokbot.
         */

        n.subTitle = 'Sleep log'
        n.title = '',
        n.rightTitle = 'Sleep Efficiency Score'

        // Time-in-bed and sleep time calculations
        const timeInBed = fromDokbotTimeDiffMins(last.outofbed.time, last.bed.time, true)
        let sleepTime = (fromDokbotTimeDiffMins(last.finalawake.time, last.sleep.time, true) - last.wakeup.duration)
        sleepTime = sleepTime > 0 ? sleepTime : 0

        // First section with queries
        n.line.push({
          class: 'string-header',
          name: `Sleep log date: ${[moment(last.sleep.date).format('MM/DD/YYYY')]}`,
          value: [null]
        })
        const sleepLogQueries = {
          'What time did you get into bed?': [fromDokbotTime(last.bed.time)],
          'About what time did you fall asleep?': [fromDokbotTime(last.sleep.time)],
          'How many times did you wake up during the night?': [last.wakeup.count],
          'How many minutes did you spend awake during the night?': [last.wakeup.duration],
          'What time did you wake up for the day?': [fromDokbotTime(last.finalawake.time)],
          'What time did you get out of bed for the day?': [fromDokbotTime(last.outofbed.time)],
        }
        for (const row in sleepLogQueries) {
          n.line.push({
            class: 'string',
            name: `${row}`,
            value: `${sleepLogQueries[row]}`
          })
        }

        // Second section with calculations
        n.line.push({
          class: 'string-header',
          name: 'Sleep calculation',
          value: [null]
        })
        const sleepLogCalculations = {
          'Time in Bed': [minsToHrs(timeInBed)],
          'Total Sleep Time': [minsToHrs(sleepTime)],
          'Sleep Efficiency': [s.data.score]
        }
        for (const row in sleepLogCalculations) {
          n.line.push({
            class: 'string',
            name: `${row}`,
            value: `${sleepLogCalculations[row]}`
          })
        }

        aa.push(n)
        return aa
      }

      if (getters.reminderType === 'assess') {
        n.subTitle = s.date

        // If sectional
        if (s.dict.hasSections) {
          for (let i = 1; i < s.dict.numSections + 1; i++) {

            // Headers for sectional range responses
            const [rangeHeaders, ...rangeHeadersLast] = s.dict['section_' + i].selection_headers
            n.line.push({
              class: 'range-header',
              name: rangeHeaders,
              value: rangeHeadersLast
            })

            // Headers for sectional boolean responses
            const [booleanHeaders, ...booleanHeadersLast] = s.dict['section_' + i].yes_no_headers
            n.line.push({
              class: 'boolean-header',
              name: booleanHeaders,
              value: booleanHeadersLast
            })

            // Headers for string responses
            const [stringHeaders, ...stringHeadersLast] = s.dict['section_' + i].text_input_headers
            n.line.push({
              class: 'string-header',
              name: stringHeaders,
              value: stringHeadersLast
            })

            const minScore = s.dict['section_' + i].scoreRange.slice(0, 1)[0]

            s.dict['section_' + i].steps.forEach(step => {

              // SELECTION
              if (typeof s.data[step.property] === 'number') {
                const computedValue = rangeHeadersLast.map((header, columnIdx) => {
                  const scoreValue = s.data[step.property] - minScore
                  const maxScore = s.dict['section_' + i].scoreRange.slice(-1)[0] - minScore
                  if (typeof step === 'object' && step.reversed) {
                    const flippedScore = Math.abs(maxScore - scoreValue)
                    return columnIdx === flippedScore
                  }
                  return columnIdx === scoreValue
                })
                const text = step?.text ?? step
                n.line.push({
                  class: 'range',
                  name: text,
                  value: computedValue
                })
              }

              // YES/NO
              if (typeof s.data[step.property] === 'boolean') {
                const computedValue = booleanHeadersLast.map((header, columnIdx) => {
                  const scoreValue = s.data[step.property] - minScore
                  return columnIdx === scoreValue
                })
                const text = step?.text ?? step
                n.line.push({
                  class: 'boolean',
                  name: text,
                  value: computedValue
                })
              }

              // TEXT_INPUT
              if (typeof s.data[step.property] === 'string') {
                const stringValue = s.data[step.property]
                const text = step?.text ?? step
                n.line.push({
                  class: 'string',
                  name: text,
                  value: stringValue
                })
              }
            })
          }
        }
        // If not sectional
        else {
          
          // Headers for range responses
          const [rangeHeaders, ...rangeHeadersLast] = s.dict.selection_headers
          n.line.push({
            class: 'range-header',
            name: rangeHeaders,
            value: rangeHeadersLast
          })

          // Headers for boolean responses
          const [booleanHeaders, ...booleanHeadersLast] = s.dict.yes_no_headers
          n.line.push({
            class: 'boolean-header',
            name: booleanHeaders,
            value: booleanHeadersLast
          })

          // Headers for string responses
          const [stringHeaders, ...stringHeadersLast] = s.dict.text_input_headers
          n.line.push({
            class: 'string-header',
            name: stringHeaders,
            value: stringHeadersLast
          })

          const minScore = s.dict.scoreRange.slice(0, 1)[0]

          // Iterate through the steps of an assessment from the dictionary
          s.dict.steps.forEach(step => {

            // SELECTION
            if (typeof s.data[step.property] === 'number') {
              const computedValue = rangeHeadersLast.map((header, columnIdx) => {
                const scoreValue = s.data[step.property] - minScore
                const maxScore = s.dict.scoreRange.slice(-1)[0] - minScore
                if (typeof step === 'object' && step.reversed) {
                  const flippedScore = Math.abs(maxScore - scoreValue)
                  return columnIdx === flippedScore
                }
                return columnIdx === scoreValue
              })
              const text = step?.text ?? step
              n.line.push({
                class: 'range',
                name: text,
                value: computedValue
              })
            }

            // YES/NO
            if (typeof s.data[step.property] === 'boolean') {
              const computedValue = booleanHeadersLast.map((header, columnIdx) => {
                const scoreValue = s.data[step.property] - minScore
                return columnIdx === scoreValue
              })
              const text = step?.text ?? step
              n.line.push({
                class: 'boolean',
                name: text,
                value: computedValue
              })
            }

            // TEXT INPUT
            if (typeof s.data[step.property] === 'string') {
              const stringValue = s.data[step.property]
              const text = step?.text ?? step
              n.line.push({
                class: 'string',
                name: text,
                value: stringValue
              })
            }
          })
        }
        aa.push(n)

        return aa
      }
    }, []);

    commit('set', { table })
  },
  setAssess({state, commit, getters, dispatch }, payload) {

    
    // payload is the assessment name
    const assessment = state.assessments.find((e) => e.name === payload)

    const xAxesLabels = new Array(state.days.length)
    if (assessment) {
      let assessmentItems = assessment?.items || []
      commit('set', { assessment })

      // If the assessment is sleep log, calculate the sleep scores
      if (getters.reminderType === SLEEP_LOG) {
        assessmentItems = assessmentItems.map(assessmentItem => {
          const timeInBed = fromDokbotTimeDiffMins(assessmentItem.data.outofbed.time, assessmentItem.data.bed.time, true)
          const sleepTime = fromDokbotTimeDiffMins(assessmentItem.data.finalawake.time, assessmentItem.data.sleep.time, true) - assessmentItem.data.wakeup.duration
          let sleepEfficiency = timeInBed > 0 ? sleepTime/timeInBed * 100 : 0
          sleepEfficiency = sleepEfficiency > 100 ? 100 : sleepEfficiency

          assessmentItem.data['score'] = Math.round(sleepEfficiency)
          assessmentItem.data['score'] = assessmentItem.data['score'] > 0 ? assessmentItem.data['score'] : 0

          return assessmentItem
        });
      }

      // Set assessmentItems into assesses, 
      // initialize graph data and score sort
      commit('set', { assesses: assessmentItems })
      const assessShortName = getters.assessShortName
      const datasets = []
      const tooltips = []
      const assessmentDesc = assessment.items.slice()
      assessmentDesc.sort((a, b) => {
          a = a.date + (a.data.score + '').padStart(2, '0')
          b = b.date + (b.data.score + '').padStart(2, '0')
          return a.localeCompare(b)
      })

      for (const item of assessmentDesc) {
        if (undefined !== item.data) {

          // item.data is where the raw dokbot data lives
          const itemDate = moment(item.date).format(getters.xAxesFormat)
          const index = state.days.findIndex(
            (day) => day === itemDate
          )
          if (index >= 0) {
            if (undefined === datasets[item.scheduleId]) {
              datasets[item.scheduleId] = state.days.map(() => null)
              tooltips[item.scheduleId] = state.days.map(() => false)
            }
            
            // Create arrays of values on days with multiple scores
            if (datasets[item.scheduleId][index]) {
              // Data already exists at this index
              if (Array.isArray(datasets[item.scheduleId][index])) {
                datasets[item.scheduleId][index] = [...datasets[item.scheduleId][index], item.data.score]
              } else {
                datasets[item.scheduleId][index] = [datasets[item.scheduleId][index], item.data.score]
              }
            } else {
              // Single value
              datasets[item.scheduleId][index] = item.data.score
            }
            if (assessShortName === 'Sleep log') {
              tooltips[item.scheduleId][index] = `<div class="grey-text">${itemDate}</div><div>Sleep Efficiency: ${item.data.score}</div>`
              xAxesLabels[index] = true
            } else {
              tooltips[item.scheduleId][index] = `<div class="grey-text">${itemDate}</div><div>${assessShortName} total score: ${item.data.score}</div>`
              xAxesLabels[index] = true
            }
          }
        }
      }

      commit('set', {
        assessChartData: {
          datasets: Object.values(datasets),
          tooltips: Object.values(tooltips)
        },
      })
    }

    dispatch('xAxesLabels')
    dispatch('useTable')
  },

  /**
   * 
   * @param {*} param0 
   * @param {*} payload 
   */
  setEmotion({ commit, state, getters }, payload) {
    const emotion = state.emotionList.find(e => e.name === payload)
    if (emotion) {
      let emotionItems = groupBy(emotion.items, ['nextAppointmentTime'])

      emotionItems = emotionItems.sort((a, b) => {
        a = moment(a.name, 'h:mm A').format('HH:mm')
        b = moment(b.name, 'h:mm A').format('HH:mm')

        return a.localeCompare(b, undefined, { numeric: true })
      })
      
      // Break down emotions list from self-monitoring into useful graphing components
      const { times, datasets, tooltips } = emotionItems.reduce(
        (a, c) => {
          const [name] = c.name.split(',')
          a.times.push({ id: c.name, name, active: true })
          const dataset = new Array(state.days.length).fill(null)
          const tooltip = new Array(state.days.length).fill(null)
          for (const item of c.items) {
            if (item.data) {
              // console.log("days", state.days)
              // console.log("id", item.day)
              // console.log("xAxesFormat", getters.xAxesFormat)

              // Determines where on the graph the day should sit

              const index = state.days.findIndex(
                day => day === moment(item.day).format(getters.xAxesFormat)
              )

              if (index >= 0) {
                dataset[index] = item.data.severity
                tooltip[index] =
                  `<div class="grey-text mb-2">${moment(item.date).format(
                    'MMM DD'
                  )}</div>` + item.tooltip
              }
            }
          }
          a.datasets.push(dataset)
          a.tooltips.push(tooltip)

          return a
        }, 
        { times: [], datasets: [], tooltips: [] }
      )

      commit('set', {
        timeList: times,
        chartData: {
          datasets,
          tooltips
        },
        emotion: payload
      })

      const i = preferredIndex(emotionItems)
      commit('setTimeIndex', i)
      commit('set', { active: i })
    } else {
      // Current emotion is not in emotionList
      // We'll pick a reasonable default
      if (state.emotionList.length) {
        // Choose first on list
        commit('set', {
          emotion: state.emotionList[0].name
        })
      } else {
        // No emotions selected
        commit('set', {
          emotion: notAssigned
        })
      }
    }
  },
  setPeriod({ state, commit, dispatch, getters }, payload) {
    const reminderType = getters.reminderType
    commit('reset', { assessment: state.assessment, emotion: state.emotion })
    commit('setPeriod', payload)
    commit('set', { xAxesFormat: 'MMM D' })
    dispatch('init', reminderType)
  },

  /**
   * setAllEmotions - action function to assemble and store chart data
   * @param {vuex function} commit 
   * @param {vuex state object} state
   * @param {vuex state getters object} getters
   */
  async setAllEmotions({ commit, state, getters }) {
    /* group emtions by emotion, contained in 'title' */
    let emotionItems = groupBy(getters.emotions, ['title'])
    /* contains assembled chart data */
    let data = []
    emotionItems.forEach(emotion => {
      /** need two parallel arrays to capture chart data
      * and tooltips separately to accommodate limitations
      * of ApexCharts in the way they handle x-axis labels
      * and tooltips
      * 
      * Fill each array with null according to the number of days in chosen range 
      */
      let itemData = Array(getters.days.length).fill(null)
      let tooltips = Array(getters.days.length).fill(null)
      /* loop through each emotion to build chart data object and tooltips */
      emotion.items.forEach((item) => {
        let convertedTime = new Date(item.completedAt.seconds * 1000)
          .toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        let itemDay = moment(item.day).format(getters.xAxesFormat)
        /* find the index of the day of the range in order
         align data properly according to date */
        const index = state.days.findIndex(
          day => day === itemDay
        )
        itemData[index] = item.data.severity
        tooltips[index] = {
          date: item.date,
          time: convertedTime,
          level: severityLabels[item.data.severity],
          emotion: item.title,
          cope: item.data.cope || ' doing nothing'
        }
      })
      data.push({
        name: emotion.name,
        data: itemData,
        tooltips: tooltips
      })
    })
    commit('setSessionData', data)
  },

}
