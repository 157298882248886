import * as app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/performance'
import 'firebase/auth'
import config from '@/firebase-config/config'

const firebase = app.initializeApp(config)

// firebase.functions().useFunctionsEmulator('http://localhost:5001')

export default {
  firebase,
  auth: app.auth,
  firestore: app.firestore,
  performance: app.performance(),
  analytics: app.analytics(),
  db: firebase.firestore(),
  functions: firebase.functions(),
  now: app.firestore.Timestamp.now,
  increment: app.firestore.FieldValue.increment,
  actionCodeSettings: {
    handleCodeInApp: true
  }
}
