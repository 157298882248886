<template>
  <v-app class="auth-page">
    <v-container
      no-gutters
      fill-height
      class="auth-page-content"
    >
      <v-row fill-height>
        <v-col
          style="padding: 0"
          no-gutters
          :class="this.$route.name == 'signUp' || this.$route.name == 'signUpComplete' ? 'col-md-8 col-sm-12' : 'col-12'"
        >
          <v-main style="background-color: var(--v-neutralLight-base) !important;">
            <v-container class="form-container">
              <v-row class="main-row fill-height justify-center align-left">
                <v-col class="col-auto py-0">
                  <v-card
                    :class="
                      this.$route.name == 'signIn' ? 'content-signin' : 'content'
                    "
                    elevation="0"
                  >
                    <header class="auth-page-header">
                      <router-link :to="{ name: 'signIn' }">
                        <img
                          alt="Adhere.ly"
                          class="logo"
                          src="@/assets/logo.2.0.svg"
                        >
                      </router-link>
                    </header>
                    <section class="content-wrap">
                      <!-- Contains what the router is set to -->
                      <!-- Such as ActionResetPassword -->
                      <router-view />
                    </section>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-main>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp && (this.$route.name == 'signUp' || this.$route.name == 'signUpComplete')"
          class="col-4 right-bar"
          fill-height
        >
          <v-container
            :class="[
              'py-0',
              {
                'fill-height': $vuetify.breakpoint.smAndUp,
              }
            ]"
          >
            <v-row class="right-bar-row fill-height justify-center align-start">
              <v-col class="col-auto py-0">
                <header>
                  <h1>Get started with Adhere.ly</h1>
                </header>
                <img
                  src="@/assets/getstarted.png"
                  alt="Get started with Adhere.ly graphic describing the interface to the app"
                >
                <h2>Benefits</h2>
                <ul>
                  <li>Adhere.ly is HIPAA-compliant, free, and secure.</li>
                  <li>Mental health practitioners report 8x the time savings when using Adhere.ly compared to other tools.</li>
                  <li>Adhere.ly monitors client progress over time, maximizing the value of therapy.</li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
import Layout from '@/mixins/Layout'

export default {
  name: 'LayoutDefault',
  mixins: [Layout],
  watch: {
    $route: function(rt) {
      this.resetNotice()
    }
  },
  beforeDestroy() {
    this.resetNotice()
  },
  methods: {
    ...mapMutations('authentication', ['resetNotice'])
  },
 
}
</script>

<style lang="scss">
.main-row {
  margin: 0 !important;
  min-height: 100vh;
  padding: 4rem 0 0 0;
  
  @media (max-width: 599px) {
      padding: 0;
    }
}

.main-row > .col-auto {
  margin: 0;
  min-width: 100%;
  padding: 0;
}

@media(min-width: 600px) {
  .main-row > .col-auto {
    margin: 0 auto;
    min-width: 100%;
    padding: 12px;
  }

  .v-card {
    margin: 0 auto;
  }
}

.form-container {
  margin: 1rem;
  max-width: calc(100% - 2rem);
}

.right-bar {
  background-color: var(--v-primary-base);
  background-image: linear-gradient(
    130deg,
    hsl(12deg 100% 73%) 0%,
    hsl(11deg 100% 73%) 11%,
    hsl(10deg 100% 73%) 22%,
    hsl(10deg 100% 73%) 33%,
    hsl(9deg 100% 73%) 44%,
    hsl(8deg 100% 73%) 56%,
    hsl(7deg 100% 73%) 67%,
    hsl(6deg 100% 73%) 78%,
    hsl(5deg 100% 73%) 89%,
    hsl(4deg 100% 73%) 100%
  );

  &-row {
    padding: 2rem;

    header {
      margin-top: 126px;
      h1 {
        font-size: 32px;
        margin-bottom: 2rem;
      }
    }

    img {
      max-width: 100%;
    }

    h2 {
      margin-bottom: 1rem;
    }

    ul {
      list-style: none;
      padding-left: 0 !important;
      li {
        background-image: url('../assets/icon-check.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
        margin-bottom: 1rem;
        padding-left: 32px;
      }
    }
  }
}

.auth-page {

  overflow: hidden !important;
  
  &-header {
    margin-bottom: 1rem;
    text-align: center;
  }

  @media(min-width: 600px) {
    &-header {
      text-align: left;
    }
  }
  
  &-content {
    max-width: 100% !important;
    padding: 0 0 0 0 !important;

    // @media (max-width: 959px) {
    //   padding: 0 0 30px !important;
    // }

    // @media (max-width: 599px) {
    //   padding: 0 0 30px !important;
    // }
  }

  .content-signin {
    width: 100%;
    max-width: 734px;
    padding: 50px;
    border-radius: 15px !important;

    @media (max-width: 959px) {
      padding: 45px 80px;
    }

    @media (max-width: 599px) {
      padding: 45px 6px;
    }
  }

  .content {
    width: 100%;
    max-width: 734px;
    padding: 142px;
    border-radius: 15px !important;

    @media (max-width: 959px) {
      padding: 45px 80px;
    }

    @media (max-width: 599px) {
      padding: 45px 6px;
    }

    &-wrap {
      max-width: 100%;
      margin: 0 1rem;
    }

    @media(min-width: 600px) {
      &-wrap {
        max-width: 496px;
        margin: 0 auto;
      }
    }

    &-title {
      font-size: 30px;
      font-weight: 500;
      line-height: 1.1;

      @media (max-width: 599px) {
        font-size: 24px;
      }
    }

    &-subtitle {
      // margin-top: 16px;
      font-size: 16px;
      color: var(--v-neutralMedium-base);
    }

    &-body:not(.content-body-no-header) {
      // margin-top: 48px;

      @media (max-width: 959px) {
        // margin-top: 40px;
      }
    }

    &-footer {
      margin-top: 54px;
      font-size: 16px;

      @media (max-width: 959px) {
        margin-top: 60px;
      }
    }

    .forgot-password {
      margin-bottom: 20px;
      margin-left: 6px;
      font-size: 16px;
    }
  }
}
</style>
