export default {
  generalFontSize: '16px',
  generalBodyFontFamily: "'Montserrat', sans-serif",
  generalBorderRadius: '15px',
  generalHeaderColor: 'var(--v-black-base)',
  generalHeaderH1FontSize: '1.75rem',
  generalHeaderH1FontWeight: 700,
  generalHeaderH4FontSize: '16px',
  generalHeaderH4FontWeight: 500,
  generalDatePickerTitleDateFontSize: '34px',
  generalDatePickerTitleYearFontSize: '14px',
  generalInputBorder: '1px solid rgba(154, 166, 182, 0.6)',
  generalInputBorderRadius: '15px',

  iconScheduledSuccessSecondaryColor: '#D55994',
  iconPrivacyPrimaryColor: 'var(--v-neutralMedium-base)',

  cardBorderRadius: 'var(--general-border-radius)',

  btnTextTransform: 'uppercase',
  btnBorderRadius: '30px',
  btnSizeXLarge: '60px',
  btnFontSizesXLarge: '16px',
  btnFontWeight: 500,

  workflowLogoWidth: '42px',
  workflowHeaderPx: '2.813rem',
  workflowHeaderPb: '0.625rem',
  workflowHeaderPy: '2.813rem',
  workflowBodyPx: '2.813rem',
  workflowBodyPb: '2.813rem',
  workflowBodyPy: '0.625rem',
  workflowFooterPx: '2.813rem',
  workflowFooterPt: 0,
  workflowFooterPy: '2.813rem',
  workflowPrivacyTitleMargin: 0,
  workflowPrivacyTitleFontSize: '14px',
  workflowPrivacyTextFontSize: '12px',
  workflowPrivacyTitleColor: 'var(--v-neutralMedium-base)',
  workflowPrivacyTextColor: 'var(--v-neutralMedium-base)',
  workflowH1SpanFontWeight: 'var(--general-header-h1-font-weight)',
  workflowH1SpanTextDecoration: 'none',
  workflowScheduledH1FontSize: '36px',
  workflowScheduledH1FontWeight: 'var(--general-header-h1-font-weight)',

  inputHeight: '64px',
  inputFontSizes: '18px',

  choiceTitleColor: '#000',
  choiceTitleFontSize: '16px',
  choiceTitleFontWeight: 500,
  choiceTitleLineHeight: '1.1',
  choiceTextMargin: '4px 0 0',
  choiceTextColor: 'var(--v-neutralMedium-base)',
  choiceTextFontSize: '14px',
  choiceTextFontWeight: '500',
  choiceTextLineHeight: '1.1',
  choiceBorder: 'var(--v-neutralLight-base)',
  choiceBorderHover: 'var(--v-accent-base)',
  choiceBgColor: 'var(--v-neutralLight-base)',
  choiceMoreTitleColor: 'var(--choice-title-color)',
  choiceSelectedBorder: '1px solid var(--v-resultPositive-base)',
  choiceSelectedBgColor: 'var(--v-resultPositive-base)',
  choiceSelectedTitleColor: '#fff',
  choiceSelectedTextColor: 'var(--v-primaryLight-base)',
  choiceDisabledTitleColor: 'var(--v-neutralMedium-base)',
  summaryHeaderColor: 'var(--general-header-color)',
  summaryHeaderSmsColor: 'var(--v-neutralMedium-base)',
  summaryHeaderSmsFontSize: '14px',
  summaryHeaderSmsFontWeight: 500,
  summaryTextFontSize: '18px',
  summaryTextFontWeight: 500,
  summaryTextColor: 'var(--v-neutralDark-base)',
  summaryEmailContentPadding: 0,
  summarySmsContentPadding: 0,
  summaryContentBorder: 'none',

  dashboardWidth: '276px',
  contentWidth: '1440px'
}
