export default {
  // Will expand on this later if changing between client or patient is desired
  clientPhrase: state => state.clientPhrase,  
  firebaseUser: state => state.firebaseUser,
  isAdmin: state => state.user != null && state.user.role === 'admin',
  isIntercomLaunched: state => state.intercomLaunched,
  isProvider: state => state.user != null && state.user.role === 'provider',
  isUserLoggedIn: state => state.user !== null,
  isUserLoading: state => !!state.userLoading,
  notice: state => state.notice,
  providerName: state => {
    if (state.user) {
      return `${state.user.first_name} ${state.user.last_name}`
    }
  },
  providerTitle: state => {
    if (state.user) {
      let pre = state.user.first_name
      if (!!state.user.title && 'Other' !== state.user.title) {
        pre = state.user.title
      }

      return `${pre} ${state.user.last_name}`
    }
  },
  role: state => (state.user ? state.user.role : null),
  tid: state => state.tid,
  user: state => state.user,
}
