<template>
  <div :class="['menu-dashboard', menuClass]">
    <v-list flat>
      <v-list-item-group
        color="primary"
      >
        <template v-for="(item, i) in menu">
          <dashboard-menu-item
            v-if="typeof item === 'object'"
            :key="i"
            :item="item"
          >
            <v-list-item-icon
              :is="item.icon"
              v-if="item.icon"
            />
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </dashboard-menu-item>

          <div
            v-else
            :key="i"
            class="divider"
          />
        </template>

        <slot />
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import DashboardMenuItem from '@/components/dashboard/DashboardMenuItem'

export default {
  name: 'DashboardMenu',
  components: { DashboardMenuItem },
  props: {
    menu: {
      type: Array,
      required: true
    },
    menuClass: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
.menu {
  &-dashboard {
    .v-list-item {
      &__content {
        overflow: unset;
      }

      &--active {
        .v-list-item__title {
          font-weight: 500;
          color: var(--v-black-base);
        }
        svg {
          path {
            fill: var(--v-primary-base);
          }
        }
      }
    }
  }

  &-navigation-drawer {
    padding: 0px;

    .v-list-item {
      &--active {
        background: white;
      }
     
      border-radius: 12px;
      padding: 0px;

      svg {
        margin: 11px 11px 11px 24px;
      }

      &__title {
        font-size: 18px;
        font-weight: 300;
      }
    }
  }

  &-app-bar {
    border: 1px solid #e8e8e8;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05);

    .divider {
      margin: 0 32px;
    }

    .v-list {
      padding: 24px 0;

      &-item {
        min-height: 34px;
        padding: 0 32px;

        svg {
          margin-right: 11px;
        }

        &__title {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }

  &-dashboard,
  &-app-bar {
    .divider {
      position: relative;
      padding: 15px 0;

      &::before {
        content: '';
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #e3e3e3;
      }
    }
  }
}
</style>
