<template>
  <v-app>
    <!-- Very basic router-view passthrough for Relaxation exercises specifically (see router/routes/default.js - needs refactoring): -->
    <v-main
      v-if="$route.name === 'relaxation'"
      app
    >
      <v-container class="py-0">
        <router-view />
      </v-container>
    </v-main>
    <v-app-bar
      v-if="$route.name !== 'relaxation'"
      app
      absolute
      flat
      :height="headerHeight"
      :max-height="headerHeight"
    >
      <!-- Logo and buttons -->
      <v-container
        class="fill-height align-start pa-0"
      >
        <v-row class="justify-center justify-sm-space-between pt-2 pt-sm-4">
          <div class="app-bar-logo ml-2">
            <a
              href="https://www.adhere.ly/"
              target="_blank"
            >
              <img
                alt="Adhere.ly"
                class="logo logo-login"
                src="@/assets/logo.2.0.svg"
              >
            </a>
          </div>
          <v-col class="col-auto  hidden-xs-only">
            <router-link
              :to="{ name: 'signIn' }"
              class="spacing-sign-in"
            >
              Sign In
            </router-link>
            <v-btn
              width="160"
              color="primary"
              :to="{ name: 'signUp' }"
            >
              Sign Up
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- Page content -->
    <v-main
      v-if="$route.name !== 'relaxation'"
      style="padding-top: 10% !important"
    >
      <v-container class="py-0">
        <router-view />
        <v-row class="justify-center hidden-sm-and-up">
          <v-col class="col-auto">
            <v-btn
              width="160"
              color="primary"
              :to="{ name: 'signIn' }"
            >
              Sign Up
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <footer-layout
      v-if="$route.name !== 'relaxation'"
    />
  </v-app>
</template>

<script>
import Layout from '@/mixins/Layout'
import FooterLayout from '@/components/Footer'

export default {
  name: 'LayoutDefault',
  components: {
    FooterLayout,
  },
  mixins: [Layout],
  data() {
    return {
      metaBreakpoints: {}
    }
  },
  computed: {
    headerHeight() {
      if (this.$vuetify.breakpoint.smOnly) {
        return '116px'
      } else if (this.$vuetify.breakpoint.xsOnly) {
        return '72px'
      } else {
        return '142px'
      }
    }
  }
}
</script>
<style scoped>

  .ml-2 {
    margin-top: 1.25rem;
  }
  .spacing-sign-in {
    color: #FF7154;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    width:160px;
    min-width:64px; 
    height:36px; 
    padding:0px 16px 
  }
</style>