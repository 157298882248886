<template>
  <section @click="acted">
    <router-view />
    <wait-overlay :state="isLoading" />
  </section>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import debounce from 'lodash.debounce'
import WaitOverlay from './components/WaitOverlay'
import postRobot from 'post-robot'
// import { toHexString } from '@videojs/vhs-utils/cjs/byte-helpers'

export default {
  name: 'App',
  components: {
    'wait-overlay': WaitOverlay
  },
  data() {
    return {
      authFailureListener: null,
      ssoListener: null
    }
  },
  computed: {
    ...mapGetters('app', ['isLoading']),
    ...mapGetters('authentication', ['user'])
  },
  mounted() {
    this.initH()
    window.addEventListener('scroll', debounce(this.acted, 100))
    window.addEventListener('resize', debounce(this.initH, 100))
    window.addEventListener('error', async (event) => {
      try {
        await this.$firebase.functions.httpsCallable('logEventFrontend')({
          message: event.message,
          level: event.type,
          stack: event?.error?.stack,
          data: {
            uid: this.user.id,
            email: this.user.email,
            signInAt: new Date(this.user.signInAt * 1000).toLocaleString(),
            colno: event?.colno,
            lineno: event?.lineno,
            message: event?.message,
            timeStamp: event?.timeStamp
          }
        })
      } catch (e) {
        console.error("Error sending to logtail via firebase callable:")
        console.error(e)
      }
    })    
  },
  async created() {
    await this.segmentTrack({ eventName: 'Site View' })
    // Determine if this is the demo env
    const demoHostnames = [
      'adherely-demo.firebaseapp.com',
      'localhost'
    ]

    this.setIsDemo(demoHostnames.includes(window.location.hostname))

    postRobot.on('getSSOToken', (event) => {
      this.ssoAuth(event.data)
    })

    postRobot.on('authFailure', (event) => {
      console.log('authFailure', event)
      this.loading = false
      this.setNotice({
        message: 'Login from doxy.me has failed.',
        type: 'error'
      })
    })
  },
  methods: {
    ...mapMutations('app', ['acted', 'setIsDemo', 'loading']),
    ...mapMutations('authentication', ['setNotice']),
    ...mapActions('authentication', ['doxymeSignIn']),
    ...mapActions('analytics', ['segmentTrack']),
    initH: () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }, 
    async ssoAuth(ssoToken) {
      if (!ssoToken.authToken) {
        this.$router.push({name: 'signIn'})
      }
      // call doxymeSignIn and attempt to either authenticate or create account
      this.doxymeSignIn(ssoToken.authToken)
      .then(() => {
        // set up authstate listener to see if auth changed to monitor when
        // emailVerified state changes, which is slow, or if not verified when
        // state changes, send verification email, which should techinally not happen
        // but we want to make sure we handle it.
        this.$firebase.auth().onAuthStateChanged(async user => {
          if (user) {
            // force reload of user to get state of emailVerified
            await user.reload()
            if (user.emailVerified) {
              this.$router.push({ name: 'dashboard' }).catch(() => {})
            } else if (!user.emailVerified) {
              user.sendEmailVerification()
              .then(async () => {
                const analytic = {
                  eventName: 'Sign Up Complete',
                  name: user.first_name + ' ' + user.last_name,
                  email: user.email,
                  id: user.id
                }
                await this.segmentTrack(analytic)
                this.$router.push({ name: 'signUpComplete' })
              })
            } else {
              this.$router.push({name: 'signIn'})
            }
          }
        })
      }) 
    }

  }
}
</script>
<style>
:root {
  --font-family-primary: "Open Sans", sans-serif;
  --font-weight-button: 800;
}
</style>