export default () => {
  const nav = window.navigator
  const screen = window.screen
  let guid = nav.mimeTypes.length
  guid += nav.userAgent.replace(/\D+/g, '')
  guid += nav.plugins.length
  guid += screen.height || ''
  guid += screen.width || ''
  guid += screen.pixelDepth || ''

  const timeStampInMs =
    window.performance &&
    window.performance.now &&
    window.performance.timing &&
    window.performance.timing.navigationStart
      ? window.performance.now() + window.performance.timing.navigationStart
      : Date.now()
  const ret = guid + timeStampInMs

  return ret.replace('.', '')
}
