export default {
  loading({ commit }, payload) {
    commit('loading', payload)
  },

  error({ commit }, payload) {
    commit('error', payload)
  },

  notice({ commit }, payload) {
    console.log("Setting notice", payload)
    commit('notice', payload)
  },
  
  setLock({ commit }) {
    commit('setLock', true)
  },

  unlock({ commit }) {
    commit('setLock', false)
  },

  fetchDocs(state, payload) {
    return payload.get().then(snapshot =>
      snapshot.docs.map(x => ({
        ...x.data(),
        id: x.id
      }))
    )
  }
}
