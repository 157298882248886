<template>
  <v-overlay
    v-if="overlay"
    v-model="overlay"
    class="overlay"
    color="white"
    opacity="0.85"
  >
    <v-progress-circular
      indeterminate
      size="196"
      width="7"
      color="#ff9175"
    >
      <span class="loading-text">Loading Adherely...</span>
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'WaitOverlay',
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      overlay: false
    }
  },
  watch: {
    state(flag) {
      this.overlay = flag
    }
  }
}
</script>

<style scoped>
.overlay {
  background: white;
  opacity: 0.85;
}
.loading-text {
  font-family: var(--font-family-primary);
}
</style>