import defaultState from '@/store/ux/state'

export default {
  reset: state => {
    Object.assign(state, defaultState())
  },
  // Get the title and type of a given activity
  setActivityUX: (state, payload) => {
    state.ux.activityTitle = payload.title
    state.ux.activityType = payload.type
    state.ux.reminderType = payload.reminderType
  },
  // Get whether the iPhone preview us used for a given workflow
  setUXPreviewMode: (state, payload) => {
    state.ux.isPreviewMode = payload
  }
}