import IconViewDashboard from '@/components/icons/IconSummary'
import IconRemind from '@/components/icons/IconRemind'
import IconNotification from '@/components/icons/IconNotification'
import IconCalendar from '@/components/icons/IconCalendar'
import IconHelpBox from '@/components/icons/IconHelpBox'
import IconAccount from '@/components/icons/IconUsers'

export default [
  {
    icon: IconViewDashboard,
    text: 'Summary',
    route: 'admin'
  },
  {
    icon: IconRemind,
    text: 'Reminders',
    route: 'adminReminders'
  },
  {
    icon: IconNotification,
    text: 'Notifications',
    route: 'notifications'
  },
  {
    icon: IconCalendar,
    text: 'Contacts',
    route: 'contacts'
  },
  {
    icon: IconHelpBox,
    text: 'Logs',
    route: 'logs'
  },
  {
    icon: IconAccount,
    text: 'Users',
    route: 'users'
  }
]
