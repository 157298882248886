export const codeMessage = {
  'auth/user-not-found': 'Account for provided email not found.',
  'auth/argument-error': 'Please provide a valid email address'
}

export const getErrorMessage = e => {
  return undefined !== e.code && undefined !== codeMessage[e.code]
    ? codeMessage[e.code]
    : e.message
}
