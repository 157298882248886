<template>
  <v-app>
    <navigation-drawer :is-drawer-open="isDrawerOpen" />

    <!-- <app-bar v-if="!$route.meta.hideAppBar" /> -->

    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        class="snackbar"
        :timeout="timeout"
        top
        color="white"
      >
        <img
          v-if="snackType === 'info'"
          class="icon--success"
          src="@/assets/icon-check--green.svg"
          alt="Success checkmark"
        >
        <img
          v-if="snackType === 'error' || snackType === 'err'"
          class="icon--error"
          src="@/assets/icon-error.svg"
          alt="Error icon"
        >
        <span class="snackbar--text">{{ snackText }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <img
              class="icon--close"
              src="@/assets/icon-close.svg"
              alt="Icon to close dialog"
            >
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-main
      class="layout-dashboard"
      color="white"
      :class="{
        'navigation-drawer-closed': !isShowNavigationDrawer,
        'layout-dashboard-full-width': $route.meta.fullWidth
      }"
    >
      <v-container
        v-if="!$route.meta.preventWrap"
        fluid
        class="ma-0 pa-0"
      >
        <section
          :class="[
            'px-0', // 48px
            'pb-7', // 28px
            // Set useDeprecatedHeader on old routes that have not been adapted to use the new header yet
            $route.meta.useDeprecatedHeader ? 'pt-7' : 'pt-0',
            'layout-dashboard-content',
            `layout-dashboard-content-${this.$route.name || 'undefined'}`
          ]"
        >
          <div
            class="hamburger"
            @click="isDrawerOpen = !isDrawerOpen"
          >
            <v-icon
              v-if="!isDrawerOpen"
              size="2.25rem"
            >
              {{ mdiMenu }}
            </v-icon>
            <v-icon
              v-else
              size="2.25rem"
            >
              {{ mdiClose }}
            </v-icon>
          </div>
          <router-view />
        </section>
      </v-container>
      <router-view v-else />
    </v-main>

    <!-- <bottom-navigation-provider v-if="$vuetify.breakpoint.smAndDown" /> -->
  </v-app>
</template>

<script>

import { mapGetters } from 'vuex'

// import AppBar from '@/components/dashboard/AppBar'
import NavigationDrawer from '@/components/dashboard/NavigationDrawer'
// import BottomNavigationProvider from '@/components/dashboard/BottomNavigationProvider'
import Layout from '@/mixins/Layout'
import { mdiMenu, mdiClose } from '@mdi/js'

export default {
  name: 'LayoutDashboard',
  components: {
    // AppBar,
    NavigationDrawer,
    // BottomNavigationProvider,
    
  },
  mixins: [Layout],
  props: {
    mainClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDrawerOpen: true,
      mdiClose,
      mdiMenu,
      snackbar: false,
      timeout: 4000,
      snackText: '',
      snackType: ''
    }
  },
  computed: {
    ...mapGetters('app', ['notice', 'isLocked']),
    ...mapGetters('clients', ['haveChosenClient']),
    isShowNavigationDrawer() {
      if (this.isLocked && !this.haveChosenClient) {
        // User is always directed to the select client page
        return false
      }
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  watch: {
    notice(noticeUpdate) {
      // console.log(noticeUpdate)
      if (noticeUpdate) {
        this.snackbar = true
        this.snackText = noticeUpdate.message ?? noticeUpdate.text
        this.snackType = noticeUpdate.type
      }
    }
  },
  created() {
    this.$root.$on('DrawerClosed', now => {
      this.isDrawerOpen = now
    })
  }
}
</script>

<style lang="scss">
@import '../theme/functions';

.hamburger {
  color: #212121;
  cursor: pointer;
  display: block;
  position: fixed;
  left: 1rem;
  top: 1.125rem;
  z-index: 100;
}

.icon {
  font-size: 2rem !important;
}

@media(min-width: 768px) {
  .icon{
    font-size: 3rem !important;
  }
}

.hamburger:hover {
  color: var(--v-primary-base);
  transition: color 500ms;
}

.layout-dashboard {
  // Max-width includes width of sidebar since this container has the sidebar on the left as padding, not margin
  // max-width: calc(var(--content-width) + var(--dashboard-width));
  font-family: var(--font-family-primary);

  .v-main__wrap {
    margin: 0 auto;
    max-width: var(--content-width);
    width: 100%;
  }

  &-full-width {
    .v-main__wrap {
      max-width: 100%;
    }
  }
}

.v-main__wrap {
  margin: 0px;
}

.v-snack.snackbar {

  .v-sheet.v-snack__wrapper {
    box-shadow: 0px 3px 3px rgba(114, 129, 167, 0.1) !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 12px !important;
    padding: 8px 16px;
    z-index: 11;
    color: #192333;
  }

  .icon {
    &--success,
    &--error {
      width: 20px;
      height: 20px;
      margin: auto 0px;
    }
  }

  .snackbar--text {
    margin-left: 6px;
    line-height: 20px;
  }
  .v-snack__content {
    padding: 0px !important;
    display: flex;
  }
  .v-snack__btn {
    padding: 0px !important;
    min-width: 0px;

    &:hover::before {
      opacity: 0.0;
    }
  }
  .v-snack__action {
    margin: 0px;
  }
}

.layout-dashboard {
  // background-color: var(--v-secondary-base) !important;
  .v-main__wrap {
    background: #fff;
    margin-top: 2rem;
  }

  @media (max-width: 599px) {
    & > .v-main__wrap > .container {
      padding: 0 7px !important;

      & > .row {
        margin-right: -7px;
        margin-left: -7px;
      }
    }
  }

  &-content {
    margin: 0 1rem;
    max-width: 100%;
    padding: 10px 0 30px;

    @media (max-width: 959px) {
      padding: 30px 0 45px;
    }

    &-clientAdd, &-clientEdit {
      max-width: 100%;
    }
  }
}
</style>
