var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('navigation-drawer',{attrs:{"is-drawer-open":_vm.isDrawerOpen}}),_c('div',{staticClass:"text-center"},[_c('v-snackbar',{staticClass:"snackbar",attrs:{"timeout":_vm.timeout,"top":"","color":"white"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"black","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('img',{staticClass:"icon--close",attrs:{"src":require("@/assets/icon-close.svg"),"alt":"Icon to close dialog"}})])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[(_vm.snackType === 'info')?_c('img',{staticClass:"icon--success",attrs:{"src":require("@/assets/icon-check--green.svg"),"alt":"Success checkmark"}}):_vm._e(),(_vm.snackType === 'error' || _vm.snackType === 'err')?_c('img',{staticClass:"icon--error",attrs:{"src":require("@/assets/icon-error.svg"),"alt":"Error icon"}}):_vm._e(),_c('span',{staticClass:"snackbar--text"},[_vm._v(_vm._s(_vm.snackText))])])],1),_c('v-main',{staticClass:"layout-dashboard",class:{
      'navigation-drawer-closed': !_vm.isShowNavigationDrawer,
      'layout-dashboard-full-width': _vm.$route.meta.fullWidth
    },attrs:{"color":"white"}},[(!_vm.$route.meta.preventWrap)?_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('section',{class:[
          'px-0', // 48px
          'pb-7', // 28px
          // Set useDeprecatedHeader on old routes that have not been adapted to use the new header yet
          _vm.$route.meta.useDeprecatedHeader ? 'pt-7' : 'pt-0',
          'layout-dashboard-content',
          `layout-dashboard-content-${this.$route.name || 'undefined'}`
        ]},[_c('div',{staticClass:"hamburger",on:{"click":function($event){_vm.isDrawerOpen = !_vm.isDrawerOpen}}},[(!_vm.isDrawerOpen)?_c('v-icon',{attrs:{"size":"2.25rem"}},[_vm._v(" "+_vm._s(_vm.mdiMenu)+" ")]):_c('v-icon',{attrs:{"size":"2.25rem"}},[_vm._v(" "+_vm._s(_vm.mdiClose)+" ")])],1),_c('router-view')],1)]):_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }