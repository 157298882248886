import defaultState from '@/store/review/state'

export default {
  reset: (state, payload) => Object.assign(state, defaultState(), payload),
  set: (state, value) => {
    const extracted = Object.entries(value)
    extracted.forEach(([name, value]) => {
      state[name] = value
    })
  },
  setActive: (state, payload) => (state.active = payload),
  setAssessmentTableData: (state, payload) => (state.assessmentTableData = payload),
  setHasChartData: (state, payload) => {
    const chartDataType = `has${payload.type}Data`
    state[chartDataType] = payload.hasData
  },
  setEmotion: (state, payload) => (state.emotion = payload),
  setLoading: (state, payload) => (state.loading = payload),
  setPeriod: (state, payload) => {
    state.period.value = payload
  },
  setPeriodDefault: (state, payload) => {
    state.period.defaultValue = payload
  },
  setPrintChartData: (state, payload) => {
    const data  = `chartData${payload.type}`
    state[data] = payload.data
  },
  setSelfReminder: (state, payload) => (state.selfReminder = payload),
  setSessionData: (state, payload) => (state.sessionData = payload),
  setTime: (state, payload) => (state.time = payload),
  setTimeIndex: (state, payload) => {
    state.time = state.timeList[payload].id
  },
  setThoughtsAndBehaviorsGraphData: (state, payload) => (state.thoughtsAndBehaviorsGraphData = payload),
}
