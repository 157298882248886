import firebase from '@/firebase'
import postRobot from 'post-robot'
export default async function ssoSignin({to, next, store }) {
  store.commit('loading', true)
  const ssoToken = to.query?.ssoToken || null
  if (ssoToken) {
    const ssoValidateToken = firebase.functions.httpsCallable('ssoValidateToken')
    const verified = await ssoValidateToken({token: ssoToken})
    if (!verified.data) {
      postRobot.send(window.opener, 'authFailure').then(() => {
        window.close()
      }).catch(e => {
        console.log('error in postrobot: authFailure', e)
      })
      return
    }
    // checking for manual doxyme login 
    // if no opener, then call is coming directly from doxyme and we do not
    // want to close the window and redirect focus to the opener
    if (window.sessionStorage.getItem('loginWindow') === 'true') {
      postRobot.send(window.opener, 'getSSOToken', {authToken: verified}).then(() => {
        window.close()
      }).catch(e => {
        console.log('error in postrobot: getSSOToken', e)
      })
      
    } else {
      // call doxymeSignIn and attempt to either authenticate or create account
      store.dispatch('authentication/doxymeSignIn', {authToken: verified})
      .then(() => {
        // set up authstate listener to see if auth changed to monitor when
        // emailVerified state changes, which is slow, or if not verified when
        // state changes, send verification email, which should techinally not happen
        // but we want to make sure we handle it.
        firebase.auth().onAuthStateChanged(async user => {
          if (user) {
            // force reload of user to get state of emailVerified
            await user.reload()
            if (user.emailVerified) {
              return next({ name: 'dashboard' })
            } else if (!user.emailVerified) {
              user.sendEmailVerification()
              .then(() => {
                return next({ name: 'signUpComplete' })
              })
            } else {
              return next()
            }
          }
        })
      }) 
    }
  } else {
    return next({ name: 'signIn' })
  }
}
