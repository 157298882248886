import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(weekday)

export default dayjs
