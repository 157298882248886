export const snapshotToArray = snapshot => {
  const returnArr = []

  snapshot.forEach(function(childSnapshot) {
    const item = childSnapshot.data()
    item.id = childSnapshot.id
    item.key = childSnapshot.key
    item.ref = childSnapshot.ref

    returnArr.push(item)
  })

  return returnArr
}

export const fetchDocs = collection => {
  return collection.get().then(snapshot => {
    let docs = snapshot.docs
    docs = docs.map(x => ({
      ...x.data(),
      id: x.id
    }))
    docs = clear(docs)

    return docs
  })
}

export const clear = docs => {
  return docs.map(p => {
    for (const [name, value] of Object.entries(p)) {
      if (undefined !== value?.firestore) {
        delete p[name]
      }
    }

    return p
  })
}

export const first = snapshots => {
  if (!snapshots.empty) {
    snapshots.forEach(snapshot => {
      return snapshot
    })
  }

  return null
}
