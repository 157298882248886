export default {
  customMessage: state => state.customMessage,
  filter: state => state.filter,
  recurrence: state => state.recurrence,
  item: state => state.item,
  editType: state => state.editType,
  video: state => state.item.video || null,
  audio: state => state.item.audio || null,
  session: state => state.session || null
}
