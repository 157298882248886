<script>
import { paramCase } from 'param-case'

const CUSTOM_THEME_STYLESHEET = 'custom-theme-stylesheet'

export default {
  methods: {
    setStyle(fonts = '') {
      let el = document.getElementById(CUSTOM_THEME_STYLESHEET)

      if (!el) {
        el = document.createElement('style')

        el.setAttribute('type', 'text/css')
        el.setAttribute('id', CUSTOM_THEME_STYLESHEET)

        document.head.appendChild(el)
      }

      const styleObj = this.$vuetify.theme.options.cssVariables

      let styleStr = ''

      Object.keys(styleObj).forEach(item => {
        styleStr += `--${paramCase(item)}: ${styleObj[item]};`
      })

      el.innerHTML = `
          ${fonts}
          :root {${styleStr}}
        `
    }
  }
}
</script>
