<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
      fill="url(#paint0_linear_8_105)"
    />
    <path
      d="M18 11C20.7614 11 23 8.76142 23 6C23 3.23858 20.7614 1 18 1C15.2386 1 13 3.23858 13 6C13 8.76142 15.2386 11 18 11Z"
      fill="url(#paint1_linear_8_105)"
    />
    <path
      d="M6 23C8.76142 23 11 20.7614 11 18C11 15.2386 8.76142 13 6 13C3.23858 13 1 15.2386 1 18C1 20.7614 3.23858 23 6 23Z"
      fill="url(#paint2_linear_8_105)"
    />
    <path
      d="M18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23Z"
      fill="url(#paint3_linear_8_105)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8_105"
        x1="1.3207"
        y1="1.11962"
        x2="12.7897"
        y2="5.39627"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8_105"
        x1="13.3207"
        y1="1.11962"
        x2="24.7897"
        y2="5.39627"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8_105"
        x1="1.3207"
        y1="13.1196"
        x2="12.7897"
        y2="17.3963"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8_105"
        x1="13.3207"
        y1="13.1196"
        x2="24.7897"
        y2="17.3963"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-neutralMedium-base)'
    }
  }
}
</script>
