<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0833 10.625C22.2049 10.625 22.3215 10.5767 22.4075 10.4908C22.4934 10.4048 22.5417 10.2882 22.5417 10.1667C22.5387 7.73641 21.572 5.40653 19.8536 3.68807C18.1352 1.96961 15.8053 1.00291 13.375 1C13.2535 1 13.1369 1.04829 13.0509 1.13424C12.965 1.2202 12.9167 1.33678 12.9167 1.45833V9.73125C12.9169 9.9683 13.0111 10.1955 13.1787 10.3633C13.3462 10.531 13.5734 10.6255 13.8104 10.6259L22.0833 10.625Z"
      fill="url(#paint0_linear_10_111)"
    />
    <path
      d="M22.5417 12.0014H13.8333C13.6983 11.9842 13.5613 12.0118 13.4435 12.0802C13.3256 12.1485 13.2336 12.2537 13.1814 12.3795C13.1293 12.5054 13.1201 12.6448 13.155 12.7764C13.19 12.9081 13.2673 13.0245 13.375 13.1078L19.5332 19.266C19.6192 19.352 19.7357 19.4002 19.8572 19.4002C19.9788 19.4002 20.0954 19.352 20.1813 19.266C21.0778 18.3742 21.7884 17.3135 22.2724 16.1452C22.7562 14.9769 23.0035 13.7243 23 12.4597C23 12.3382 22.9518 12.2217 22.8658 12.1357C22.7798 12.0497 22.6632 12.0014 22.5417 12.0014Z"
      fill="url(#paint1_linear_10_111)"
    />
    <path
      d="M10.7056 23.0011C11.9579 22.9984 13.1973 22.7472 14.3518 22.2619C15.5063 21.7766 16.553 21.0669 17.4312 20.1741C17.5171 20.0881 17.5654 19.9716 17.5654 19.85C17.5654 19.7285 17.5171 19.612 17.4312 19.526L11.273 13.3678C11.1647 13.2379 11.0986 13.078 11.0832 12.9095V4.20116C11.0832 4.0796 11.0349 3.96302 10.949 3.87707C10.8631 3.79112 10.7465 3.74283 10.6249 3.74283C8.0711 3.75353 5.62615 4.77828 3.8279 6.59165C2.02966 8.40502 1.02542 10.8585 1.03612 13.4123C1.04682 15.9661 2.07157 18.4111 3.88494 20.2093C5.69832 22.0075 8.15177 23.0118 10.7056 23.0011Z"
      fill="url(#paint2_linear_10_111)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10_111"
        x1="13.2253"
        y1="1.11514"
        x2="24.2645"
        y2="5.23113"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_10_111"
        x1="13.4506"
        y1="12.085"
        x2="23.7871"
        y2="17.2211"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_10_111"
        x1="1.56613"
        y1="3.97319"
        x2="21.1533"
        y2="10.242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-neutralMedium-base)'
    }
  }
}
</script>
