import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import cssVariables from '@/theme/css-variables'

// Custom svg icons
import ChatIcon from '@/assets/img/types/ChatIcon'
import InterviewIcon from '@/assets/img/types/InterviewIcon'
import VideoIcon from '@/assets/img/types/VideoIcon'
import GameIcon from '@/assets/img/types/GameIcon'
import JournalIcon from '@/assets/img/types/JournalIcon'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      /**
       * Types:
       * - chat
       * - interview
       * - video
       * - game
       * - journal
       */
      chat: {
        component: ChatIcon
      },
      interview: {
        component: InterviewIcon
      },
      video: {
        component: VideoIcon
      },
      game: {
        component: GameIcon
      },
      journal: {
        component: JournalIcon
      }
    }
  },
  theme: {
    themes: {
      light: {
        // primaryLight: '#70D2E0',
        primaryLight: '#2385F7',
        accent: '#FF9175',
        // anchor: '#1391A4',
        anchor: '#192333',
        resultPositive: '#10B1C9',
        primary: '#FF9175', /* primary-base */
        pink: '#F17391',
        error: '#A51A0A',
        neutralDark: '#192333',
        black: '#192333',
        secondary: '#F3F7FA',
        neutralLight: '#F5F5F5',
        neutralGray: '#F5F5F5',
        neutralGrayText: '#817D7D',
        resultInactive: '#CACFD2',
        neutralMedium: '#9AA6B6',
        green: '#64BB41',
        success: '#256710',
        yellow: '#F0C747',
        orange: '#F5BA4B',
        textColor: '#61718A',
        violet: '#674EA7',
        white: '#FFFFFF',
        workflowBg: '#FFFFFF',
        workflowHeaderBg: '#FFFFFF'
      }
    },
    options: {
      cssVariables,
      logo: '/img/logo-bird.2.0.svg',
      customProperties: true
    }
  }
})
