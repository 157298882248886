<template>
  <v-footer
    app
    color="transparent"
    absolute
    class="footer"
  >
    <v-row justify="center">
      <v-col class="col-12 col-sm-auto pb-0">
        <nav class="footer-nav footer-nav-left">
          <a
            href="/"
            class="footer-link"
          >About Us</a>
          <router-link
            :to="{ name: 'contactUs' }"
            class="footer-link"
          >
            Contact Us
          </router-link>
        </nav>
      </v-col>
      <v-col class="col-12 col-sm-auto pb-0">
        <nav class="footer-nav footer-nav-right">
          <router-link
            :to="{ name: 'privacyPolicy' }"
            class="footer-link"
          >
            Privacy Policy
          </router-link>
          <router-link
            :to="{ name: 'termsOfService' }"
            class="footer-link"
          >
            Terms of Service
          </router-link>
        </nav>
      </v-col>
      <v-col class="col-12 footer-copyright text-center py-0">
        Copyright {{ new Date().getFullYear() }} Adhere.ly. All rights reserved.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
.footer {
  padding-top: 20px;
  padding-bottom: 48px;

  @media (max-width: 959px) {
    padding-bottom: 50px;
  }

  @media (max-width: 599px) {
    padding-bottom: 15px;
  }

  &-nav {
    &-left {
      text-align: right;

      @media (max-width: 599px) {
        text-align: center;
      }
    }

    &-right {
      @media (max-width: 599px) {
        text-align: center;
      }

      .footer-link {
        @media (max-width: 599px) {
          text-transform: none;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  &-link {
    position: relative;
    margin: 0 16px;
    font-size: 14px;
    font-weight: 500;
    color: var(--v-neutralMedium-base);
    text-transform: uppercase;
    text-decoration: none;

    @media (max-width: 599px) {
      font-weight: 400;
    }

    &.router-link-exact-active {
      color: var(--v-primaryLight-base);
    }

    &::after {
      content: '';
      position: absolute;
      right: -16px;
      top: 50%;
      width: 1px;
      height: 10px;
      margin-top: -5px;
      background-color: var(--v-neutralMedium-base);
    }

    &:last-child::after {
      display: none;
    }
  }

  &-copyright {
    margin-top: 14px;
    font-size: 12px;
    color: var(--v-neutralMedium-base);

    @media (max-width: 599px) {
      font-size: 10px;
    }
  }
}
</style>
