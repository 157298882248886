import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import store from '@/store'
import localStorage from '@/misc/localStorage'
import LayoutAuth from '@/layouts/LayoutAuth'
import LayoutDefault from '@/layouts/LayoutDefault'
import LayoutDashboard from '@/layouts/LayoutDashboard'
// import LayoutDemo from '@/layouts/LayoutDemo' not used seemingly
import authenticated from '@/router/middleware/authenticated'
import isAdmin from '@/router/middleware/isAdmin'
import adminRoutes from './routes/admin'
import summaryRoutes from './routes/summary'
import dashboardRoutes from './routes/dashboard'
import authRoutes from './routes/auth'
import defaultRoutes from './routes/default'
import middlewarePipeline from './middlewarePipeline'
import firebase from '@/firebase'
import authenticatedSignin from '@/router/middleware/authenticatedSignin'
import ssoSignin from '@/router/middleware/ssoSignin'


const HBAT = 'Dh9sF6TYbxn39pNP'

// to prevent duplicate routes and swallow errors associated with them
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  })
}

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: LayoutDefault,
      children: defaultRoutes
    },
    {
      path: '/activity/:activity/:choice',
      name: 'activity',
      component: () => import('@/views/Activity.vue'),
      meta: {
        middleware: [authenticatedSignin]
      }
    },
    {
      path: '/go/:workflowId',
      name: 'go-dokbot',
      component: () => import('@/views/GoDokbot.vue')
    },
    {
      path: '/go-link/:timeId',
      name: 'go-link',
      component: () => import('@/views/GoLink.vue')
    },
    {
      path: '/auth',
      redirect: { name: 'signIn' },
      component: LayoutAuth,
      children: authRoutes
    },
    {
      path: '/dashboard',
      redirect: { name: 'dashboard' },
      component: LayoutDashboard,
      children: dashboardRoutes.map(item => ({
        ...item,
        meta: { middleware: [authenticated], ...item.meta }
      }))
    },
    { // Configure authentication privileges for
      // admin-level client and reminder data
      path: '/dashboard/admin',
      redirect: { name: 'admin' },
      component: LayoutDashboard,
      meta: { role: 'admin', authRequired: true },
      children: adminRoutes.map(item => ({
        ...item,
        meta: {
          middleware: [isAdmin, authenticated],
          role: 'admin',
          bodyClass: 'layout-dashboard-content-full-width'
        }
      }))
    },
    { // Configure authentication privileges for
      // provider-level client and reminder data
      path: '/dashboard/summary',
      redirect: { name: 'summary' },
      component: LayoutDashboard,
      meta: { authRequired: true },
      children: summaryRoutes.map(item => ({
        ...item,
        meta: {
          middleware: [isAdmin, authenticated],
          role: 'admin',
          bodyClass: 'layout-dashboard-content-full-width'
        }
      }))
    },
    {
      path: '/sso',
      name: 'sso',
      component: () => import('@/views/auth/SSO.vue'),
      meta: { middleware: [ssoSignin] }
    },
    { path: '*', redirect: '/' },
  ],
  scrollBehavior(to) {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    if (to.hash) {
      // adding timeout and promise to fix issue with scrolling to hash
      return new Promise(resolve => {
        const params = { 
          selector: to.hash, 
          offset: {x: 0, y: 120}, 
          behavior: 'smooth'
        }
        setTimeout(() => resolve(params), 150)
      })
    }
    return { left: 0, top: 0 }
  },
})

/**
 * Handle user redirections
 */
router.beforeEach((to, from, next) => {
  if(to.name === 'signUpComplete') {
    // facebook pixel tracking
    window.fbq('trackCustom', 'signUpComplete')
  }

  firebase.analytics.logEvent('page_view')
  const whiteDomains = [
    'localhost',
    'adherely-dev.firebaseapp.com',
    'adherely-demo.firebaseapp.com',
    'adherely-stage.firebaseapp.com',
    'app.adhere.ly',
    'adhere.ly',
  ]

  if (to.query.hbat) {
    localStorage.setItem('hbat', to.query.hbat)
  }
  
  // Redirect if user is not signed in
  if (
    to.name !== 'signIn' &&
    to.name !== 'relaxation' &&
    !whiteDomains.includes(window.location.hostname) &&
    localStorage.getItem('hbat') !== HBAT
  ) {
    next({ name: 'signIn' })

      return
  }
  // TODO: keeping this temporarily because doxyme needs it for their 
  // current implementation
  // Add ?lock-client=true to any URL to force the provider to be unable to switch clients
  // const isQueryParamPresent = to.fullPath.includes('?')
  // const isLocked = store.getters['app/isLocked']
  // if (isQueryParamPresent && localStorage.getItem('hbat') !== HBAT) {
  //   const lockClient = to.query?.['lock-client'] === 'true' ?? false
  //   if (!lockClient && !isLocked) {
  //     store.commit('app/setLock', false)
  //     window.parent.postMessage('adherelySessionClosed')
  //     console.log('adherely session closed from locked mode')
  //     store.dispatch('authentication/logout').then(() => {
  //       return next({ name: 'signIn' })
  //     })
  //   } else if (lockClient && !isLocked) {
  //     store.commit('app/setLock', true)
  //     if (!store.getters['clients/haveChosenClient']) {
  //       if (to.name === 'patientSelect') {
  //         return next()
  //       }
  //       console.log('Stopping navigation, forcing provider to select a client (app is in locked state)')
  //       return next({ name: 'patientSelect' })
  //     } else {
  //       // Prevent provider from accessing changing client
  //       if (to.name === 'patientSelect') {
  //         console.log('Provider has already selected a client (app is in locked state)')
  //         return next({ name: 'dashboard' })
  //       }
  //     }
  //   } else {
  //     return next({ name: 'signIn' })
  //   }

  //   next()
    // console.log('lock-client', to, to.query['lock-client'])
    // store.commit('app/setLock', to.query?.['lock-client'])
    // const isLocked = to.query?.['lock-client']
    // console.log("to.query?.['lock-client']", to.query?.['lock-client'])
    // console.log('islocked in queryparam', isLocked)
    // console.log('Provider client select and view is' 
    //   , to.query?.['lock-client']
    //     ? 'locked' : 'unlocked'
    // )
  // } 
  

  // Add ?lock-client=true to any URL to force the provider to be unable to switch clients
  const isLocked = to.query?.['lock-client'] === 'true' || store.getters['app/isLocked']
  if (isLocked) console.log('Provider client select and view is locked')

  store.commit('app/setLock', isLocked)
  window.parent.postMessage(isLocked ? 'startLockMode': 'stopLockMode', '*')

  // Force user to select a client if the app is locked (prevent navigation away)
  if (store.getters['authentication/isUserLoggedIn'] && store.getters['app/isLocked']) {

    if (!store.getters['clients/haveChosenClient']) {
      window.parent.postMessage('selectingClient', '*')
      if (to.name === 'patientSelect') {
        return next()
      }
      
      console.log('Stopping navigation, forcing provider to select a client (app is in locked state)')
      return next({ name: 'patientSelect' })
    } else {
      // Prevent provider from accessing changing client
      if (to.name === 'patientSelect') {
        console.log('Provider has already selected a client (app is in locked state)')
        return next({ name: 'dashboard' })
      }
    }

    next()
  }

  // This allows chaining of middlewares in the route definitions themselves
  if (to.meta.middleware) {
    const middleware = to.meta.middleware

    const context = {
      to,
      from,
      next,
      store
    }
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    })
  }

  next()
})

export default router
