<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3382 6.62476C11.3443 6.58078 11.3375 6.53596 11.3186 6.49577C11.2998 6.45557 11.2696 6.42173 11.2318 6.39834C10.075 5.67693 7.44692 4.53934 2.38142 4.47334C2.32069 4.47249 2.2604 4.48372 2.20406 4.50637C2.1477 4.52903 2.09642 4.56265 2.05317 4.6053C2.00993 4.64794 1.9756 4.69876 1.95217 4.75479C1.92873 4.81081 1.91667 4.87095 1.91667 4.93168V19.3829C1.91666 19.5034 1.96406 19.619 2.04864 19.7048C2.13321 19.7906 2.24815 19.8396 2.36859 19.8413C7.09034 19.9018 9.69642 20.8954 10.9715 21.6122C11.0061 21.6323 11.0452 21.6429 11.0852 21.6429C11.1251 21.6429 11.1643 21.6323 11.1988 21.6122C11.2334 21.5921 11.2622 21.5632 11.2821 21.5285C11.3021 21.4937 11.3125 21.4543 11.3125 21.4142V6.91076C11.3152 6.81499 11.3237 6.71947 11.3382 6.62476Z"
      fill="url(#paint0_linear_8_92)"
    />
    <path
      d="M18.6456 16.9941C18.6453 17.1563 18.5878 17.3132 18.4831 17.4371C18.3785 17.561 18.2334 17.6441 18.0736 17.6715C16.5875 17.9606 15.2192 18.6805 14.1392 19.7413C13.7782 20.1421 13.4625 20.5814 13.1978 21.0512C13.1743 21.0939 13.1648 21.1429 13.171 21.1912C13.1771 21.2395 13.1985 21.2846 13.232 21.32C13.2655 21.3553 13.3094 21.3791 13.3573 21.3878C13.4053 21.3966 13.4547 21.3898 13.4985 21.3684C16.0665 20.2757 18.8416 19.7545 21.6311 19.8412C21.7516 19.8396 21.8665 19.7906 21.9512 19.7048C22.0357 19.619 22.0831 19.5034 22.0831 19.3829V4.93168C22.0831 4.87095 22.0711 4.81081 22.0476 4.75479C22.0241 4.69876 21.9898 4.64794 21.9466 4.6053C21.9033 4.56265 21.8521 4.52903 21.7957 4.50637C21.7393 4.48372 21.6791 4.47249 21.6183 4.47334C20.5971 4.48618 19.6805 4.54576 18.85 4.63468C18.7938 4.64079 18.7418 4.66747 18.704 4.70961C18.6663 4.75175 18.6455 4.80636 18.6456 4.86293V16.9941Z"
      fill="url(#paint1_linear_8_92)"
    />
    <path
      d="M17.104 16.4922C17.152 16.4786 17.1943 16.4495 17.2244 16.4097C17.2544 16.3699 17.2707 16.3213 17.2708 16.2713V2.59009C17.2708 2.55319 17.262 2.51682 17.245 2.48408C17.2279 2.45135 17.2033 2.42321 17.173 2.40206C17.1428 2.38091 17.1079 2.36738 17.0713 2.36261C17.0347 2.35784 16.9975 2.36198 16.9628 2.37467C15.8923 2.73392 14.924 3.34544 14.1395 4.15759C13.3731 4.90002 12.8643 5.86815 12.6875 6.92042V18.7014C12.6875 18.7481 12.7016 18.7936 12.7282 18.8319C12.7547 18.8702 12.7923 18.8997 12.8359 18.9161C12.8795 18.9325 12.9271 18.9352 12.9723 18.924C13.0176 18.9126 13.0583 18.8878 13.089 18.8526L13.1101 18.8288C14.2012 17.6975 15.5832 16.889 17.104 16.4922Z"
      fill="url(#paint2_linear_8_92)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8_92"
        x1="2.21889"
        y1="4.67868"
        x2="14.0348"
        y2="7.09696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8_92"
        x1="13.4549"
        y1="4.67567"
        x2="24.6671"
        y2="6.87852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8_92"
        x1="12.8345"
        y1="2.55889"
        x2="18.759"
        y2="3.16996"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B9B6B2" />
        <stop
          offset="1"
          stop-color="#C6C6C6"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-neutralMedium-base)'
    }
  }
}
</script>
