export default {
  async setActivityUX({commit}, payload) {
    await commit('setActivityUX', payload)
  },
  async setUXPreviewMode({commit}, payload) {
    await commit('setUXPreviewMode', payload)
  },
  async resetUXState({commit}) {
    await commit('reset')
  }
}