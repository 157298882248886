export default {
  capitalizeFirst: string =>
    (string + '').charAt(0).toUpperCase() + (string + '').slice(1),
  lowerFirst: string =>
    (string + '').charAt(0).toLowerCase() + (string + '').slice(1),
  firstUpperOtherLower: string => {
    let out = '' + string
    out = out.toLowerCase()

    return out.charAt(0).toUpperCase() + out.slice(1)
  },
  firstUpper: string => string.charAt(0).toUpperCase() + string.slice(1),
  lower: string => {
    let out = '' + string
    out = out.toLowerCase()
    out = out.replace(/^i\s/, 'I ').replace(/\si([^-A-Za-z])/, ' I$1')

    return out
  }
}
