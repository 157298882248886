import uniqid from 'uniqid'
import { snapshotToArray } from '@/kit/helpers/firebaseHelper'
import channels from '@/kit/dictionaries/channels'
import firebase from '@/firebase'
import localStorage from '@/misc/localStorage'
import defaultState from '@/store/clients/state'

export default {
  async writeClient({ getters, dispatch, commit }, payload) {
    const id = payload.id
    const ref = firebase.db.collection(getters.collectionPath)
    const clientData = { ...payload, createdAt: firebase.now() }
    if (id) {
      await ref
        .doc(id)
        .update(clientData)
        .then(() => {
          dispatch('setClientNew', clientData)
        })
    } else {
      delete clientData.id
      const client = await dispatch('addClient', clientData)
      dispatch('setClientNew', client)
    }
    if (getters.client.phone && !getters.client.smsWelcomeMsgSent) {
      // send initial sms then mark intial as having been sent
      const smsSend = firebase.functions.httpsCallable('smsSend')
      const message = 'Your provider has set you up with Adhere.ly reminders. \n'
        + 'Talk to your provider or reply STOP to unsubscribe. '
        + 'MSG & data rates may apply.'
      smsSend({to: getters.client.phone, message: message}).then(async () => {
        commit('smsWelcomeMsgSent', true)
        await firebase.db
        .collection(getters.collectionPath)
        .doc(getters.client.id)
        .update({smsWelcomeMsgSent: true})
      })
    }
    await dispatch('analytics/segmentTrack', {eventName: 'Client Added'}, { root: true })

  },

  async addClient({ getters }, clientData) {
    return await firebase.db
      .collection(getters.collectionPath)
      .add(clientData)
      .then(docRef => ({ ...clientData, id: docRef.id }))
      .catch(() => {
        // console.error('Error adding document: ', error)
      })
  },
  async findClients({ getters }, payload) {
    const clientsRef = firebase.db.collection(getters.collectionPath)
    const field = 'name'
    const data = []

    let clients = await clientsRef
    if (undefined !== payload && payload !== null && payload[field]) {
      payload[field] = payload[field].toLowerCase()
      clients = clients
        .orderBy('name_lower')
        .startAt(payload[field])
        .endAt(`${payload[field]}\uf8ff`)
    } else {
      clients = clients.orderBy('createdAt', 'desc')
    }

    await clients
      .limit(500)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            data.push({ ...doc.data(), id: doc.id })
          })
        }
      })

    return data
  },
  // add isDeleted field to all clients that don't have it yet
  async setIsDeleted({ getters }) {
    const clientsRef = firebase.db.collection(getters.collectionPath)
    await clientsRef
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(async doc => {
          const docData = doc.data()
          if (!docData['isDeleted']) {
            await firebase.db.collection(getters.collectionPath).doc(doc.id).update({isDeleted: false})
          }
        })
      })
  },
  // countClients should be called any time a user is added or
  // soft-deleted throughout the app to ensure the clientsCount 
  // field value is always accurate
  async countClients({ getters, rootGetters, dispatch }) {
    await dispatch('setIsDeleted')
    const clientsRef = firebase.db.collection(getters.collectionPath)
    const userRef = firebase.db.collection('users')
    const snapshot = await clientsRef
      .where('isDeleted', '==', false)
      .get()
    const user = await rootGetters['authentication/user']
    userRef
      .doc(user.id)
      .update({countClients: snapshot.size})
  },
  async fetchClients({ getters, dispatch }) {
    await dispatch('countClients')
    const clientsRef = firebase.db.collection(getters.collectionPath)
    const data = []

    await clientsRef
      .where('isDeleted', '==', false)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(async doc => {
            const docData = doc.data()
            data.push({
              ...docData,
              id: doc.id
            })
          })
        }
      })

    return data
  },
  // @TODO: need rename after refactoring of client functionality
  async setClientNew({ commit }, payload) {
    const data = { ...defaultState().client, ...payload }
    data.id = payload.id
    localStorage.setItem('client', JSON.stringify(data))
    await commit('setClient', data)
  },

  async saveClient({ dispatch }, schedule) {
    const data =
      schedule.channel.key === 'sms'
        ? { phone: schedule.phone }
        : { email: schedule.email }

    let client = await dispatch('getClient', data)
    if (!client) {
      client = await dispatch('addClient', {
        ...defaultState().client,
        ...data,
        name: schedule.name,
        channel: schedule.channel.key,
        createdAt: firebase.now()
      })
    }
    return client
  },

  async getClient({ getters }, payload) {
    const clientsRef = firebase.db.collection(getters.collectionPath)
    const fields = ['email', 'phone']

    for (const field of fields) {
      if (payload[field]) {
        const client = await clientsRef
          .where(field, '==', payload[field])
          .limit(1)
          .get()

        if (!client.empty) {
          return snapshotToArray(client)[0]
        }
      }
    }

    return null
  },
  async setClient({ commit, getters, dispatch }, payload) {
    payload = { ...payload, id: payload.id || uniqid() }
    payload['contact'] =
      payload[payload.channel === 'email' ? 'email' : 'phone']

    if (getters.client.contact === payload.contact) {
      payload.id = getters.client.id
    }

    localStorage.setItem('client', JSON.stringify(payload))
    commit('setClient', payload)
    dispatch('fetchClient')
  },
  fetchClient({ rootGetters, commit }) {
    return new Promise(resolve => {
      let client
      if (!rootGetters['app/isLocked']) {
        client = localStorage.getItem('client')
      }

      client =  client ? JSON.parse(client) : null

      if (client) {
        commit('setClient', client)
      } else {
        commit('reset')
      }

      resolve(client)
    })
  },

  async softDeleteClient({ getters, dispatch }, payload) {
    if (getters.client.id === payload.id) {
      await firebase.db
        .collection(getters.collectionPath)
        .doc(payload.id)
        .update({isDeleted: true})
      dispatch('countClients')
      dispatch('resetClient')
    }
  },

  // not sure this actually works
  async deleteClient({ dispatch, getters }, payload) {
    if (getters.client.id === payload) {
      dispatch('resetClient')
    }

    return firebase.db
      .collection(getters.collectionPath)
      .doc(payload)
      .delete()
  },
  getClientData({ state }) {
    const cs = channels().filter(c => c.key === state.client.channel)
    const client = {
      activity: state.client.activity,
      clientId: state.client.id,
      name: state.client.name,
      email: state.client.email,
      phone: state.client.phone,
      caregiverName: state.client.caregiverName,
      caregiverPhone: state.client.caregiverPhone,
      caregiverEmail: state.client.caregiverEmail,
      channel: cs.length > 0 ? cs[0] : null
    }
    return client
  },
  resetClient({ getters, rootGetters, dispatch }) {
    // Prevent reset if the client has been set and the app is locked
    if (getters.haveChosenClient && rootGetters['app/isLocked']) {
      console.log('Cannot reset client since the application is locked')
      return
    }

    localStorage.removeItem('client')
    dispatch('fetchClient')
  },
  clientInfo(context, payload) {
    return {
      ...defaultState().client,
      id: payload.id || payload.clientId || null,
      name: payload.name || null,
      email: payload.email || null,
      phone: payload.phone || null,
      channel: payload.channel.key || null
    }
  },
  async setActivity({commit}, payload) {
    await commit('setActivity', payload)
  }
}
