import _ from 'lodash'
export const notAssigned = 'Not assigned'
export const exportHeaders = {
  emotions: {
    headers: [
      'Date',
      'Emotion',
      'Level',
      'Response'
    ]
  },
  thoughts: {
    headers: [
      'Date',
      'Thought',
      'Level',
      'Response'
    ]
  },
  behaviors: {
    headers: [
      'Date',
      'Behavior',
      'Level',
      'Response'
    ]
  },
  distressingSituations: {
    headers: [
      'Date',
      'Situation',
      'Level',
      'Response'
    ]
  }
}
export const severityLabels = [
  'Not at all',
  'A little',
  'Pretty',
  'Very',
  'Extremely',
  ' '
]
export const durationLabels = [
  '0 min',
  '30 min',
  '60 min',
  '90 min',
  '120+ min',
]
export const totalEmotions = [
  'Anxiety',
  'Fear',
  'Sadness',
  'Stress',
  'Anger'
]
export const selfReminders = [
  {name: 'Distressing situations'},
  {name: 'Thoughts and behaviors'},
  {name: 'Emotions'}
]
export const scoreLabels = ['0', '5', '10', '15', '20', '25', '30']
export const scoreLabels100 = ['0', '20', '40', '60', '80', '100']
// Generates the above labels for assessments with different ranges/views on the graph
export function genScoreLabels(increment, max) {
  if (increment <= 0) throw new Error('Cannot increment by non-positive value')

  const labels = []
  for (let i = 0; i <= max; i += increment) {
    labels.push(i + '')
  }
  
  return labels
}
export const colors = ['#10B1C9', '#818BE7', '#D55994', '#F68AF2']
export const tableChartWidth1 = 79.8
export const tableChartWidth2 = 86.5
export const progressStates = {
  blank: '',
  missed: 'Missed',
  scheduled: 'Scheduled',
  completed: 'Completed',
  none: 'None'
}
export const SLEEP_LOG = 'sleeplog'
export const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const thirtydays = _.range(1, 31)

export const colorMap = {
  'anger': '#EF8489',
  'anxiety': '#A656F6',
  'avoidance': '#F1A27C',
  'catastrophizing': '#D43A30',
  'checking': '#BFD254',
  'distressing situations': '#99C762',
  'fear': '#E86C92',
  'intrusive thoughts': '#D9D94E',
  'pessimism': '#346CDC',
  'physical activity': '#5CB9E0',
  'repetitive behavior': '#AE64F6',
  'sadness': '#457EE5',
  'self-deprecating': '#5DBCDD',
  'stress': '#F4BB51',
  'tics': '#BBB9B6',
  'worries': '#EF8688',
}

export const activityHeaders = {
  'affect-and-emotion': [
    {
      text: 'Date',
      align: 'start',
      sortable: true,
      value: 'date',
      width: '15%'
    },
    {
      text: 'Time',
      align: 'start',
      sortable: true,
      value: 'time',
      width: '15%'
    },
    {
      text: 'Exercise',
      align: 'start',
      sortable: false,
      value: 'title',
      width: '50%'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      width: '20%'
    }
  ],
  'custom-reminder': [
    {
      text: 'Date',
      align: 'start',
      sortable: true,
      value: 'date',
      width: '15%'
    },
    {
      text: 'Time',
      align: 'start',
      sortable: true,
      value: 'time',
      width: '15%'
    },
    {
      text: 'Activity',
      align: 'start',
      sortable: true,
      value: 'title',
      width: '20%'
    },
    {
      text: 'Reminder',
      align: 'start',
      sortable: true,
      value: 'reminder',
      width: '30%'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      width: '20%'
    },
  ],
  'enhancing-safety': [
    {
      text: 'Date',
      align: 'start',
      sortable: true,
      value: 'date',
      width: '15%'
    },
    {
      text: 'Time',
      align: 'start',
      sortable: true,
      value: 'time',
      width: '15%'
    },
    {
      text: 'Activity',
      align: 'start',
      sortable: true,
      value: 'title',
      width: '50%'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      width: '20%'
    },
  ],
  'exposure': [
    {
      text: 'Date',
      align: 'start',
      sortable: true,
      value: 'date',
      width: '15%'
    },
    {
      text: 'Time',
      align: 'start',
      sortable: true,
      value: 'time',
      width: '15%'
    },
    {
      text: 'Activity',
      align: 'start',
      sortable: true,
      value: 'title',
      width: '20%'
    },
    {
      text: 'Result',
      align: 'start',
      sortable: true,
      value: 'tooltip',
      width: '30%'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      width: '20%'
    },
  ],
  'journals': [
    {
      text: 'Date',
      align: 'start',
      sortable: true,
      value: 'date',
      width: '15%'
    },
    {
      text: 'Time',
      align: 'start',
      sortable: true,
      value: 'time',
      width: '10%'
    },
    {
      text: 'Activity',
      align: 'start',
      sortable: true,
      value: 'title',
      width: '15%'
    },
    {
      text: 'Result',
      align: 'start',
      sortable: true,
      value: 'tooltip',
      width: '40%'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      width: '10%'
    },
  ],
  'relaxation': [
    {
      text: 'Date',
      align: 'start',
      sortable: true,
      value: 'date',
      width: '15%'
    },
    {
      text: 'Time',
      align: 'start',
      sortable: true,
      value: 'time',
      width: '15%'
    },
    {
      text: 'Activity',
      align: 'start',
      sortable: true,
      value: 'title',
      width: '50%'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      width: '20%'
    },
  ],
  'social-skills': [
    {
      text: 'Date',
      align: 'start',
      sortable: true,
      value: 'date',
      width: '15%'
    },
    {
      text: 'Time',
      align: 'start',
      sortable: true,
      value: 'time',
      width: '15%'
    },
    {
      text: 'Activity',
      align: 'start',
      sortable: true,
      value: 'title',
      width: '25%'
    },
    {
      text: 'Result', 
      alight: 'start',
      sortable: true,
      value: 'tooltip',
      width: '25%'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      width: '20%'
    },
  ],
  'distressing-situations': [
    {
      text: 'Date',
      align: 'start',
      sortable: true,
      value: 'date',
      width: '15%'
    },
    {
      text: 'Time',
      align: 'start',
      sortable: true,
      value: 'time',
      width: '15%'
    },
    {
      text: 'Situation',
      align: 'start',
      sortable: true,
      value: 'situation',
      width: '15%'
    },
    {
      text: 'Result',
      align: 'start',
      sortable: false,
      value: 'reason'
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      width: '20%'
    }
  ],
}

export const distressingSituationsFeelingsMap = {
  1: 'anxious',
  2: 'afraid', 
  3: 'sad',
  4: 'angry',
  5: 'disgusted'
}

export const distressingSituationsThoughtsMap = {
  1: 'worries',
  2: 'thoughts popping in my head', 
  3: 'putting myself down',
  4: 'thought it was worse than it actually was'
}

export const distressingSituationsSensationsMap = {
  1: 'heart racing',
  2: 'shortness of breath',
  3: 'nausea',
  4: 'faint/dizzy',
  5: 'needed to use restroom', 
  6: 'muscle tension or body aches'
}

export const distressingSituationsCopeMap = {
  1: 'I avoided or left', 
  2: 'I stayed where I was',
  3: 'I approached or interacted'
}

export const chartOptions = {
  clip: false,
  elements: {
    point: {
      pointRadius: 8,
      pointHoverRadius: 12
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    chartAreaBorder: {
      borderColor: '#757575',
      borderWidth: 2,
    },
    legend: {
      labels: {
        font: {
          family: 'Montserrat'
        }
      },
      position: 'bottom'
    },
    tooltip: {
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      bodyColor: '#333',
      bodyFont: {
        size: 15
      },
      bodySpacing: 5,
      borderColor: '#333',
      borderWidth: 1,
      displayColors: false,
      padding: 16,
      titleColor: '#333',
      titleFont: {
        size: 16,
        weight: 'bold'
      },
      enabled: true,

      // external: function(context) {
      //   // Tooltip Element
      //   let tooltipEl = document.getElementById('chartjs-tooltip');

      //   // Create element on first render
      //   if (!tooltipEl) {
      //     tooltipEl = document.createElement('div');
      //     tooltipEl.id = 'chartjs-tooltip';
      //     tooltipEl.innerHTML = '<table></table>';
      //     document.body.appendChild(tooltipEl);
      //   }

      //   // Hide if no tooltip
      //   const tooltipModel = context.tooltip;
      //   if (tooltipModel.opacity === 0) {
      //     tooltipEl.style.opacity = 0;
      //     return;
      //   }

      //   // Set caret Position
      //   tooltipEl.classList.remove('above', 'below', 'no-transform');
      //   if (tooltipModel.yAlign) {
      //     tooltipEl.classList.add(tooltipModel.yAlign);
      //   } else {
      //     tooltipEl.classList.add('no-transform');
      //   }

      //   function getBody(bodyItem) {
      //     return bodyItem.lines;
      //   }

      //   // Set Text
      //   if (tooltipModel.body) {
      //     const titleLines = tooltipModel.title || [];
      //     const bodyLines = tooltipModel.body.map(getBody);

      //     let innerHtml = '<thead style="margin-bottom: 1rem;">';

      //     titleLines.forEach(function(title) {
      //       innerHtml += '<tr><th>' + title + '</th></tr>';
      //     });
      //     innerHtml += '</thead><tbody>';

      //     bodyLines.forEach(function(body, i) {
      //       console.log('body in tt', body)
      //       body.forEach(line => {
      //         let style = 'background: transparent; display: block; margin-bottom: 0.5rem;'
      //         const span = '<span style="' + style + '">' + line + '</span>'
      //         innerHtml += '<tr><td>' + span + '</td></tr>'
      //       })
      //     });
      //     innerHtml += '</tbody>';

      //     let tableRoot = tooltipEl.querySelector('table');
      //     tableRoot.innerHTML = innerHtml;
      //   }

      //   const position = context.chart.canvas.getBoundingClientRect();
      //   // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

      //   // Display, position, and set styles for font
      //   tooltipEl.style.background = 'linear-gradient(90deg, rgba(254,254,254,1) 0%, rgba(254,254,255,1) 35%, rgba(255,255,255,1) 100%)'
      //   tooltipEl.style.border = '1px solid #333'
      //   tooltipEl.style.borderRadius = '8px'
      //   tooltipEl.style.boxShadow = '2px 2px 5px #555'
        
      //   tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
      //   tooltipEl.style.fontFamily = '"Montserrat", sans-serif'
      //   tooltipEl.style.opacity = 1
        
      //   tooltipEl.style.padding = '0 1rem 0 0.5rem'
      //   tooltipEl.style.pointerEvents = 'none'
      //   tooltipEl.style.position = 'absolute'
      //   tooltipEl.style.textAlign = 'left'
      //   console.log('position', position)
      //   tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';

      // },
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 12,
      ticks: {
        stepSize: 1,
      }
    },
    x: {
      ticks: {
        stepSize: 7
      }
    }
  },
  spanGaps: true,
  tooltips: {
    bevelWidth: 3,
    bevelHighlightColor: 'rgba(255, 255, 255, 0.75)',
    bevelShadowColor: 'rgba(0, 0, 0, 0.5)',
    shadowOffsetX: 3,
    shadowOffsetY: 3,
    shadowBlur: 10,
    shadowColor: 'rgba(0, 0, 0, 0.5)'
  }
}