import cloneDeep from 'lodash.clonedeep'
import moment from '@/plugins/moment'
import { notAssigned } from '@/store/review/static'
import assessRemindersDict from "@/kit/dictionaries/reminders/assess"
import remindRemindersDict from "@/kit/dictionaries/reminders/remind"
import {
  groupBy,
  orderBy
} from '@/helpers/array'
import { empty } from 'is_js'

export default {
  active: (state) => state.active,
  assess: state => state.assess,
  assessChartData: state => state.assessChartData,
  assessments: state => state.assessments,
  assessment: state => state.assessment,
  assessmentTableData: state => state.assessmentTableData,
  assesses: state => state.assesses,
  assessShortName: (state) => {
    // The value that is displayed to the user is also what's fed into Moment to determine the offset
    for (const d of assessRemindersDict) {
      const f = d.items?.find(i => i.title === state.assessment?.name)?.shortName
      if (f) {
        return f
      }
    }
    // The value that is displayed to the user is also what's fed into Moment to determine the offset
    for (const d of remindRemindersDict) {
      const f = d.items?.find(i => i.title === state.assessment?.name)?.shortName
      if (f) {
        return f
      }
    }
  },
  assessMaxScore: (state) => {
    for (const property of assessRemindersDict) {
      const findMaxScore = property.items?.find(i => i.title === state.assessment?.name)?.maxScore
      if (findMaxScore) {
        return findMaxScore
      }
    }
    for (const property of remindRemindersDict) {
      const findMaxScore = property.items?.find(i => i.title === state.assessment?.name)?.maxScore
      if (findMaxScore) {
        return findMaxScore
      }
    }
  },
  assessScoreStep: (state) => {
    for (const property of assessRemindersDict) {
      const findScoreSteps = property.items?.find(i => i.title === state.assessment?.name)?.chartYAxisSteps
      if (findScoreSteps) {
        return findScoreSteps
      }
    }
    for (const property of remindRemindersDict) {
      const findScoreSteps = property.items?.find(i => i.title === state.assessment?.name)?.chartYAxisSteps
      if (findScoreSteps) {
        return findScoreSteps
      }
    }
  },
  chartData: state => {
    const chartData = cloneDeep(state.chartData)
    state.timeList.forEach((t, i) => {
      if (!t.active) {
        chartData.datasets[i] = []
        chartData.tooltips[i] = []
      }
    })

    return chartData
  },
  current: (state) => state.current,
  colors: state => state.colors,
  completed: state => state.completed,
  days: (state) => state.days,
  diffDays: (state) => {
    if (state.period.value === '1 week') {
      // Week-long view is 8 days
      return 8
    }
    if (state.period.value === 'all') {
      return state.days.length
    }
    const period = state.period.value.split(' ')
    return -moment().diff(moment().add(...period), 'days')
  },
  emotion: state => state.emotion,
  emotionList: state => state.emotionList,
  emotions: state => state.emotions,
  from: (state) => state.from,
  haveMonitoring: state => state.haveMonitoring,
  hasChartData: (state) => (type) => {
    return state[type]
  },
  isDefaultPeriod: (state) => state.period.defaultValue === state.period.value,
  loading: (state) => state.loading,
  noEmotions: state => state.emotion === notAssigned,
  period: (state) => {
    const period = cloneDeep(state.period)
    if (state.reminderType === 'reviewAssess') {
      period.items.push({ value: 'all', text: 'Show all' })
    }

    return period
  },
  periodValue: state => state.periodValue,
  printChartData: (state) => (type) => {
    const chartData = `chartData${type.charAt(0).toUpperCase() + type.slice(1)}`
    return state[chartData]
  },
  reminderType: state => state.reminderType,
  scheduled: state => state.scheduled,
  schedules: state => state.schedules,
  sections: state => state.sections,
  selfReminder: state => state.selfReminder,
  sessions: state => state.sessions,
  sessionData: state => state.sessionData,
  table: state => state.table,
  thoughtsAndBehaviorsGraphData: state => state.thoughtsAndBehaviorsGraphData,
  thoughtsAndBehaviorsTableData: (state) => {
    const sessions = cloneDeep(state.sessions)
    // console.log('sessions in getters', sessions)
    const sorted = sessions.sort(orderBy.bind(null, 'date', -1))
    return sorted
  },
  time: state => state.time,
  timeList: state => state.timeList,
  xAxesFormat: state => state.xAxesFormat,
  xAxesLabels: (state) => state.xAxesLabels,

}
